@import "./successModal/_successModal.scss";
.claims-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.claims-container {
  .claims-image-and-text-wrapper {
    img {
      width: 100%;
    }
    .view {
      width: 100%;
      p {
        margin-bottom: 0;
      }
      .mask {
        background-color: #15191f;
        opacity: 0.3;
      }
    }
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      br {
        display: none;
      }
      color: #ffffff;
      max-width: 113rem;
      position: absolute;
      font-size: 10rem;
      font-weight: 100;
      line-height: 9.2rem;
    }
  }
  .forms-all {
    text-align: center;
    max-width: 92rem;
    margin: 0 auto;
  }
  .md-form {
    width: 100%;
    height: 100%;
  }
  .first-column-inputs {
    .md-form {
      height: 4rem;
    }
  }
  .select2 {
    margin: 1.5rem 0;
    &:focus {
      outline: none;
    }
    .react-select-container {
      .react-select__control {
        background-color: $white;
        border-color: $default !important;
        height: 4rem;
        border: 2px solid $default;
      }
    }
  }
  .text-red {
    bottom: 0;
  }
}
.add-another {
  background: $default;
  color: white;
  padding: 2rem 7rem;
  position: relative;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;
  img {
    position: absolute;
    left: 5%;
    bottom: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.move-to-top-icon {
  background-color: #d8d8d8;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: inline-block;
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  img {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.5rem;
  }
  small {
    color: $default;
    width: 40%;
    text-align: center;
    font-weight: 600;
  }
}
.claims {
  /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  .account-info {
    p {
      width: 25rem;
        @media screen and(max-width: 480px) {
          width: 20rem;
        }
    }
    h4 {
      font-weight: $fwregular;
      &:last-of-type {
        color: #888888;
      }
    }
  }
  .checkbox.same-as-acc-checkbox {
    width: unset;
  }
  .select2,
  .checkbox,
  .switch {
    margin: 1.5rem 0;
  }
  .label {
    margin-bottom: 0 !important;
  }
  .masked-input {
    margin: 1.5rem 0 0;
  }
  .checkbox {
    min-height: 4rem;
  }
  .currency-simbol {
    position: absolute;
    top: 1.1rem;
    margin-bottom: 0;
    left: 1rem;
    font-size: 1.4rem;
  }
  textarea {
    max-height: 10rem;
  }
  .no-wrap {
    bottom: -3.3rem;
  }
  .md-form input[type="number"] {
    box-sizing: border-box;
    width: 100% !important;
    border: 2px solid #6c808b;
    border-color: #6c808b;
    border-radius: 5px;
    height: 4rem;
    padding: 1rem 2rem;
    color: #404040 !important;
    opacity: 0.5;
  }

  .remove-all-items {
    width: 13rem;
  }

  .switch {
    width: 85px;
    position: relative;
    // display: inline-block;
    display: flex;
    justify-content: space-between;
    top: 1rem;
    min-height: 2.5rem;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .slider {
        background-color: $default;
      }
      &:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
      }
      &:checked + .slider:before {
        -webkit-transform: translateX(21px);
        -ms-transform: translateX(21px);
        transform: translateX(21px);
        background-color: white;
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 20px;
      right: 0;
      bottom: 0;
      // border: 1px solid #837e7e;
      background-color: #d8d8d8;
      -webkit-transition: 0.4s;
      height: 20px;
      width: 40px;
      transition: 0.4s;
      &.round {
        border-radius: 34px;
        &:before {
          border-radius: 50%;
        }
      }
      &:before {
        position: absolute;
        content: "";
        height: 14px;
        width: 14px;
        left: 3px;
        top: 3px;
        bottom: 3px;
        right: 3px;
        background-color: #aaaaaa;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
}
