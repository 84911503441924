.plp-container-wrap {
  padding: 0 8rem;
  max-width: 100%;
}
.sticky-filter {
  height: calc(100vh - 260px);
  overflow: auto;
  position: sticky;
  top: 215px;
}
.sticky-filter::-webkit-scrollbar {
  display: none;
}
@media screen and(max-width:1440px) {
  .plp-container-wrap {
    padding: 0 3rem;
  }
}

@media screen and(max-width:390px) {
  .plp-container-wrap {
    padding: 0 3rem;
  }
}
