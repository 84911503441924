.menu-item-wrap {
  background-color: transparent;
  display: flex;
  &:hover {
    > .menu-item {
      text-decoration: underline !important;

      img {
        filter: brightness(0) saturate(100%) invert(46%) sepia(27%)
          saturate(246%) hue-rotate(157deg) brightness(99%) contrast(91%);
      }
    }
  }

  &:hover {
    .dropdown-items.hovered {
      opacity: 1;
      visibility: visible;
      margin-top: 0 !important;
    }
  }
  .menu-item {
    padding: 1rem 2rem;
    color: #404040;
    font-family: Yantramanav;
    z-index: 10;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    text-transform: uppercase;
  }
  .pointer-events-none {
    pointer-events: none;
  }
}
