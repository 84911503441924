#new-version-popup {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 20px 2px #0000002e;
  border-radius: 4px;
  font-size: 1.2rem;
  z-index: 1000;
  p {
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }
  .btn {
    min-width: 2rem;
    padding: 1rem;
  }
}
