@import "./components/sidebar/sidebar";

.selector-container {
  margin: 10px 0;
  display: inline-block;
  width: 100%;
  cursor: initial;
  &.finish {
    animation: slideIn 0.5s ease-in-out;
    .selected-option {
      width: 68%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }

  .selector-title {
    padding: 0 15px;
    font-weight: 300;
    color: $gray-darken-3;
    line-height: 0;

    &:first-line {
      line-height: 30px;
    }
  }
  .selected-option {
    border: 1px $border-gray solid;
    border-radius: 2px;
    height: 40px;
    padding: 0 15px;
    cursor: pointer;

    p {
      line-height: 40px;
      font-size: 1.4rem;
      color: $gray-darken-2;
      padding: 0;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .found-option {
      > * {
        float: left;
      }
      img {
        width: 25px;
        height: 25px;
        border: 1px $secondary solid;
        border-radius: 2px;
        margin-top: calc((40px - 27px) / 2);
      }

      p {
        margin-left: 5px;
        max-width: calc(100% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .selector-container {
    .selector-title {
      float: left;
      max-width: 33%;
      line-height: 15px;
      height: 45px;
      overflow: visible;
    }

    .selected-option {
      float: right;
      width: 67%;
    }
  }
}
