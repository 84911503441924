.my-downloads-listing-item {
  margin-bottom: 8rem;
  .downloader {
    opacity: 1;
    position: absolute;
    top: 0;
    right: 1rem;
    &.downloader-icon {
      opacity: 1;
      svg {
        opacity: 1;
      }
    }
  }
  .my-downloads-item-information {
    .tooltip {
      position: relative;
      top: 0.8rem !important;
      left: 1.6rem !important;
      width: auto;
    }
  }
  @import "./modal/modal";
}
