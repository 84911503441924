.specs-container {
  .tabs {
    display: flex;
    padding: 1.8rem 2.3rem;
    background: $gray-lighten-4;
    @media screen and (max-width: 1200px) {
      overflow-y: auto;
    }
    @media screen and (max-width: 480px) {
      align-items: center;
    }
    p {
      margin-right: 2rem;
      position: relative;
      font-size: 1.6rem;
      margin-bottom: 0;
      transition: all 0.25s ease-in-out;
      text-decoration: underline;
      &:after {
        content: " ";
        width: 100%;
        background-color: transparent;
        transition: all 0.25s ease-in-out;
        height: 6px;
        position: absolute;
        bottom: -17px;
        left: 0;
      }
      &:last-of-type {
        margin-left: 0;
      }
      &.active {
        font-weight: bold;
        text-decoration: none;
        &:after {
          background-color: #6c808b;
        }
      }
    }
  }
  .collections-and-available-in-container {
    // width: 100%;
    max-height: 60px;
    &.available {
      display: flex;
      flex-direction: column;
      align-items: end;
      margin-bottom: 1.2rem;
    }

    .collections-container {
      float: left;
      font-size: 2.4rem;
      font-weight: 400;
    }

    .available-in-container {
      float: right;
      display: inline-block;
      // margin-bottom: 20px;
      .mr-5 {
        margin-right: 1rem !important;
      }

      > * {
        float: left;
      }

      p {
        font-size: 1.4rem;
        color: $gray-darken-3;
        font-weight: 400;
        line-height: 30px;
        margin-right: 5px;
      }

      .images-container {
        > * {
          margin: 0 5px;
        }
      }
    }
  }

  .mapped-specs-container {
    border-bottom: 1px solid $gray;
    padding: 2rem 2.3rem;
    margin-bottom: 1.5rem;
    > div {
      // transition: 0.2s all ease-in-out;

      > div {
        padding: 10px 15px;
      }
    }

    .specs-name {
      height: 38px;
      background: $gray-lighten-4;
      cursor: pointer;
      padding: 10px 10px 10px 25px;
      border-radius: 2px;
      width: calc(100% - 15px);
      margin: 10px 0;

      h1 {
        color: $gray-darken-3;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.6rem;
        float: left;
      }

      img {
        float: right;

        &.plus {
          margin-top: calc((18px - 18px) / 2);
        }

        &.minus {
          margin-top: calc((18px - 2px) / 2);
        }
      }
    }

    .description {
      font-weight: 500;
      font-size: 1.6rem;
      color: #404040;
    }

    .specs-options-container {
      // display: grid;
      // grid-template-columns: repeat(1, 1fr);
      // grid-column-gap: 1.5rem;
      > div {
        // display: flex;
        // grid-template-columns: 2fr 3fr;
        > div {
          width: auto;
          text-align: left;
        }
      }

      .show-more {
        cursor: pointer;
        text-decoration: underline;
        font-size: 1.6rem;
        &.link {
          color: #6c808b;
          display: inline-block;
          margin-left: 1rem;
        }
      }
      > * {
        padding: 2px 0;
        display: inline-block;
        width: 100%;

        > * {
          width: 50%;
          font-size: 1.6rem;
        }
      }

      .specs-options-name {
        color: #404040;
        font-weight: 500;
        // width: 70%;
      }

      .specs-options-value {
        color: #404040;
        font-weight: 400;
        text-align: left;
      }
    }
  }
}
