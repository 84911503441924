@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}
@mixin fontSize($font-size, $line-height, $letter-spacing) {
  font-size: $font-size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}
.faq-page-container {
  a {
    display: inline;
    padding: 0;
    text-decoration: underline !important;
    font-size: 1.6rem;
  }
  .faq-contact-us-border {
    border: 2px solid #d9d9d9;
    a {
      text-decoration: unset !important;
    }
  }
  .faq-contact-us-text {
    margin: 0 15%;
  }
  .md-form {
    margin-top: 0;
  }
  @include media(500px) {
    padding: 0 1.5rem !important;
  }
  .contact-us-faq-page {
    margin-top: 2rem;
    border-radius: 1rem;
    background-color: #b7b7b7;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p,
    a {
      color: $white;
      @include fontSize(2.1rem, 2.5rem, 0);
      @include media(1200px) {
        @include fontSize(1.8rem, 2rem, 0);
      }
      @include media(1050px) {
        @include fontSize(1.4rem, 1.6rem, 0);
      }
    }
    a {
      text-decoration: underline;
      text-transform: uppercase;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  .listing-and-image-wrapper-faq {
    img {
      width: 100%;
    }
    .dropdown-item {
      img {
        width: unset;
        max-width: 100%;
      }
    }
    @include media(1200px) {
      flex-direction: column-reverse;
    }
  }
  @import "./faqItem/faqItem";
  margin-top: 10rem;
  margin-bottom: 30rem;
  @include media(992px) {
    margin-top: 4rem;
    margin-bottom: 10rem;
  }
  // .title-container {
  //   margin-bottom: 5rem;
  // }
  .faq-listing {
    margin-top: 5rem;
    @include media(992px) {
      padding: 0;
    }
  }
}
