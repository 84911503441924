.downloads-button {
  cursor: pointer;
  position: relative;
}
.download-link {
  cursor: pointer;
  display: flex;
}
.downloads-container-mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  > div {
    &:nth-of-type(3n + 2) {
      justify-content: center;
    }
    &:nth-of-type(3n + 3) {
      justify-content: end;
    }
  }
}
.download-h2 {
  color: $default;
  font-weight: 400;
  font-size: 1.3rem;
  text-decoration: underline !important;
  line-height: 25px;
}
.downloads-container {
  display: none;
  position: absolute;
  top: -71px;
  background: #fff;
  padding: 5px 10px;
  border: 1px solid $default;
  right: 0px;
  width: 170px;

  &.opened {
    display: block;
  }
}

.downloads-container-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 16px #fff solid;
  border-right: 10px transparent solid;
  border-left: 10px transparent solid;
  top: -11px;
  right: 24px;
  z-index: 12;
  display: none;

  &.opened {
    display: block;
  }
}

.downloads-container-arrow-border {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 16px $default solid;
  border-right: 11px transparent solid;
  border-left: 11px transparent solid;
  top: -10px;
  right: 23px;
  z-index: 11;
  display: none;

  &.opened {
    display: block;
  }
}
