.company-story-container {
  .company-title-desc {
    p {
      font-size: 1.3rem;
    }
  }
  .breadcrumbs {
    margin-bottom: 1rem;
    .breadcrumb {
      li {
        a {
          font-size: 1.2rem;
        }
      }
    }
  }
  .company-story-text-and-img-wrapper {
    position: relative;
    .view {
      p {
        margin-bottom: 0;
      }
      .mask {
        background-color: #15191f;
        opacity: 0.3;
      }
    }
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      br {
        display: none;
      }
      color: #ffffff;
      max-width: 113rem;
      position: absolute;
      font-size: 10rem;
      font-weight: 100;
      line-height: 9.2rem;
    }
  }
  .imageloader {
    width: 100%;
  }
  .text-side {
    max-width: 33rem;
    margin: 0 auto;
  }
  .company-story-modal {
    max-width: 1140px;
  }
  .company-store-last-row-wrapper {
    button {
      background-color: #ffffff !important;
      border: none !important;
    }
    .company-story-block {
      a {
        font-size: 1.6rem;
        padding-left: 0;
        text-decoration: underline !important;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .company-story-container {
    .company-block {
      padding: 0 10rem;
    }
    .company-story-block {
      padding-top: 7rem;
    }
  }
}
@include media-breakpoint-down(lg) {
  .company-story-container {
    .company-story-block {
      padding-top: 3.5rem;
    }
  }
}

@media screen and (max-width: 1440px) {
  .company-story-container {
    .company-story-text-and-img-wrapper {
      h1 {
        max-width: 92rem;
        font-size: 8rem;
        line-height: 7.2rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .company-story-container {
    .company-story-text-and-img-wrapper {
      h1 {
        max-width: 28.4rem;
        font-size: 4rem;
        line-height: 3.2rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .company-story-container {
    .company-story-text-and-img-wrapper {
      img {
        height: 47.5rem;
        object-fit: cover;
        object-position: left;
      }
      h1 {
        br {
          display: inline;
        }
        max-width: 33.5rem;
        font-size: 4rem;
        line-height: 3.2rem;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .explore-block {
    .text-block {
      order: 1;
    }
    .img-block {
      order: 0;
    }
  }
}
