.resources-main-container {
  .resources-banner-block {
    h1 {
      font-size: 100px;
      line-height: 92px;
      @include media-breakpoint-down(md) {
        font-size: 6.5rem;
        line-height: 1;
      }
      @include media-breakpoint-down(xs) {
        font-size: 4rem;
      }
    }
  }
  .select-industry {
    .label-text {
      margin-bottom: 1.5rem;
    }
    .select-custom-option {
      padding: 0.8rem 1.2rem;
      &:hover {
        background-color: rgba(108, 128, 139, 0.3);
      }
    }
  }
  .resources-tabs {
    @include media-breakpoint-down(sm) {
      overflow-x: auto;
      display: flex;
    }
    .resources-tab {
      width: 12rem;
      &:not(:last-child) {
        margin-right: 6rem;
        @include media-breakpoint-down(sm) {
          margin-right: 3rem;
        }
      }
      &:after {
        content: "";
        width: 30%;
        height: 0.5rem;
        transition: all 0.5s ease-in-out;
        background-color: transparent;
        position: absolute;
        bottom: 0;
      }
      &.active {
        &::after {
          background-color: $default;
        }
        h2 {
          font-weight: $fwbold;
        }
      }
    }
  }
  .checkbox {
    h5 {
      font-size: $fs-16;
      color: $primary;
    }
  }
}
