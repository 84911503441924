@import "./virtualShowroom/virtualShowroom";
.homepage-showroom-wrapper {
  margin-top: 10rem;
  h2 {
    color: #404040 !important;
    white-space: nowrap;
  }
  .homepage-showroom-image-wrapper {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    .view {
      .mask {
        background-color: rgba(54, 59, 69, 0.43);
        .homepage-showroom-text-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 25%;
          .logo {
            min-height: 4.1rem;
          }
          h4 {
            margin-top: 2rem;
            font-size: 3.9rem;
            font-weight: 300;
            color: white;
          }
          p {
            font-size: 1.6rem;
            font-weight: 400;
            color: white;
          }
          .homepage-showroom-text-2-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 3.7rem;
            a {
              color: transparent;
              font-size: 4rem;
              height: 6rem;
              width: 6rem;
              background-size: contain;
              background-image: url("../../../assets/icon/play_button.svg");
              background-repeat: no-repeat;
              transition: all 0.25s ease-in-out;
              &:hover {
                transform: scale(1.1);
                transition: all 0.25s ease-in-out;
              }
            }

            p {
              &:nth-child(2) {
                margin-top: 1.5rem;
              }
            }
          }
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .homepage-showroom-wrapper {
    .homepage-showroom-image-wrapper {
      .view {
        .mask {
          .homepage-showroom-text-wrapper {
            .logo {
              width: 80%;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .homepage-showroom-wrapper {
    .homepage-showroom-image-wrapper {
      .view {
        .mask {
          .homepage-showroom-text-wrapper {
            .logo {
              max-height: 3.5rem;
            }
            h4 {
              font-size: 3.3rem;
              margin-top: 1.5rem;
            }
            p {
              font-size: 1.7rem;
            }
            .homepage-showroom-text-2-wrapper {
              margin-top: 2.5rem;
              a {
                height: 4.6rem;
                width: 4.6rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .homepage-showroom-wrapper {
    .homepage-showroom-image-wrapper {
      .view {
        img {
          height: 45rem;
          object-fit: cover;
        }
        .mask {
          .homepage-showroom-text-wrapper {
            .logo {
              max-height: 3.2rem;
              min-height: 100%;
            }
            h4 {
              font-size: 3rem;
              margin-top: 1.4rem;
            }
            p {
              font-size: 1.6rem;
            }
            .homepage-showroom-text-2-wrapper {
              margin-top: 2.1rem;
              a {
                height: 4.3rem;
                width: 4.3rem;
              }
            }
          }
        }
      }
      .display-2 {
        font-size: 4rem;
        white-space: normal;
        text-align: center;
        font-weight: 300;
        margin-top: 5rem;
      }
    }
  }
}

// @media screen and (max-width: 576px) {
//   .homepage-showroom-wrapper {
//     .homepage-showroom-image-wrapper {
//       .view {
//         .mask {
//           .homepage-showroom-text-wrapper {
//             .logo {
//               max-height: 3.2rem;
//             }
//             h4 {
//               // font-size: 3rem;
//               margin-top: 1.2rem;
//             }
//             p {
//               font-size: 1.7rem;
//             }
//             .homepage-showroom-text-2-wrapper {
//               margin-top: 2.2rem;
//               a {
//                 height: 3.8rem;
//                 width: 3.8rem;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 360px) {
  .homepage-showroom-wrapper {
    .homepage-showroom-image-wrapper {
      .view {
        img {
          height: 40rem;
          object-fit: cover;
        }
        .mask {
          .homepage-showroom-text-wrapper {
            .logo {
              max-height: 2.4rem;
            }
            h4 {
              font-size: 2.2rem;
              margin-top: 0.8rem;
            }
            p {
              font-size: 1.4rem;
            }
            .homepage-showroom-text-2-wrapper {
              margin-top: 3.8rem;
              a {
                height: 3.6rem;
                width: 3.6rem;
              }
            }
          }
        }
      }
    }
  }
}
