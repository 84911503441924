.live-edge {
  width: 100%;
  .sidebar-product-info {
    margin-bottom: 5rem;
    a {
      font-size: 1.6rem;
    }
  }

  .search-input-wrapper {
    width: 70%;
    margin-right: 2rem;
  }
}
