@import "./components/MediaGalleryModal.scss";
.yarl__root {
  .yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: 0% !important;
    @media (min-width: 1200px) {
      --yarl__thumbnails_vignette_size: 0% !important;
    }
  }

  .yarl__thumbnails_container {
    touch-action: manipulation !important;
  }
}
.yarl-one-image-fix .yarl__navigation_prev,
.yarl-one-image-fix .yarl__navigation_next {
  display: none;
}
.pdp-slider-img,
.yarl__root .yarl__carousel .yarl__fullsize.yarl__flex_center {
  cursor: zoom-in;
}
.pdp-video-slider {
  min-width: 55vw;
  height: 68vh;
}

@media screen and (max-width: 1024px) {
  .pdp-video-slider {
    min-width: 40vw;
    iframe {
      min-height: 68vh;
      position: absolute;
      top: -25vh;
      left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery-slider-wrap {
    min-width: 100%;
    .pdp-video-slider {
      iframe {
        height: 60vh;
        width: 80vw;
        left: -20vw;
      }
    }
  }
}

.slick-arrow {
  // display: none !important;
  display: flex;
  justify-self: center;
  color: white !important;
  position: absolute;
  width: 3rem;
  height: 3rem;
  z-index: 10;
  &:before {
    font-weight: 600;
    position: absolute;
    left: 50%;
    right: 50%;
    width: 3rem;
    top: 0;
    height: 3rem;
    transform: translate(-50%, 0%);
    width: 3rem;
    height: 3rem;
  }
  &.slick-prev {
    // top: unset;
    // bottom: 110%;
    top: -40%;
    left: -13%;
    transform: translate(-50%, 0) rotate(0deg);
    &:before {
      opacity: 1;
      content: "";
      background-image: url("../../../app/assets/icon/leftMobileWhite.svg");
    }
  }
  &.slick-next {
    color: white;
    position: absolute;
    // bottom: 110%;
    top: -40%;
    // bottom: unset;
    right: -13%;
    transform: translate(-50%, 0) rotate(-180deg);
    &:before {
      opacity: 1;
      content: "";
      background-image: url("../../../app/assets/icon/leftMobileWhite.svg");
    }
  }
  @media screen and (max-width: 768px) {
    &.slick-next,
    &.slick-prev {
      top: 40%;
    }
  }
}
.media-gallery {
  padding: 0 15px;
  .side-slider {
    width: 150px;
    padding: 30px 0;
    @media screen and (max-width: 1440px) {
      width: 100px;
    }
    .side-slider-img {
      cursor: pointer;
    }
    .slick-arrow {
      left: calc(50% - 15px);
      &.slick-disabled {
        opacity: 0.5;
      }
      &.slick-prev {
        top: -30px;
        transform: rotate(-270deg);
      }
      &.slick-next {
        bottom: -30px;
        top: unset;
        right: unset;
        transform: rotate(-90deg);
      }
      &::before {
        background-image: url("../../../app/assets/icon/leftMobile.svg");
      }
    }
  }
}
.view-image-details-container {
  bottom: -4rem;
  .img-holder {
    width: 4.9rem;
    height: 4.9rem;
    border: 1px solid;
    margin: 0 0.8rem;
    position: relative;
    .bckg {
      background-color: rgba(112, 112, 112, 0.5);
      z-index: 2;
      height: 100%;
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }
  .view-image-details {
    font-size: 14px;
  }
}
.vertical-slider-wrapper {
  bottom: -14rem;
  width: 100%;
  left: 0;
  justify-content: center;
  &.decrease-items {
    .vertical-slider {
      .slick-list {
        .slick-track {
          height: auto !important;
        }
      }
    }
  }
  .vertical-slider {
    .slick-slide > div {
      margin: 5px 0;
    }
    .slick-list {
      margin: -5px 0;
    }
    .slick-slide {
      max-width: 10rem;
      margin-left: 2rem;
      &:first-of-type {
        margin-left: 0;
      }
      img {
        width: 100%;
      }
      > div {
        height: 10rem;
        width: 10rem;
        max-height: 10rem;
        background-color: #fff;
        display: inline-block;
        > div {
          height: 10rem !important;
          width: 10rem !important;
          max-height: 10rem !important;
          img {
            max-height: 10rem !important;
            width: 100% !important;
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
    .slick-vertical {
      position: relative;
      &.slick-prev {
        top: -40%;
        left: -13%;
      }
      &.slick-next {
        top: -40%;
        right: -13%;
      }
    }
  }
}
.product-media-slider {
  padding-bottom: 1rem;
  &.slick-initialized {
    .slick-arrow {
      position: absolute;
      width: 4rem;
      height: 4rem;
      top: 50%;
      z-index: 2;
      &:before {
        display: flex;
        font-weight: 600;
        font-size: 20px;
        top: 0;
        width: 4rem;
        height: 4rem;
      }

      &.slick-prev {
        left: 28%;
        transform: rotate(-90deg);
        &:before {
          transform: rotate(90deg);
          opacity: 1;
          content: "";
          background-image: url("../../../app/assets/icon/leftMobile.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      &.slick-next {
        right: 28%;
        transform: rotate(-90deg);
        &:before {
          transform: rotate(-90deg);
          opacity: 1;
          content: "";
          background-image: url("../../../app/assets/icon/leftMobile.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      @media screen and (max-width: 1900px) {
        &.slick-prev {
          left: 10%;
        }
        &.slick-next {
          right: 10%;
        }
      }
      @media screen and (max-width: 1200px) {
        &.slick-prev {
          left: 5%;
        }
        &.slick-next {
          right: 5%;
        }
      }
    }
    .slick-list {
      .slick-track {
        display: flex;
        align-items: center;
        .slick-slide {
          opacity: 0.51;
          padding: 0 6.5rem;
          // max-height: 67.5rem;
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          > div {
            text-align: center;
          }
          img {
            max-width: 100%;
            max-height: 68vh;
            @media screen and (max-width: 991px) {
              max-height: 55vh;
            }
            width: auto !important;
            // max-width: 80%;
          }
          &.slick-active {
            opacity: 1;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .vertical-slider-wrapper {
    .vertical-slider {
      .slick-slide > div {
        margin: 0;
      }
      .slick-list {
        margin: 0;
      }
      .slick-slide {
        > div {
          height: 10rem;
          width: 10rem;
          max-height: 10rem;
          background-color: #fff;

          > div {
            height: 10rem !important;
            width: 10rem !important;
            max-height: 10rem !important;
            img {
              max-height: 10rem !important;
              width: 100% !important;
              object-fit: contain;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .product-media-container {
    .slick-slide {
      div {
        .pdp-slider-img {
          height: 100% !important;
          max-height: 33rem;
          object-fit: contain;
        }
      }
    }
  }
}
