.tooltip-container {
  position: relative;
  .tooltip-content {
    position: absolute;
    bottom: 7rem;
    left: 2rem;
    z-index: 9999999;
    height: 7.3rem;
    width: 12.5rem;
    background-color: white;
    border: 1px solid $default;
    display: flex;
    justify-content: center;
    align-items: center;
    .tooltip-tick-arrow {
      position: absolute;
      bottom: -1.5rem;
      right: 2rem;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 15px solid $default;
      .inner-tick {
        position: absolute;
        bottom: 0.2rem;
        left: -0.9rem;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 14px solid $white;
      }
    }
    &.top {
      top: 0rem;
      bottom: unset;
    }
    &.center {
      left: unset;
      right: unset;
      align-self: center;
      .tooltip-tick-arrow {
        right: unset;
      }
    }
  }
  .tooltip-trigger-element {
    position: relative;
  }
}
