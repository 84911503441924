.tooltip-elements-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.finish-item-tooltip {
  max-width: 5.6rem;
  max-height: 5.6rem;
  height: 5.6rem;
  width: 5.6rem;
  padding: 0;
  margin: 1rem;
  .tooltip-content {
    z-index: 9999999;
    width: 21rem;
    height: 25rem;
    top: -26rem !important;
    padding: 2rem;
    box-shadow: -1px 1px 3px #00000055;
    display: flex;
    align-items: flex-start;
    .image-container {
      height: 17.5rem;
      width: 17.5rem;
      border-radius: 5px;
      margin-bottom: 1rem;
      img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
      }
    }
    .info-container {
      p {
        font-size: 14px;
        line-height: 1.6rem;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
  .tooltip-trigger-element {
    padding: 0;
  }
  &.align-left {
    .tooltip-elements-container {
      justify-content: flex-start;
    }
    .tooltip-content {
      .tooltip-tick-arrow {
        left: 1rem;
      }
    }
  }
  &.align-right {
    .tooltip-elements-container {
      justify-content: flex-end;
    }
    .tooltip-content {
      .tooltip-tick-arrow {
        right: 1rem;
      }
    }
  }
}

.finish-item {
  cursor: pointer;
  border-radius: 5px;
  height: 5.6rem;
  width: 5.6rem;
  max-width: 5.6rem;
  padding: 0;
  margin: 0;
  img {
    border-radius: 5px;
  }
  div {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
}
