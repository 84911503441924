.three-column {
  display: grid;
  grid-template-columns: 1fr;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
  gap: 1.5rem 1.5rem;
}

.border-thic {
  border-width: 2px !important;
}

.session-container {
  position: fixed;
  top: 12rem;
  left: 1rem;
  z-index: 9999;
  transition: all 0.2s ease-in-out;
  @include media-breakpoint-up(md) {
    top: 7rem;
    text-align: center;
  }
}

.session-messages-inner {
  left: 1rem;
  z-index: 9999;
  margin-bottom: 1rem;
  animation-name: fadeInOut;
  animation-duration: 4s;
  &.favorites {
    position: fixed;
    bottom: 0;
    left: 40%;
    .session {
      color: white !important;
      background: $default !important;
      border: 1px solid transparent;
      p,
      a,
      span {
        color: white !important;
      }
    }
  }
  .session {
    padding: 1rem 2.5rem;
    background: #fff;
    span {
      padding-left: 1rem;
      color: white;
      font-size: $fs-14;
    }
    svg {
      &.svg-inline--fa path {
        fill: $white !important;
      }
    }
    &.danger {
      span {
        color: $white;
      }
      background: $danger;
    }
    &.success {
      color: $black;
      background: $light-green;
      svg {
        &.svg-inline--fa path {
          fill: $black;
        }
      }
    }
    &.info,
    &.warning {
      color: $white;
      background: $warning;
    }
  }
}

@media screen and (max-width: 768px) {
  .session-messages-inner {
    &.favorites {
      left: 0 !important;
      right: 0;
      width: 350px;
      max-width: 100%;
      margin: 0 auto;
      .session.success {
        height: auto;
        span > div {
          flex-direction: column;
          align-items: baseline !important;
          > div {
            margin-left: 0 !important;
            span {
              padding-left: 0;
            }
          }
          a {
            span {
              padding-left: 0;
            }
          }
        }
      }
    }
    &.add-to-favorites,
    &.product-add-remove {
      .session {
        width: auto;
        a.fs-14 {
          margin-left: 0 !important;
        }
      }
    }
    &.product-add-remove {
      margin: 0 auto !important;
      left: 0 !important;
      right: 0;
    }
  }
}

.session-messages-inner {
  padding: 1rem 0rem;
  &.build-your-own,
  &.add-to-favorites,
  &.product-add-remove {
    .session {
      border-radius: 5px;
      box-shadow: 0px 2px 4px #00000033;
      background: $secondary !important;
      height: 60px;
    }
  }

  &.add-to-favorites {
    .session {
      width: 500px;
      a.fs-14 {
        margin-left: 118px;
      }
    }
  }
  &.favorites {
    position: fixed;
    bottom: 0;
    left: 40%;
  }
  &.product-add-remove {
    left: 50%;
    // margin-top: -100px; /* Negative half of height. */
    margin-left: -165px;
  }
  .session {
    display: flex;
    align-items: center;
    &.danger {
      color: $danger;
    }
  }
}

.separator {
  height: 0.6rem;
  width: 100%;
  border-bottom: 1px solid $white;
  position: relative;
  &:before {
    display: inline-block;
    content: "";
    width: 4rem;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -0.3rem;
    background-color: $white;
  }
  &.dark {
    border-color: $default;
    &:before {
      background-color: $default;
    }
  }
}

.close {
  position: absolute;
  top: calc(50% - 2rem);
  &.close-left {
    left: 1rem;
  }

  &.close-right {
    right: 1rem;
  }
}

.slick-slider {
  .slick-dots {
    //    bottom: -4rem;
    li {
      button {
        //        position: static;
        //        width: 1.5rem;
        //        height: 1.5rem;
        &:before {
          color: $secondary;
          //          content: "";
          //          opacity: 1;
          //          position: absolute;
          //          border: 1px solid $primary;
          //          height: 1rem;
          //          width: 1rem;
          //          top: calc(50% - 0.5rem);
          //          left: 0;
          //          right: 0;
          //          margin: 0 auto;
          //          border-radius: 1rem;
          //          transition: all $transitionDuration ease-in-out;
        }
      }
      &.slick-active {
        button {
          &:before {
            color: #6c808b;
            //            background-color: $primary;
            //            height: 1.5rem;
            //            width: 1.5rem;
            //            top: calc(50% - 0.75rem);
          }
        }
      }
    }
  }
}

.menu-top-item {
  // padding-left: 2rem;
  // padding-right: 2rem;
  @media (max-width: 1580px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (max-width: 1310px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (max-width: 1220px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

// Helpers
.border-lg {
  @include media-breakpoint-up(lg) {
    border: 1px solid $gray;
  }
}
.border-right-lg {
  @include media-breakpoint-up(lg) {
    border-right: 1px solid $gray;
  }
}

.mt--30 {
  margin-top: -30rem;
}

.fullscreen-canvas {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  z-index: 100000 !important;
  background-color: white;
}

@include media-breakpoint-up(lg) {
  .mt-lg--25 {
  }

  .position-lg-sticky {
    position: sticky;
  }
}

.logo {
  &.logo-desktop {
    height: 3rem;
    width: 22.5rem;
  }
}

.pagination {
  margin: 0;
  .page-item {
    &.disabled {
      opacity: 0;
    }
    .page-link {
      border: 0.2rem solid;
      border-color: transparent;
      background-color: transparent !important;
      color: $default;
      font-size: 1.3rem;
      height: 4rem;
      width: 4rem;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.active {
      .page-link {
        box-shadow: none;
        border-color: $default;
        border-radius: 0.5rem;
        color: $default;
        z-index: 2;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .pagination {
    .page-item {
      .page-link {
        height: 3rem;
        width: 3rem;
      }
    }
  }
}
// REMOVED FOCUS
:focus {
  outline: none;
}
@include media-breakpoint-down(xs) {
  .craftsmanship,
  .company-story-container,
  .environmental-page-wrapper,
  .options-container {
    h1 {
      font-size: 1.6rem;
    }
    h3 {
      font-size: 2.4rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .session-messages-inner {
    &.add-to-favorites {
      .session {
        width: auto;
      }
    }
  }
}
