@import "./filters/filters";
@import "./fabricDetail/fabricDetail";
@import "./fabricItem/fabricItem";

#fabric-sidebar {
  height: 100vh;
  width: 56rem;
  background-color: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  box-shadow: -3px 0px 6px #00000062;
  .fabric-sidebar-skeleton {
    width: 100%;
    .items-skeleton {
      .col {
        padding: 0 1rem;
      }
    }
  }
  .sidebar-header {
    &.header-bigger-title {
      img {
        @include media-breakpoint-up(sm) {
          top: 2.5rem !important;
        }
      }
    }
    .sidebar-title {
      &.bigger-title {
        width: 80%;
        text-align: center;
        line-height: 3rem;
      }
    }
    h2 {
      font-size: 40px;
      line-height: 1.6rem;
    }
  }
  .sidebar-footer {
    position: sticky;
    bottom: 0;
    button {
      margin: 0;
    }
  }
  .fabric-sidebar-main-content {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 80vh;
    .fabric-sidebar-main-column {
      padding: 0;
      height: 100%;
    }
    .fabrics-sidebar-image-container {
      height: 23rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 32rem;
        object-fit: contain;
      }
    }
    .items-container {
      margin: 0;
      padding: 0 0.5rem;
      padding-bottom: 3rem;
      overflow: visible;
    }
    .items-container::-webkit-scrollbar {
      display: none;
    }
    .fabrics-toolbar {
      background-color: white;
    }
    .fabrics-categories-toolbar {
      padding: 2.5rem 0;
      max-width: 100%;
      .slick-slider {
        .slick-list {
          width: 85%;
          margin: 0 auto;
        }
        .slick-track {
          display: flex;
          align-items: center;
          .slick-slide {
            display: flex;
            justify-content: center;
            > div {
              display: flex;
              align-items: center;
            }
          }
        }
        .slick-arrow {
          z-index: 1;
          &:before {
            width: 0.75rem;
            height: 1.5rem;
            display: block;
            content: "" !important;
          }
          &.slick-prev {
            left: 3rem;
            &:before {
              background: url("../../../../assets/icon/arrowLeft.svg") no-repeat
                center / cover;
            }
          }
          &.slick-next {
            right: 0;
            &:before {
              background: url("../../../../assets/icon/arrowRight.svg")
                no-repeat center / cover;
            }
          }
        }
      }

      .fabrics-categories-toolbar-item {
        padding: 0 1.24rem;
        // max-width: fit-content;
        cursor: pointer;
        p {
          font-size: 16px;
          text-align: center;
          position: relative;
          white-space: nowrap;
          &:after {
            content: "";
            width: 100%;
            height: 0.5rem;
            background: transparent;
            position: absolute;
            bottom: -1rem;
            left: 0;
            transition: all 0.5s ease-in-out;
          }
        }
        &.active-item {
          p {
            font-weight: 600;
            &:after {
              content: "";
              background: $default;
            }
          }
        }
      }
    }
    .fabrics-categories-toolbar::-webkit-scrollbar {
      display: none;
    }
    .empty-fabric-array {
      min-height: 35rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .fabric-sidebar-main-content::-webkit-scrollbar {
    display: none;
  }
}

@media screen and(max-width:600px) {
  #fabric-sidebar {
    width: 100%;
    height: 100%;
    margin: 0;
    .sidebar-header {
      max-height: 7rem;
      h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
    .sidebar-footer {
      width: 100%;
      position: fixed;
      bottom: 0;
      button {
        max-width: 16rem;
      }
      .save-filters-mobile-btn {
        button {
          max-width: 100%;
          width: 100%;
          margin: 0;
        }
      }
      .selected-item {
        margin-bottom: 1.5rem;
        .row {
          flex-direction: column;
        }
      }
    }
    .fabric-sidebar-main-content {
      max-height: 75vh;
      .fabrics-categories-toolbar {
        .slick-slider {
          .slick-list {
            width: 80%;
          }
        }
        .fabrics-categories-toolbar-item {
          padding: 0 1rem;
        }
      }
    }
    .empty-fabric-array {
      min-height: 50rem;
    }
    .items-container {
      .items-column {
        padding: 0;
        .items-row {
          margin: 0;
          justify-content: center;
        }
      }
    }
  }
}
