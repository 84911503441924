.my-downloads-heading {
  h2 {
    padding: 0;
    font-size: 3.2rem;
    line-height: 2rem;
    .my-downloads-product-count {
      margin-left: 4rem;
      font-size: 1.4rem;
    }
  }
}
