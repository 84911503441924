.accordion {
  align-items: center;
  height: auto;
  border-top: 1px solid rgba(217, 217, 217, 1);
}
.accordionNoBorders {
  align-items: center;
  height: auto;
}

.accordion-header {
  cursor: pointer;
  font-weight: 500;
  font-size: 2rem;
  padding: 2rem 0 2rem 0;
}

.accordion-header-active {
  cursor: pointer;
  font-weight: 500;
  font-size: 2rem;
  padding: 2rem 0 1.6rem 0;
}
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, padding 0.4s ease;
}

.accordion-content-active {
  max-height: auto;
  height: 100%;
  padding-bottom: 20px;
}

.accordion-content-active {
  div div:not(:last-child) .checkbox,
  .checkbox:not(:last-child) {
    margin: 0 0 1.2rem 0;
  }
}

@media screen and (max-width: 993px) {
  .accordion-content-active {
    div div:not(:last-child) .checkbox,
    .checkbox:not(:last-child) {
      margin: 0;
      padding: 1.5rem;
    }
  }
}