.option-dropdown-selection {
  max-width: 600px;
  margin-left: 9rem;
  .selector-title {
    padding: 0;
  }
  .checkbox-option {
    margin-bottom: 1.5rem;
    h5 {
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
}

.tabs-container {
  display: flex;
  gap: 1rem;
  padding-left: 9rem;
  margin-bottom: 3.2rem;
  .tabs-option {
    color: #797979;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    border-bottom: 2px solid white;
    cursor: pointer;
    &.active-tabs-option {
      color: #404040;
      border-bottom: 2px solid #6c808b;
    }
  }
}
.modal-option-selection {
  .generic-description {
    margin-left: 9rem;
    .generic-description-value {
      font-weight: 500;
    }
  }
  .sidebar-footer-tab {
    background-color: white;
  }
  .sidebar-item-container .image-container img.selected-image  {
    border: none;
  }
}

.show-additional-trigger {
  color: #797979;
  font-family: $secondaryFontGibson;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
  padding-left: 9rem;
  margin-bottom: 3.2rem;
}
