@include media-breakpoint-up(lg) {
  .three-column {
    height: 70rem;
    border-spacing: 2rem;
    border-collapse: separate;
    .category-promo {
      background-position: center;
      background-size: cover;
      transition: all $transitionDuration ease-in-out;
      background-color: rgba(0, 0, 0, 0.5);
      width: 25%;
      .overlay {
        display: inline-table;
        background-color: rgba(0, 0, 0, 0.25);
      }
      .category-promo-content {
        a {
          color: #ffffff;
        }
        cursor: pointer;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 20rem;
        .button,
        .text {
          visibility: hidden;
          position: absolute;
          bottom: 5rem;
        }
        .separator-block {
          .separator {
            transition: all $transitionDuration ease-in-out;
            width: 4rem;
          }
        }
      }
    }
    &:hover {
      .category-promo {
        width: 20% !important;
        &:hover {
          width: 30% !important;
          .category-promo-content {
            height: 20rem;
            max-width: 32rem;
            display: inline-block;
            .title,
            .button,
            .text {
              visibility: visible;
            }
            .text {
              height: 7rem;
              span {
                line-height: 1.5;
              }
            }
            .separator-block {
              margin-bottom: 0.5rem !important;
              height: 2rem !important;

              .separator {
                transition: all $transitionDuration ease-in-out;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .three-column {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    .category-promo {
      margin: 0.75rem 0;
      background-position: center;
      background-size: cover;
      transition: none;
      width: 100%;
      min-height: 14rem;
      height: 100%;
      padding: 4rem;
      .category-promo-content {
        a {
          text-align: center;
          color: #ffffff;
        }
        position: relative !important;
        .separator-block {
          .separator {
            width: 4rem;
          }
        }
      }
    }
  }
}
