.collection-wrapper {
  .collection-bottom-toolbar {
    > .row {
      width: 100%;
    }
  }
  .iFrame-wrapper {
    margin: 0 auto;
    width: 114rem;
    height: 70rem;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
}

.plp-container-wrap {
  padding: 0 8rem;
  max-width: 100%;
}

.collection-row {
  position: sticky;
  top: 146px;
  z-index: 90;
  background: white;
}

@media screen and(max-width:1440px) {
  .plp-container-wrap {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 1100px) {
  .collection-wrapper {
    .iFrame-wrapper {
      width: 90rem;
      height: 65rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .collection-wrapper {
    .iFrame-wrapper {
      width: 70rem;
      height: 60rem;
    }
  }

  .collection-row {
    top: 52px;
  }
}

@media screen and (max-width: 800px) {
  .collection-wrapper {
    .iFrame-wrapper {
      width: 55rem;
      height: 45rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .collection-wrapper {
    .iFrame-wrapper {
      width: 100%;
      height: 45rem;
    }
  }
}

@media screen and (max-width: 350px) {
  .collection-wrapper {
    .iFrame-wrapper {
      width: 100%;
      height: 45rem;
    }
  }
}
