.static-page {
  ul {
    li {
      a {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        text-decoration: underline !important;
      }
    }
    li:nth-child(2) {
      a {
        text-decoration: none !important;
        color: $primary;
      }
      a:hover {
        color: $default;
      }
    }
  }
}
