.item-detail-container {
  a {
    text-decoration: none !important;
  }
}
.item-price-container {
  span {
    color: $default;
    font-size: $fs-16;
  }
}

.item-subtotal-container {
  span {
    color: $default;
    font-size: $fs-16;
  }
}

.item-remove-container {
  cursor: pointer;
  i {
    font-size: 2.5rem;
  }
}

.item-options-container {
  padding-left: 2.5rem;
  .item-option-name {
    color: $secondary-color;
    font-size: $fs-16;
  }
  .item-option-value {
    color: $default;
    font-size: $fs-14;
  }
}

$cart-image-size: 21.2rem;
.item-image-container {
  min-height: 15.1rem;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  img {
    max-width: $cart-image-size;
    max-height: $cart-image-size;
  }
  a {
    &.edit-link {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 3rem;
      height: 3rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.item-qty-container {
  .loading-container {
    margin-left: 0.5rem;
  }
}

.disable-minus {
  .qty-click.qty-click-minus {
    svg {
      cursor: default;
      g,
      cricle:last-of-type,
      path {
        stroke: $secondary-color !important;
      }
    }
  }
}
/*Media Queries*/
@include media-breakpoint-down(md) {
  .items-table {
    .mobile-title {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .item-price-container-label,
  .item-options-container-label,
  .item-subtotal-container-label {
    display: flex;
    align-items: center;
  }
}
