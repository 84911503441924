@import "./topMenu/topMenu";

.icons-wrapper {
  position: relative;
  right: 6rem;
  > div > div {
    padding: 0 0.8rem;
    max-width: 5rem;
  }
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(4%) hue-rotate(196deg) brightness(106%) contrast(100%);
  }
  @media screen and (max-width: 1025px) and (min-width: 992px) {
    padding: 0 0.6rem;
  }
  @media screen and (max-width: 991px) {
    right: 0;
    max-width: 100%;
  }
}
.dropdown-menu-wrapper {
  transition: all 0.65s ease-in-out;
  &.hide {
    transform: translateY(-80rem);
    opacity: 0;
    .dropdown-items-wrapper {
      box-shadow: none;
    }
  }
  .dropdown-items-wrapper {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 6rem;
    box-shadow: 0 0.8rem 0.6rem #00000064;
    border-radius: 0 0 1rem 1rem;
    /*Media Queries*/
    @media screen and (max-width: 1440px) {
      padding-left: 7%;
      padding-right: 7%;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
    > div {
      padding-top: 3.5rem;
    }
    h2 {
      font-size: $fs-14;
    }
    a {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      &.link-2 {
        font-size: $fs-13;
      }
      &:hover {
        text-decoration: underline !important;
      }
    }
    .img-fluid {
      img {
        object-fit: cover;
        width: 100%;
      }
    }
    span {
      &.link-2 {
        font-size: $fs-13;
      }
    }
    .gray-bg {
      background: rgba($secondary, 0.15);
    }
    .col-with-cms-block {
      a {
        padding: 0;
        &:hover {
          text-decoration: none !important;
        }
      }
      .react-loading-skeleton {
        height: 30rem !important;
        @media screen and (max-width: 1440px) {
          height: 25rem !important;
        }
      }
    }
    .left-col,
    .right-col {
      a {
        img {
          object-fit: cover;
          width: 100%;
        }
        span {
          &:after {
            content: "";
            background: url("../../../../assets/icon/rightMobile.svg") center
              no-repeat;
            position: absolute;
            width: 2rem;
            height: 2rem;
            right: 0;
            background-size: cover;
          }
        }
      }
    }
    .left-col {
      img {
        max-height: 60rem;
        /*Media Queries*/
        @media screen and (max-width: 1920px) {
          max-height: 50rem;
        }
        @media screen and (max-width: 1600px) {
          max-height: 40rem;
        }
      }
    }
    .right-col {
      img {
        max-height: 27.8rem;
        /*Media Queries*/
        @media screen and (max-width: 1920px) {
          max-height: 22.8rem;
        }
        @media screen and (max-width: 1600px) {
          max-height: 17.8rem;
        }
      }
    }
    ul {
      &.menu-ul {
        list-style: none;
        margin: 0;
      }
    }
    .col-fab-menu {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
      -webkit-column-gap: 0;
      -moz-column-gap: 0;
      column-gap: 0;
      > ul {
        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid; /* Theoretically FF 20+ */
        break-inside: avoid-column; /* IE 11 */
        display: table; /* Actually FF 20+ */
        width: 100%;
        &.menu-ul {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
/*Media Queries*/
@media screen and (min-width: 1440px) {
  .dropdown-menu-wrapper {
    .dropdown-items-wrapper {
      h2 {
        font-size: $fs-14;
      }
      a {
        span {
          font-size: $fs-14;
        }
        h5,
        h2 {
          font-size: $fs-14;
        }
        &.link-2 {
          font-size: $fs-14;
        }
      }
      span {
        &.link-2 {
          font-size: $fs-14;
        }
      }
      .col-with-cms-block {
        a {
          p {
            font-size: 1.7rem;
            line-height: 1.7rem;
          }
        }
      }
      .col-fab-menu {
        > ul {
          &.menu-ul {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}
