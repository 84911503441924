.environmental-page-wrapper {
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

@media screen and(max-width:485px) {
  .environmental-page-wrapper {
    img {
      max-height: 360px;
    }
  }
}
