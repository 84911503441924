%with-appear-transition {
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}
%appear {
  opacity: 1;
  visibility: visible;
}

.fabric-pattern-widget {
  .badges {
    @extend %with-appear-transition;
  }
  .fabric-pattern-widget-info {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.fabric-pattern-widget:hover {
  .badges {
    @extend %appear;
  }
}

@media screen and(max-width:992px) {
  .fabric-pattern-widget {
    .img-fluid {
      max-height: 35rem !important;
    }
  }
}
