.related-container-wrap {
  @media (min-width: 1920px) {
    max-width: 1440px;
  }
}

.related-container {
  margin: 6rem 0 0;
  margin-bottom: 30px;
  h1 {
    font-weight: 300;
    color: $default;
    font-size: 2.4rem;
    line-height: 1.2;
    margin: 0;
    float: left;
    width: 100%;
  }
  .related-title {
    font-family: $secondaryFontGibson;
    font-weight: 200;
  }

  .product-container {
    // height: 320px;
    @media screen and (max-width: 425px) {
      height: unset;
    }
    padding: 0 15px;
    position: relative;

    &:hover {
      p {
        background-color: $gray-lighten-4;
      }

      .badges {
        opacity: 1;
      }
    }

    > img {
      width: 100%;
      // margin: calc((250px - 100%) / 2) 0;
    }

    p {
      color: $primary;
      font-size: 1.6rem;
      font-weight: 500;
      text-align: center;
      padding: 10px 20px;
      transition: 0.3s all ease-in-out;
      background: #fff;
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .badges {
      opacity: 0;
      transition: 0.3s all ease-in-out;
      position: absolute;
      width: 100%;
      bottom: 150px;
      left: 0;

      .badge-container {
        margin: 0 auto;
        width: fit-content;

        > div {
          width: fit-content;
          margin: 0 !important;
          float: left;

          > * {
            float: left;
            margin: 0 5px;
          }
        }
      }
    }
  }
  .related-products-slider {
    .slick-arrow {
      top: 35%;
      &.slick-prev {
        left: -35px;
        &:before {
          opacity: 1;
          content: "";
          background-image: url("../../../../../../app/assets/icon/leftMobile.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      &.slick-next {
        right: -65px;
        &:before {
          // transform: rotate(-180deg);
          opacity: 1;
          content: "";
          background-image: url("../../../../../../app/assets/icon/leftMobile.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      @media screen and (max-width: 1500px) {
        top: 100%;
        &.slick-prev {
          left: 43%;
        }
        &.slick-next {
          right: 40%;
        }
      }
      @media screen and (max-width: 1050px) {
        top: 100%;
        &.slick-prev {
          left: 40%;
        }
        &.slick-next {
          right: 36%;
        }
      }
      @media screen and (max-width: 425px) {
        &.slick-prev {
          left: 15%;
        }
        &.slick-next {
          right: 3%;
        }
      }
    }
  }
}
