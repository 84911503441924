#my-downloads-listing {
  padding-top: 2rem;
  padding-bottom: 10rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #000;
  transition: all 0.2s ease-in-out;
  &.no-items {
    padding-top: 4rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 0;
  }
  @import "./item/item";
}
