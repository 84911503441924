.grades-list {
  h3 {
    font-size: 24px;
    color: #404040;
  }
  .btn-grade-list {
    border: none;
    width: auto;
    box-shadow: none !important;
  }
  .table-grid {
    thead {
      border-top: 1px solid #6c808b;
    }
    tbody > tr > td,
    > thead > tr > th {
      font-size: 16px;
      padding: 17px 2rem;
    }
    tbody > tr > td {
      color: #888888;
    }
    > thead > tr > th {
      border-bottom: 0;
      color: #404040;
    }
    tr {
      .tablet-heading {
        font-size: 16px;
        color: #404040;
        display: inline-block;
        margin-right: 10px;
        min-width: 50px;
      }
      &:last-of-type {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .grades-list .table-grid tr p.tablet-heading {
    display: block;
  }
}

@media screen and (min-width: 769px) {
  table.table.table-grid {
    tr {
      th {
        &:first-of-type,
        &:last-of-type {
          width: 200px;
        }
        &:nth-of-type(2) {
          width: 350px;
        }
        &:nth-of-type(3) {
          width: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .grades-list {
    > div {
      flex-flow: column;
      align-items: flex-start !important;
      button {
        justify-content: end;
        padding: 0.84rem 0;
        margin: 0.375rem 0;
      }
    }
    thead {
      display: none;
    }
    tbody tr {
      display: flex;
      flex-direction: column;
      border-top: 1px solid #dee2e6;
      td {
        border: none;
        display: flex;
      }
    }
  }
}
