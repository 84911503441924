.my-downloads-item-modal {
  @import "./images/images";

  .my-downloads-item-modal-content-header {
  }

  .my-downloads-item-modal-content-footer {
  }
}
.my-downloads-item-modal-content {
  .modal-body {
    max-height: 45rem;
    overflow-y: scroll;
  }
}
