.react-select__value-container {
  padding-left: 2.5rem !important;
}

.react-select__indicator {
  color: $default !important;
}

.contact-information {
  height: 18rem;
}
.modal {
  .modal-dialog {
    &.download-project-modal {
      .text-red {
        bottom: 0rem;
      }
      .phone-input {
        .text-red {
          bottom: -1rem;
        }
      }
      &.wide-modal {
        @media screen and (max-width: 600px) {
          h1 {
            font-size: 2rem;
          }
          .contact-information {
            height: 100%;
            .md-form {
              width: 100%;
              label {
                font-size: 1.5rem !important;
                &.active {
                  top: -2rem;
                }
              }
            }
            .phone-input {
              label {
                margin-bottom: 0 !important;
                h2 {
                  font-size: 1.5rem !important;
                  &.label-text {
                    &.required {
                      &:after {
                        top: 0;
                      }
                    }
                  }
                }
              }
              .text-red {
                bottom: -1.5rem;
              }
            }
          }
          .project-pdf-layout {
            width: 8.1rem;
            height: 11.1rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
.favorite-pdf-image {
  margin-top: 6rem !important;
}
.notes-pdf {
  // position: absolute;
  // bottom: 7%;
  left: 0;
  width: 100%;
  margin-bottom: 5px;
  .col-11 {
    p {
      word-break: break-all;
    }
  }
}

