.heading-timeline-year {
    font-size: 5.4rem;
    color: $gray-darken-3;
    font-family: $fontUtopia;
    font-weight: 600;
    text-transform: none !important;
    @media(max-width: $medium-screen) {
        font-size: 3.2rem;
    }
    @media(max-width: $small-screen) {
        font-size: 2.4rem;
    }
}
.timeline-photo {
    object-fit: contain;
}
.timeline-wrap {
    .hero-banner .hero-banner-row .pagebuilder-column-group {
        bottom: 0;
    }
    strong b, b strong {
        font-weight: 500 !important;
    }
    br {
        display: none;
    }
    .row p {
        margin-bottom: 10px;
    }
}