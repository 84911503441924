#download-tearsheet {
  .modal-dialog {
    max-width: 56rem;
    .modal-header {
      .modal-title-pdf {
        cursor: pointer;
        bottom: 50%;
        transform: translate(0, 50%);
      }
    }
    .modal-content {
      max-width: 100%;
      border-top: 0.3rem solid $primary-color;
      .select2 {
        &.not-disabled {
          opacity: 1;
          .react-select-container {
            .react-select__control {
              border: 2px solid $default;
              border-color: $default !important;
              opacity: 1;
            }
          }
        }
        &.disabled {
          .react-select-container {
            .react-select__control {
              border: 2px solid $default;
              border-color: $default !important;
              opacity: 0.5;
            }
          }
        }
      }

      .md-form {
        height: 100%;
        width: 100%;
        margin: 0 !important;

        input[type="number"] {
          font-size: 1.4rem;
          box-sizing: border-box;
          width: 100% !important;
          border: 2px solid $default;
          border-color: $default;
          border-radius: 5px;
          height: 4rem;
          padding: 1rem 2rem;
          color: #333 !important;
          opacity: 1;
          box-shadow: none;
          &.disabled {
            opacity: 0.5;
          }
        }
        textarea.md-textarea {
          height: 100%;
          max-height: 10rem;
        }
      }

      .modal-body {
        padding: 2.5rem 4rem 2.5rem;
        h5 {
          font-size: 1.4rem;
        }
        .modal-inner-content {
          padding: 0;

          .textarea-char-num {
            color: red;
            font-size: 1.2rem;
            line-height: 1.6rem;
            letter-spacing: 0.167rem;
            padding: 0 1rem;
            &.validation-error {
              color: $danger;
            }
          }
          .enter-value-container {
            .enter-value {
              padding: 0;
              line-height: 2rem;
              font-size: 14px;
              border: none;
              border-bottom: 0.1rem solid -light;
              background: transparent;
              height: auto;
              &:focus {
                border: none !important;
                border-bottom: 0.1rem solid -light !important;
              }
            }
          }
          .modal-buttons {
            margin-top: 4rem;
            .white-button {
              padding: 1.7rem 0 !important;
            }
          }
          .form-check-input {
            cursor: pointer;
            position: absolute;
            margin-top: 0;
            margin-left: 0;
            left: 0;
            top: 0;
            width: 2rem;
          }
          .form-check-label {
            cursor: pointer;
            font-size: 1.4rem;
            font-weight: normal;
          }
          .checkbox-wrapper {
            margin-bottom: 2.5rem;
          }
        }
      }
    }
  }
  .check-button {
    &.form-check {
      padding-left: 3rem;
      line-height: 1.8;
      cursor: pointer;
      height: 100%;
    }
  }
  ::-webkit-input-placeholder {
  }

  :-moz-placeholder {
    /* Firefox 18- */
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
  }
}
@include media-breakpoint-up(sm) {
  #download-tearsheet {
    button {
      &.btn {
        min-width: 16rem;
      }
    }
    .modal-dialog {
      .modal-content {
        .modal-body {
          .modal-inner-content {
            h3 {
              font-size: 3.1rem;
              line-height: 2.6rem;
              letter-spacing: 0.129rem;
              margin-bottom: 3.5rem;
            }
          }
        }
        .md-form {
          padding-left: 0;
        }
      }
    }
  }
}
