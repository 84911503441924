.collection-wrapper {
  .separator {
    color: red;
  }
  .no-conf-wrapper {
    margin-top: 5rem;
    margin-bottom: 8rem;
    .name-and-sku-wrapper {
      &:hover {
        background-color: #f7f7f7;
      }
      .custom-price {
        @include media-breakpoint-down(xs) {
          font-size: $fs-13;
        }
      }
    }
    .frame-image-wrapper {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        &.monogramImg {
          height: 25.5rem;
        }
      }
      &.live-edge-frame {
        width: 25.5rem;
        height: 11.5rem;
        @media screen and (max-width: 768px) {
          width: auto;
        }
      }
    }
  }
  .column {
    flex: 0 0 26%;
    .imageloader {
      position: relative;
      width: 26rem;
      height: 26rem;
      border-radius: 13rem;
      background: $gray-lighten-4;
      margin: 0 auto;
      .img-fluid {
        position: absolute;
        left: 50%;
        top: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .collection-how-works {
    > div[data-element] {
      display:contents;
      > div {
        max-width: 85% !important;
      }
    }
    &.no-items {
      grid-template-columns: 1fr;
    }
    &.have-items {
      grid-template-columns: 1fr 1fr 1fr;
    }
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    > div {
      max-width: 85% !important;
    }
    .imageloader {
      cursor: initial;
      position: relative;
      width: 26rem;
      height: 26rem;
      border-radius: 13rem;
      background: $gray-lighten-4;
      margin: 0 auto;
      .img-fluid {
        position: absolute;
        left: 50%;
        top: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }
    h3 {
      min-height: 6rem;
    }
  }
  .collection-buttons-pdf {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: max-content;
    margin: 0 auto;
  }
}
.collection-img {
  cursor: initial;
  object-fit: cover;
  height: 41rem;
}
.collection-products-wrapper {
  h4 {
    min-height: 6rem;
  }
}
@include media-breakpoint-down(lg) {
  .collection-wrapper {
    .collection-how-works {
      display: grid;
      grid-gap: 0;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      align-items: center;
      > div {
        max-width: 85% !important;
      }
      > div[data-element] {
        > div {
          max-width: 85% !important;
        }
      }
      h3 {
        min-height: 100%;
      }
    }
    .collection-buttons-pdf {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
      max-width: 100%;
      margin: 0 auto;
    }
  }
}
@include media-breakpoint-down(md) {
  .collection-wrapper {
    &.collection-custom {
      .steps-description-wrapper {
        .step-item {
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
          h1 {
            font-size: 3.2rem;
            margin-right: 2rem;
          }
          h2 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .collection-wrapper {
    .collection-how-works {
      grid-template-columns: 1fr;
    }
  }
  .collection-products-wrapper {
    h4 {
      min-height: 100%;
    }
  }
}
@include media-breakpoint-down(xs) {
  .collection-wrapper {
    &.collection-custom {
      .steps-description-wrapper {
        .step-item {
          margin-right: 0.5rem;
          &:last-child {
            margin-right: 0;
          }
          h1 {
            font-weight: 100;
            margin-right: 1rem;
          }
          h2 {
            font-size: $fs-16;
          }
        }
      }
    }
  }
}
@media screen and(max-width:480px) {
  .collection-wrapper {
    &.collection-custom {
      .steps-description-wrapper {
        .step-item {
          h2 {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
