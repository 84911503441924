@import "./galleryDetail/galleryDetail";
.gallery-items-wrapper {
  transition: all 0.8s ease-in-out;
  // -moz-column-count: 4;
  // -webkit-column-count: 4;
  // column-count: 4;
  // column-gap: 1rem;
  // -moz-column-gap: 1rem;
  // -webkit-column-gap: 1rem;
  // column-fill: initial;
  // vertical-align: baseline;

  // display: flex;
  // flex-wrap: wrap;
  // gap: 2.4rem;

  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.4rem;

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
  /*Media Queries*/
  @include media-breakpoint-down(md) {
    // -moz-column-count: 2;
    // -webkit-column-count: 2;
    // column-count: 2;
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(xs) {
    // -moz-column-count: 1;
    // -webkit-column-count: 1;
    // column-count: 1;

    grid-template-columns: repeat(1, 1fr);
  }
  .gallery-item {
    // margin-bottom: 1rem;
    break-inside: avoid;
    max-width: 42rem;
    display: flex;
    // width: 100%;
    .our-installation-img {
      padding: 15px;
    }
    @include media-breakpoint-down(xs) {
      max-width: 100%;
    }

    .our-installation-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 52rem;
    }

    // .num-of-images-wrapper {
    //   right: 0;
    //   z-index: 1;
    //   .num-of-images {
    //     top: 1.2rem;
    //     left: 1.5rem;
    //     &.more-then-nine-images {
    //       left: 1.05rem;
    //     }
    //   }
    // }

    .imageloader {
      height: 100%;
      width: 100%;
      span {
        width: 100%;
        height: 100%;
        .react-loading-skeleton {
          height: 30rem;
        }
      }
    }
    // img {
    //   &.our-installation-img {
    //     width: 100%;
    //   }
    // }
  }
  @include media-breakpoint-down(xs) {
    flex-direction: column !important;
  }
  .gallery-modal-popup {
    max-width: 127.8rem;
    .modal-content {
      @include media-breakpoint-down(lg) {
        max-height: 70rem;
        overflow-y: scroll;
      }
      @include media-breakpoint-down(md) {
        max-height: 80rem;
        overflow-y: scroll;
      }
      @include media-breakpoint-down(xs) {
        max-height: 65rem;
        overflow-y: scroll;
      }
    }
  }
}

.hidden-overflow {
  overflow-y: hidden !important;
}

.h1-bg {
  background-color: #9da4ac;
  padding-bottom: 6rem;
  padding-top: 6rem;
  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}

.gallery-container-wrap {
  padding: 0 9rem;
  max-width: 100%;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 0 3rem;
  }
}

.gallery-title {
  font-weight: $fwmedium;
  font-size: 2.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0px;
  color: $gray-darken-3;
  height: 5rem;
  max-height: 100%;
  flex-wrap: wrap;
  display: flex;
  padding: 0 1rem;

  @media screen and (max-width: 1258px) {
    height: 8rem;
  }

  @media screen and (max-width: 1075px) {
    height: 10rem;
  }

  @media screen and (max-width: 991px) {
    height: 5rem;
  }
}

.gallery-border {
  border: 1px solid $gray-lighten-5;
}
