.filters-boolean-wrapper {
  margin: 0;
  margin-left: 1.5rem;
  .switch-filter-container {
    padding: 0;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray;
    margin-bottom: 2rem;
    .switch {
      span {
        color: $default;
      }
    }
  }
}
