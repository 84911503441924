.base-options-container {
  background-color: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  height: 100%;
  width: 56rem;
  display: block;
  box-shadow: -3px 0px 6px #00000062;
  margin: 0;
  .modal-footer {
    button {
      width: 21.2rem;
      &.btn-secondary {
        background-color: white !important;
      }
      @media screen and (max-width: 600px) {
        width: 15rem;
      }
    }
  }
  .items-container {
    max-height: calc(100% - 200px);
    .image-container {
      position: relative;
      &.player-display-badge {
        &:after {
          content: "";
          display: block;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          background: url("../../assets/icon/badge.svg") no-repeat;
          background-size: contain;
          position: absolute;
          bottom: 1.9rem;
          left: 2.1rem;
          opacity: 0.7;
        }
      }
    }
    .selected-option-details {
      display: grid;
      grid-template-columns: 1.5fr 2fr;
      grid-gap: 1.5rem;
      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
      }
      .details-holder {
        position: relative;
        top: 1.7rem;
      }
      img {
        width: 17.8rem;
        height: 17.8rem;
      }
      .d-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        p,
        a {
          font-size: 1.4rem;
        }
        a {
          text-decoration: underline !important;
        }
      }
    }
  }
  .d-grid-options {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(4, 1fr);
    }
    place-items: center;
    .option {
      width: 76px;
      height: 76px;
      cursor: pointer;
      position: relative;
      &.selected {
        img {
          &:last-of-type {
            border-radius: 5px;
          }
          border: 1px #6c808b solid;
        }
      }
      img {
        width: 56px;
        height: 56px;
        // border-radius: 5px;
      }
      .selected {
        position: absolute;
        width: 15px;
        height: 15px;
        bottom: 20px;
        right: 20px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .base-options-title {
      &.drawer-finish {
        position: relative;
        left: 2.4rem;
      }
    }
  }
  @media screen and(max-width:992px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .base-options-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    .base-options-title {
      font-size: 40px;
      line-height: 7.4rem;
    }
    img {
      position: absolute;
      left: 3rem;
      cursor: pointer;
    }
    @media screen and(max-width:992px) {
      .base-options-title {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }
  .sidebar-footer {
    position: absolute;
    bottom: 0;
    height: 12.5rem;
    width: 100%;
    box-shadow: 0px 0px 5px #00000062;
    button {
      margin: 0;
    }
    @media screen and (max-width: 992px) {
      padding: 2rem;
    }
  }
  .items-container {
    overflow: scroll;
    .base-option {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .option-title {
        h4 {
          font-size: 14px;
        }
      }
      .img-fluid {
        height: 25rem;
        width: 25rem;
        border-radius: 5px;
        @media screen and(max-width:600px) {
          height: 10rem;
          width: 10rem;
        }
      }
      .select-option-icon {
        position: absolute;
        bottom: 0;
        right: 1rem;
        height: 3.3rem;
        width: 3.3rem;
        border-radius: 5px 0px 5px 0px;
        opacity: 0;
        transition: 0.1s ease-in-out;
        &.selected {
          opacity: 1;
          transition: 0.1s ease-in-out;
        }
        @media screen and(max-width:600px) {
          height: 2rem;
          width: 2rem;
        }
      }
      &:hover {
        .select-option-icon {
          opacity: 0.5;
          transition: 0.1s ease-in-out;
          &.selected {
            opacity: 1;
          }
        }
      }
    }
  }
  .items-container::-webkit-scrollbar {
    display: none;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {
  .controls,
  .options-section-wrapper {
    h3.fwlight,
    h1 {
      font-size: 1.6rem !important;
    }
    .body-3,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1.4rem !important;
    }
  }
}
// @media screen and (min-width: 768px) and (max-width: 1280px) {
//   .options-section-wrapper {
//     .collapse,
//     .collapsing {
//       margin: 0 !important;
//     }
//   }
// }
@include media-breakpoint-down(md) {
  .controls {
    &.recliner-true {
      margin-top: 5rem;
    }
  }
}
@media screen and (max-width: 768px) {
  .controls {
    .configurator-sidebar {
      .body-3 {
        font-size: 2.4rem !important;
      }
    }
    .body-3 {
      font-size: 1.6rem !important;
    }
  }
}

.show-nails {
  width: 40px;
  height: 40px;
  background: #d8d8d8;
  border-radius: 50%;
  transition: 0.1s all linear;
  position: relative;

  &.selected {
    background: #6c808b;
  }

  img {
    width: 40px;
    height: 40px;
  }
}
