@import "./homepageBanner/homepageBanner";
@import "./HomeContainerV2/homepageContainerV2";
.whats-new-container {
  .product-widget {
    a {
      height: unset !important;
      .product-name {
        max-height: 3rem;
        height: 100%;
      }
    }
  }
  .whats-new-title {
    white-space: nowrap;
  }
}
.plr-6 {
  padding: 0 6rem;
  .building-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #a8aeb6;
    margin-top: 1rem;
    padding: 1rem 0;
    p {
      font-size: 2rem;
      color: white;
    }
  }
  .video-wrapper {
    // height: 60rem;
    position: relative;
    .iframe {
      // margin-bottom: 10rem;
      padding-bottom: 56.25%;
      /* 16:9 */
      padding-top: 25px;
      height: 0;
      width: 100%;
      background-color: white;
      opacity: 1;
      position: relative;
      max-width: 100%;
      transition: all 0.2s;
      overflow: hidden;
      iframe {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: all 0.2s;
      }
    }
  }
}

.homepage-hero-banner {
  > .row {
    > div {
      overflow-x: clip;
      padding: 2rem 1.5rem;
    }
  }
}
@include media-breakpoint-up(xl) {
  .home-container {
    padding: 0;
    .container-fluid {
      margin: 10rem auto;
    }
  }
}
