// .dropdown {
//   .not-active {
//     display: none;
//   }
// }
@import "./items/items";
.toggle-dropdown {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: $white;
  transition: transform 0.2s ease-in-out;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  li {
    transition: visibility 0.2s ease-in-out;
  }
  &.active {
    transform: translateX(0%);

    li {
      visibility: hidden;
      border-bottom: 1px solid $gray-lighten-4;
    }
    color: $white;
    a {
      color: $white;
    }
    &.last-active {
      opacity: 1;
      visibility: visible;
      z-index: 9999;

      color: $black;

      li {
        visibility: visible;
      }
      a {
        color: $black;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .toggle-dropdown {
    height: calc(100vh - calc(100vh - 100%));
  }
}
