@import "./searchBuildCollection/searchBuildCollection";
.search-modal-wrapper {
  .md-form {
    width: 100%;
    img {
      position: absolute;
      top: 50%;
      left: 6%;
      bottom: 50%;
      transform: translate(-50%, -50%);
    }
    input {
      text-overflow: ellipsis;
      &.form-control {
        box-sizing: border-box;
        width: 100%;
        padding: 1rem 6rem;
      }
    }
  }
  .search-bottom-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  .select2 {
    .react-select-container {
      .react-select__control {
        .react-select__placeholder {
          opacity: 1;
        }
        &--is-focused {
          border: 2px solid $default;
          border-color: $default !important;
          opacity: 1;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .search-modal-wrapper {
    .md-form {
      input {
        text-overflow: ellipsis;
        &.form-control {
          text-align: left !important;
          box-sizing: border-box;
          width: 100%;
          padding: 1rem 4rem;
        }
      }
    }
    .search-bottom-fields {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0;
    }
  }
}
