// BLOCK SKELETON
@include media-breakpoint-up(sm) {
  .skeleton {
    &.homepage_hero_baner {
      height: 100rem !important;
    }
    &.homepage_showroom_image {
      height: 58rem !important;
    }
    &.homepage_showroom_text_1 {
      height: 20rem !important;
    }
    &.homepage_showroom_text_1 {
      height: 20rem !important;
    }
    &.homepage_explore_blocks {
      height: 70rem !important;
    }
    &.homepage_libby {
      height: 111rem !important;
    }
    &.footer-static-links {
      height: 23rem !important;
    }
    &.footer-updates-signup {
      height: 12rem !important;
    }
    &.footer-social-links {
      height: 6.7rem !important;
    }
    &.footer-bottom {
      height: 5rem !important;
    }
    &.search-build-container {
      height: 52rem !important;
    }
    &.pdp-promo-1,
    &.pdp-promo-2 {
      height: 54.5rem !important;
    }
  }
}
// IMAGES SKELETON
.imageloader {
  // cursor: pointer; //Removed because this is global class from Image component
  span {
    line-height: 1;
    font-size: 1.3rem;
  }
}
@include media-breakpoint-up(sm) {
  .skeleton-img {
    &.whats-new-skeleton {
      height: 100% !important;
    }
    &.homepage_showroom_image {
      height: 58.4rem !important;
    }
    &.homepage_hero_baner {
      height: 109.4rem !important;
    }
    &.homepage_libby {
      height: 114.7rem !important;
    }
    &.search_build_own {
      height: 52rem !important;
    }
    &.pdp_promo_two {
      height: 54.5rem !important;
    }
    &.pdp_promo_one {
      height: 54.5rem !important;
    }
    &.installation_gallery {
      height: 109.4rem !important;
    }
  }
}
// MEDIA BREAKPOINTS
@include media-breakpoint-down(xs) {
  .skeleton {
    &.homepage_hero_baner {
      height: 100rem !important;
    }
    &.homepage_showroom_image {
      height: 58rem !important;
    }
    &.homepage_showroom_text_1 {
      height: 20rem !important;
    }
    &.homepage_showroom_text_1 {
      height: 20rem !important;
    }
    &.homepage_explore_blocks {
      height: 70rem !important;
    }
    &.homepage_libby {
      height: 111rem !important;
    }
    &.footer-static-links {
      height: 11.2rem !important;
    }
    &.footer-updates-signup {
      height: 12rem !important;
    }
    &.footer-social-links {
      height: 6.7rem !important;
    }
    &.footer-bottom {
      height: 5rem !important;
    }
    &.search-build-container {
      height: 40rem !important;
    }
    &.pdp-promo-1,
    &.pdp-promo-2 {
      height: 32rem !important;
    }
  }
}
@include media-breakpoint-down(xs) {
  .skeleton-img {
    &.whats-new-skeleton {
      height: 100% !important;
    }
    &.homepage_showroom_image {
      height: 45rem !important;
    }
    &.homepage_hero_baner {
      height: 28.5rem !important;
    }
    &.homepage_libby {
      height: 15rem !important;
    }
    &.search_build_own {
      height: 40rem !important;
    }
    &.pdp_promo_two {
      height: 32rem !important;
    }
    &.pdp_promo_one {
      height: 32rem !important;
    }
    &.installation_gallery {
      height: 29rem !important;
    }
  }
}
