.compare-list-container {
  position: fixed;
  bottom: 0;
  background: white;
  color: #333;
  min-height: 7.6rem;
  &.closed {
    bottom: -7rem;
  }
  &.slide {
    max-height: 0;
    transition: all 0.4s ease-in-out;
    &.new-transition-exit-active,
    &.new-transition-exit-done {
      max-height: 7.8rem;
      background-color: #e4edf3;
      transition: all 0.4s ease-in-out;
    }
    &.new-transition-enter-active,
    &.new-transition-enter-done {
      max-height: 100%;
    }
  }
  .container-btn {
    background-color: #e4edf3;
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .compare {
    position: relative;
    .remove-compare {
      top: 0;
      right: 0;
      width: 2rem;
      height: 2rem;
      button {
        width: 2rem !important;
        height: 2rem;
        margin: 0 !important;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        font-size: 0;
      }
      .far {
        position: absolute;
        top: 0;
        right: 0;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
      }
    }
    .item {
      cursor: pointer;
    }
    .options-btn {
      button {
        width: 100%;
        line-height: 1;
        background: none;
        border: 1px solid #777;
        padding: 1.5rem;
        &:focus {
          outline-color: transparent;
        }
      }
    }
    .attributes-list-item {
      font-size: 1.2rem;
      border-bottom: 1px solid #777;
      word-break: break-all;
    }
  }
  .circle-btn {
    cursor: pointer;
    border: none;
    border-bottom: 0;
    background: #e4edf3;
    position: absolute;
    right: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 8rem;
    height: 4rem;
    border-top-left-radius: 10rem;
    border-top-right-radius: 10rem;
    .fa {
      margin: 1.5rem;
    }
  }
  button {
    &:focus {
      color: transparent !important;
      outline: transparent !important;
    }
  }
}
.container {
  &.compare {
    padding: 2rem 0;
  }
}
.attribute-box {
  &.slide {
    max-height: 0;
    transition: all 0.4s ease-in-out;
    &.attribute-transition-exit-active,
    &.attribute-transition-exit-done {
      max-height: 0;
      transition: all 0.4s ease-in-out;
    }
    &.attribute-transition-enter-active,
    &.attribute-transition-enter-done {
      max-height: 20rem;
      transition: all 0.4s ease-in-out;
      overflow: hidden;
    }
    .attributes-list {
      transition: all 0.4s ease-in-out;
      max-height: 100%;
    }
  }
}
$compareColor: $primary-color;
.compare-list-container {
  .compare {
    .remove-compare {
      color: $compareColor;
    }
    .options-btn {
      button {
        border-radius: 5px;
        background-color: $compareColor;
        color: #fff;
        border: none;
      }
    }
  }
  .container-btn {
    background-color: $compareColor;
    h5 {
      color: #fff;
    }
    .compare {
      .circle-btn {
        background-color: $compareColor;
        left: 50.9%;
        i.fa {
          color: #fff;
        }
      }
    }
  }
}
