@keyframes pulsate {
  0% {
    background-color: transparent;
  }

  70% {
    background-color: rgba($primary-color, 0.45);
  }

  100% {
    background-color: transparent;
  }
}
.hotspotContentContainer {
  .view {
    overflow: visible;

    .custom-mask {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-attachment: fixed;
      opacity: 0;
      background-color: transparent;
      transition: all 0.4s ease-in-out;
      .download-image-small-button {
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        transition: all 0.4s ease-in-out;
      }
    }
    &:hover {
      .custom-mask {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.3);
        .download-image-small-button {
          visibility: visible;
        }
      }
    }
  }
  // .img-slider {
  //   height: 51.5rem;
  //   object-fit: contain;
  // }
  .mask.hotSpot {
    z-index: 2;
    &.hotspot-active {
      z-index: 9;
      .hotspot-circle {
        background: url("../../assets/icon/closeHotspot.svg") no-repeat;
        background-position: center;
        &:hover {
          &::after {
            transform: scale(2);
          }
        }
      }
    }
  }
  .hotspot-inactive {
    .hotspot-circle {
      border-color: transparent;
      background-color: rgba($primary-color, 0.45);
      animation: pulsate 2.5s infinite;
      background: url("../../assets/icon/plusHotspot.svg") no-repeat;
      z-index: 0;
      background-position: center;
      width: 5rem;
      height: 5rem;
    }
  }
  .hotspot-circle {
    left: -2rem;
    width: 5rem;
    height: 5rem;
    top: -2rem;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
  }
  // .img-hotspot {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 3.7rem;
  //   height: 3.7rem;
  //   cursor: pointer !important;
  // }
  // .img-hotspot2 {
  //   width: 3.7rem;
  //   height: 3.7rem;
  //   cursor: pointer;
  // }
  .view {
    .img-hotspotdetail {
      max-width: 100%;
      max-height: 20rem;
      display: inline-block;
      opacity: 1;
      cursor: pointer;
      padding: 3rem 1.5rem !important;
    }
  }
  .detailContainer {
    box-shadow: 0px 1.2rem 3.4rem #00000018;
    opacity: 1;
    width: 15rem;
    height: 15rem;
    left: 2rem;
    top: 2rem;
  }
  .product-name {
    text-align: center;

    line-height: 3rem;
    letter-spacing: normal;
    color: $primary-color;
    opacity: 1;
    margin-bottom: 1rem;
  }
  .product-detail {
    text-align: center;

    letter-spacing: 0.2333rem;
    color: #000;
    opacity: 1;
    line-height: 2rem;
    .product-color {
      padding-right: 1rem;
    }
    .product-price {
      padding-left: 1rem;
    }
  }
  .hotspot-card {
    width: 25rem;
    max-width: 25rem;
    height: 100%;
    min-height: 30rem;
    position: absolute;
    text-align: center;
    opacity: 1;
    z-index: 10;
    border: 1px solid $default;
    .card-body {
      .card-title {
        margin-left: 0;
        color: $primary-color;
      }
      p.card-text.product-detail {
        padding-top: 0;
      }
    }
    &::before {
      content: "";
      border-top: solid 0.8rem $primary-color;
      border-left: solid 0.8rem transparent;
      border-right: solid 0.8rem transparent;
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-right: 14px solid $default;
      bottom: 50%;
      top: 50%;
      position: absolute;
    }
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-right: 14px solid $white;
      bottom: 50%;
      top: 50%;
      position: absolute;
    }
  }
  .cardContainer {
    box-shadow: 0 3px 2px 0px $default;
    transform: rotate(180deg) translateX(111%) translateY(-45%);
    bottom: 0;
    left: 0;
    &::before {
      transform: rotate(-0deg) translateY(-42%);
      left: -9%;
    }
    &:after {
      transform: rotate(-0deg) translateY(-42%);
      left: -5%;
    }
  }
  .cardContainerBottom {
    box-shadow: 0 3px 2px 0px $default;
    transform: rotate(180deg) translateX(111%) translateY(-45%);
    bottom: 0;
    &::before {
      transform: rotate(-0deg) translateY(-42%);
      left: -9%;
    }
    &:after {
      transform: rotate(-0deg) translateY(-42%);
      left: -5%;
    }
  }
  .cardContainerBottomRight {
    box-shadow: 0 3px 2px 0px $default;
    transform: rotate(180deg) translateX(-10%) translateY(-42%);
    bottom: -5rem;
    &::before {
      transform: rotate(-180deg) translateY(42%);
      right: -9%;
    }
    &:after {
      transform: rotate(-180deg) translateY(42%);
      right: -5%;
    }
  }

  .cardContainerRight {
    box-shadow: 0 3px 2px 0px $default;
    bottom: 0;
    right: 0;
    left: 0;
    transform: rotate(180deg) translateX(-15%) translateY(-45%);
    &::before {
      transform: rotate(-180deg) translateY(42%);
      right: -9%;
    }
    &:after {
      transform: rotate(-180deg) translateY(42%);
      right: -5%;
    }
  }

  .dots {
    content: "";
    display: none;
    height: 4.5rem;
    position: absolute;
    top: calc(102%);
    width: 0.2rem;
    left: 49%;
    border-left: 0.4rem dotted $secondary-color;
    &::after {
      content: "";
      width: 10.5rem;
      height: 100%;
      position: absolute;
      -ms-transform: translate(-102%, -21%);
      transform: translate(-50%, -1%);
    }
  }
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    transform: rotate(180deg);
    overflow: visible !important;
    background-attachment: fixed;
  }
}
@include media-breakpoint-down(xs) {
  .hotspotContentContainer {
    overflow: visible;
    .view {
      .img-hotspotdetail {
        max-width: 10rem;
        max-height: 10rem;
        display: inline-block;
        opacity: 1;
        cursor: pointer;
        padding: 1rem 0 !important;
      }
    }
    .hotspot-card {
      width: 13rem;
      max-width: 13rem;
      height: 100%;
      min-height: 15rem;
      .card-body {
        padding: 0;
        .card-title {
          margin: 0;
        }
        h5 {
          font-size: 1rem;
        }
      }
    }
    .cardContainer {
      box-shadow: 0 3px 2px 0px $default;
      transform: rotate(180deg) translateX(120%) translateY(-42%);
      bottom: 0;
      left: 0;
      &::before {
        transform: rotate(-0deg) translateY(-42%);
        left: -18%;
      }
      &:after {
        transform: rotate(-0deg) translateY(-42%);
        left: -10%;
      }
    }
    .cardContainerBottom {
      box-shadow: 0 3px 2px 0px $default;
      transform: rotate(180deg) translateX(120%) translateY(-42%);
      bottom: 0;
      &::before {
        transform: rotate(-0deg) translateY(-42%);
        left: -18%;
      }
      &:after {
        transform: rotate(-0deg) translateY(-42%);
        left: -10%;
      }
    }
    .cardContainerBottomRight {
      box-shadow: 0 3px 2px 0px $default;
      transform: rotate(180deg) translateX(-10%) translateY(-42%);
      bottom: -5rem;
      &::before {
        transform: rotate(-180deg) translateY(42%);
        right: -9%;
      }
      &:after {
        transform: rotate(-180deg) translateY(42%);
        right: -5%;
      }
    }

    .cardContainerRight {
      box-shadow: 0 3px 2px 0px $default;
      bottom: 0;
      right: 0;
      left: 0;
      transform: rotate(180deg) translateX(-30%) translateY(-42%);
      &::before {
        transform: rotate(-180deg) translateY(42%);
        right: -18%;
      }
      &:after {
        transform: rotate(-180deg) translateY(42%);
        right: -10%;
      }
    }
  }
}
