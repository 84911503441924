$small-step-width: 17rem;
$small-steps-width: $small-step-width * 3;

.steps-container {
  transition: 400ms;
  @media screen and (max-width: 991px) {
    .steps-inner-wrapper {
      > div {
        .row.step {
          > div {
            &.flex-md-row {
              flex-direction: column !important;
            }
            flex-wrap: wrap;
            h3 {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    padding: 0;
    .steps-inner-wrapper > div .row.step > div.flex-md-row {
      padding: 0 !important;
    }
  }
}
// @media screen and (max-width: $small-steps-width) {
//   .steps-container {
//     min-width: $small-step-width * 3;
//   }
//   .translate-0 {
//     transform: translateX($small-step-width);
//   }
//   .translate-2 {
//     transform: translateX(-$small-step-width);
//   }
// }
@include media-breakpoint-down(lg) {
  .steps-container {
    min-width: $small-steps-width;
  }
}
