@import "./filterOptions/filterOptions";

.multi-select-filter-item {
  padding: 0 1rem;
  min-width: 116px;
  position: relative;
  p {
    cursor: pointer;
    padding: 0.5rem 2.6rem;
    font-size: 14px;
    letter-spacing: 0;
    color: $gray-darken-3;
    border: 1px solid $gray;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    transition: all 0.25s ease-in-out;
    .toggle-arrow {
      position: absolute;
      right: 1rem;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $default;
      transition: all 0.25s ease-in-out;
    }
    input {
      border: none;
      max-width: 90%;
      line-height: 1rem;
      font-size: 14px;
    }
    input:visited,
    input:active,
    input:focus {
      outline: none;
    }
  }
  &.selected-filter {
    p {
      color: white;
      background: $default;
      .toggle-arrow {
        border-top: 5px solid white;
        transform: rotate(180deg);
      }
    }
    .searchable-filter {
      border: 1px solid $default;
      transition: all 0.25s ease-in-out;
      .toggle-arrow {
        transform: rotate(180deg);
        transition: all 0.25s ease-in-out;
      }
    }
  }
}
