.skeleton-container {
  .skeleton-picture {
    width: 100%;

    > * {
      > * {
        margin: 0 10px;
      }
    }
  }

  .skeleton-toolbar {
    width: 100%;
    height: 100px;
  }

  .skeleton-main-container {
    width: 100%;
    padding: 15px;
    margin: 0 auto;

    .skeleton-left {
    }

    .skeleton-right {
    }

    > div {
      > * {
        > * {
          margin: 10px 0;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .skeleton-container {
    .skeleton-main-container {
      width: 1140px;
      padding: 30px;

      .skeleton-left {
        float: left;
        width: 50%;
        padding-right: 100px;
      }

      .skeleton-right {
        float: right;
        width: 50%;
        padding-left: 100px;
        border-left: 1px $border-gray solid;
      }

      > div {
        > * {
          > * {
            margin: 10px 0;
          }
        }
      }
    }
  }
}