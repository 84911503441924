.account-sidebar {
  .account-newsletter-item,
  .quote-history {
    h4 {
      border-bottom: 0.1rem solid $gray-darken-3;
    }
  }
  .lists {
    h4 {
      border-top: 0.1rem solid $gray-darken-3;
    }
  }
}
