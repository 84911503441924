#downloadImageModal {
  .close.close-left {
    left: 1rem;
  }
  .close {
    position: absolute;
    top: calc(50% - 2rem);
  }

  .display-2 {
    font-weight: 100;
    font-size: 2.4rem;
  }
}
