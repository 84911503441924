.quote-request-history {
  p,
  a {
    font-size: 1.6rem;
    line-height: 1;
  }
  .react-date-picker__wrapper,
  .react-date-picker__calendar {
    z-index: 10;
  }
  .pagination {
    li,
    li > a {
      min-width: 4rem;
      min-height: 4rem;
    }
  }
  .order-page {
    .table-wrapper {
      max-width: 100%;
      overflow-x: auto;
      tr > td {
        vertical-align: middle;
      }
      .item-image {
        width: 15.4rem;
        height: 15.4rem;
      }
      .table-footer {
        background-color: $gray-lighten-4;
        padding: 2rem;
        > div {
          &:first-of-type {
            // border-bottom: 1px solid #ccc;
            // margin-bottom: 1.5rem;
            p {
              margin-bottom: 0;
            }
          }
          p {
            &:last-of-type {
              width: 12rem;
              text-align: end;
            }
          }
        }
        p {
          font-size: 2.4rem;
        }
      }
    }
    > div > div > div p:first-of-type {
      min-width: 15rem;
    }
    .selected-options {
      background-color: $gray-lighten-4;
      padding: 2rem;
      > div > p {
        color: $default;
      }
      .d-grid {
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;
        > div p {
          &:last-of-type {
            color: $default;
          }
          &:first-of-type {
            color: black;
            min-width: 18rem;
          }
        }
      }
    }
  }
  .filter-by {
    @media screen and (max-width: 768px) {
      form > div {
        flex-direction: column;
        > div:first-of-type {
          margin-bottom: 1.5rem;
        }
      }
    }
    p {
      color: #6c808b;
    }
    .md-form input[type="number"] {
      box-sizing: border-box;
      width: 100% !important;
      border: 2px solid #6c808b;
      border-color: #6c808b;
      border-radius: 5px;
      height: 4rem;
      padding: 1rem 2rem;
      color: #404040 !important;
      opacity: 0.5;
    }
    .md-form.form-lg label.active {
      top: -4rem;
    }
    form {
      margin-top: 5rem;
    }
  }
  .react-select__value-container {
    height: 4rem;
    position: initial;
    .react-select__single-value, .react-select__placeholder {
      line-height: 1.6rem;
    }
  }
  .counter-select {
    max-width: 9.6rem;
  }
  .view-and-pag-col {
    min-width: 15rem;
  }
  > .table-wrapper {
    max-width: 100%;
    overflow-x: auto;
  }
  .quote-history-grid {
    margin: 4rem 0;
    thead {
      border-top: 1px solid #6c808b;
    }
    tbody > tr > td,
    > thead > tr > th {
      font-size: 16px;
      padding: 17px 1rem;
    }
    tbody > tr > td,
    tbody > tr > td a {
      color: #888888;
    }
    tbody > tr > td a {
      font-size: 1.6rem;
    }
    > thead > tr > th {
      border-bottom: 0;
      color: #404040;
    }
    tr {
      .tablet-heading {
        font-size: 16px;
        color: #404040;
        display: inline-block;
        margin-right: 10px;
        min-width: 50px;
      }
      &:last-of-type {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}
