@import "./searchModal/searchModal";
@import "./searchResults/searchResults";

.search-container {
  .search-icon {
    filter: invert(100%) sepia(0%) saturate(4%) hue-rotate(196deg)
      brightness(106%) contrast(100%);
  }
  position: relative;
  .view-all-search-wrapper {
    position: sticky;
    bottom: 0;
  }
  cursor: pointer;
}

.search-modal-wrapper {
  .modal.show {
    .modal-dialog {
      width: 100%;
      max-width: 56rem;
    }
  }
  .modal-header {
    .modal-title {
      .modal-title-search {
        left: 0;
        bottom: 50%;
        transform: translate(0, 50%);
      }
    }
  }
  .search-modal {
    height: 100vh;
    .search-tabs {
      border-bottom: 5px solid #6c808b;
    }
    .modal-content {
      height: 100%;
      .tab.horisontal-tab {
        // border: 5px solid #6c808b;
        &.active {
          background-color: #6c808b;
          color: white;
          &::before {
            display: none;
          }
        }
        background-color: rgb(241, 241, 241);
        color: #404040;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 42px;
      }

      .view-all-search-wrapper {
        z-index: 10;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .search-container {
    .search-icon {
      margin-top: 0;
    }
  }
}
@include media-breakpoint-down(sm) {
  .search-container {
    cursor: pointer;
  }
  .search-modal-wrapper {
    .search-modal .modal-body.p-5.bg-white {
      height: 400px;
      overflow-y: scroll;
    }
    .modal.show {
      right: 0;
      left: unset;
      .modal-dialog {
        max-width: 85%;
        float: right;
        margin: 0;
        height: 100%;
        .modal-content {
          height: 100%;
          // .modal-body {
          //   overflow: hidden;
          // }
        }
      }
    }
    .search-build-container {
      img {
        height: 35rem;
      }
    }
  }
}
