// Default
body {
  font-family: $mainFont;
}

.wide-modal {
  width: 560px !important;
  max-width: 560px !important;
  @media screen and (max-width: 600px) {
    width: 95% !important;
    max-width: 95% !important;
    margin: 0 auto;
  }
}

.hide {
  opacity: 0;
  width: 0;
  height: 0;
}

.focus {
  box-shadow: 0 0 1px $default;
}

.w-full {
  width: 100% !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.pl-10 {
  padding-left: 1rem !important;
}
.pr-10 {
  padding-right: 1rem !important;
}

.pl-10-0 {
  padding-left: 1rem !important;
  @include media-breakpoint-down(sm) {
    padding-left: 0 !important;
  }
}

.pr-10-0 {
  padding-right: 1rem !important;
  @include media-breakpoint-down(sm) {
    padding-right: 0 !important;
  }
}

.pr-20 {
  padding-right: 2rem !important;
}

.mt-50 {
  margin-top: 5rem;
  @include media-breakpoint-down(md) {
    margin-top: 2.5rem;
  }
}

.mb-50 {
  margin-bottom: 5rem;
  @include media-breakpoint-down(md) {
    margin-bottom: 2.5rem;
  }
}

.mb-0-40 {
  @include media-breakpoint-down(md) {
    margin-bottom: 4rem;
  }
}

// Pagebuilder image classes

.pagebuilder-mobile-hidden {
  display: block;
  @media screen and (max-width: 720px) {
    display: none;
  }
}

.pagebuilder-mobile-only {
  display: none;
  @media screen and (max-width: 720px) {
    display: block;
    width: 100%;
  }
}

// Reset
%reset {
  margin: 0;
  padding: 0;
}
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
h1,
h2,
h3,
h4,
h5,
.body-1,
.body-2,
.body-3,
.body-4,
p {
  @extend %reset;
}

.ul-padding {
  padding-inline-start: 2rem;
}

.fade-out {
  transition: all 1.5s ease-in-out;
  opacity: 0 !important;
  pointer-events: none;
}

.product-widget,
.fabric-pattern-widget {
  &:hover {
    .hover-bg-gray-lighten-3 {
      transition: all 0.3s ease-in-out;
      background-color: rgba($gray-lighten-4, 1);
    }
  }
}

.fwthin {
  font-weight: $fwthin !important;
}
.fwlight {
  font-weight: $fwlight !important;
}
.fwregular {
  font-weight: $fwregular !important;
}
.fwmedium {
  font-weight: $fwmedium !important;
}

// Line heights
.lh-16 {
  line-height: $lh-16 !important;
}
.lh-24 {
  line-height: $lh-24 !important;
}
.lh-28 {
  line-height: $lh-28 !important;
}
.lh-30 {
  line-height: $lh-30 !important;
}
.lh-32 {
  line-height: $lh-32 !important;
}
.lh-36 {
  line-height: $lh-36 !important;
}
.lh-40 {
  line-height: $lh-40 !important;
}
.lh-46 {
  line-height: $lh-46 !important;
}

.lh-36-24 {
  line-height: 3.6rem !important;
  @include media-breakpoint-down(md) {
    line-height: 2.4rem !important;
  }
}

// Colors

.color-white {
  color: $white;
}
.color-black {
  color: $black;
}
.color-default {
  color: $default;
}
.color-secondary {
  color: $secondary;
}
.color-light-blue {
  color: $light-blue;
}
.color-primary {
  color: $primary;
}
.color-gray {
  color: $gray;
}
.color-dark-green {
  color: $dark-green;
}
.color-gray-darken-2 {
  color: $gray-darken-2;
}
.color-gray-lighten-4 {
  color: $gray-lighten-4;
}
.color-gray-darken-3 {
  color: $gray-darken-3;
}
.color-light-green {
  color: $light-green;
}
.color-red {
  color: $red;
}

// Headings
.display-1-homepage {
  font-weight: $fwthin;
  font-size: 9.2rem;
  line-height: 9.2rem;
  font-family: $fontUtopia;
  letter-spacing: 0;
  color: $white;
  @media screen and (max-width: 1440px) {
    font-size: 6.5rem;
    line-height: 6.5rem;
  }
  @include media-breakpoint-down(md) {
    font-size: 4rem;
    line-height: 4rem;
  }
}
.display-1 {
  font-weight: $fwthin;
  font-size: 3.8rem;
  line-height: 3.8rem;
  font-family: $fontUtopia;
  letter-spacing: 0;
  color: $white;
  @include media-breakpoint-up(xl) {
    font-size: 7rem;
    line-height: 7rem;
  }
}

.display-2 {
  font-weight: $fwthin;
  font-size: 2.2rem;
  font-family: $fontUtopia;
  font-weight: 100;
  letter-spacing: 0;
  color: $gray-darken-3;
  @include media-breakpoint-up(xl) {
    font-size: 4rem;
    line-height: 4rem;
  }
}
.display-3 {
  font-weight: $fwlight;
  font-size: 2.2rem;
  font-family: $fontUtopia;
  letter-spacing: 0;
  color: $gray-darken-3;
}
.display-4 {
  font-weight: $fwbold;
  font-size: 1.2rem;
  font-family: $mainFont;
  letter-spacing: 0;
  line-height: 2rem;
  color: $gray-darken-3;
}
.display-5 {
  font-weight: $fwmedium;
  font-size: 1.2rem;
  font-family: $mainFont;
  letter-spacing: 0;
  color: $gray-darken-3;
}
.gibson-font {
  font-family: $secondaryFontGibson !important;
}
.utopia-semi-bold {
  font-family: "utopia-std-display", sans-serif;
}
h1 {
  font-weight: $fwmedium;
  font-size: 2.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0px;
  color: $gray-darken-3;
}
h2 {
  font-weight: $fwregular;
  font-size: 2.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0px;
  color: $gray-darken-3;
}
h3 {
  font-weight: $fwlight;
  font-size: 1.4rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0px;
  color: $default;
  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
  }
}
h4 {
  font-weight: $fwmedium;
  font-size: 1.4rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0px;
  color: $gray-darken-3;
}

h5 {
  font-weight: $fwregular;
  font-size: 1.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $gray-darken-3;
}
.body-1 {
  font-weight: $fwregular;
  font-size: 1.5rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $default;
}
.body-2 {
  font-weight: $fwregular;
  font-size: 1.5rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $gray-darken-3;
}
.body-3 {
  font-weight: $fwlight;
  font-size: 1.4rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $white;
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

.body-4 {
  font-weight: $fwregular;
  font-size: 1.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $primary;
}

.body-5 {
  font-weight: $fwlight;
  font-size: 1.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $primary;
}

.body-6 {
  font-weight: $fwlight;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $gray-darken-2;
}

a {
  @extend %link-text;
  text-decoration: none !important;
}
.link-2 {
  color: $default;
  font-size: 1.5rem;
  text-decoration: underline !important;
}
.link-3 {
  @extend %link-text-3;
}

// Breadcrumbs

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  .breadcrumb-item {
    a {
      color: $primary;
      font-weight: $fwregular;
      font-size: 1.1rem;
      line-height: 2rem;
      text-transform: capitalize;
      letter-spacing: 0;
      font-family: $secondaryFontGibson;
    }
    & + .breadcrumb-item::before {
      content: "|";
      font-size: 1rem;
      color: $primary;
    }
  }
}

%button-white-text {
  font-weight: $fwlight;
  font-size: 1.3rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $white;
  text-transform: capitalize;

  svg {
    path {
      stroke: $white;
    }
  }
}

%button-dark-text {
  font-weight: $fwmedium;
  font-size: 1.3rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $default;
  text-transform: capitalize;
}

%button-body-1-text {
  @extend .body-1;
  color: $primary;
}

%button-type-2-text {
  font-weight: $fwmedium;
  font-size: 1.3rem;
  color: $default;
  text-transform: capitalize;
}

.button-type-2-text {
  @extend %button-type-2-text;
}

%field-text {
  font-weight: $fwregular;
  font-size: 1.3rem;
  font-family: $mainFont;
  line-height: 1.6rem;
  letter-spacing: 0;
  color: $primary;
}

%field-error-text {
  font-weight: $fwregular;
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-family: $mainFont;
  letter-spacing: 0;
  color: $red;
}

%link-text {
  font-weight: $fwregular;
  font-size: 2.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  line-height: 1.9rem;
  color: $dark-green;
  text-decoration: underline;
}

%link-text-3 {
  font-weight: $fwregular;
  font-size: 1.2rem;
  font-family: $secondaryFontGibson;
  letter-spacing: 0;
  color: $red;
  color: $default;
  text-decoration: underline !important;
}
.link-text {
  @extend %link-text;
}

.link-text-3 {
  @extend %link-text-3;
}

ul {
  &.ul-standard {
    padding-left: 1.5rem;
    list-style: none;
    li {
      position: relative;
      &:before {
        content: "";
        border: none;
        width: 0.5rem;
        height: 0.5rem;
        background-color: $default;
        position: absolute;
        top: calc(50% - 0.25rem);
        left: -1.2rem;
      }
      margin: 0.5rem 0;
    }
  }
}
// Modal title
.modal-heading {
  font-weight: 300;
}

// Helpers

.bold {
  font-weight: $fwbold;
}

.text-gray-darken-3 {
  color: $gray-darken-3;
}
.text-gray-lighten-4 {
  color: $gray-lighten-4;
}
.text-default {
  color: $default;
}
.text-secondary {
  color: $secondary;
}
.text-black {
  color: $black;
}
.text-light-blue {
  color: $light-blue;
}
.text-primary {
  color: $primary;
}
.text-gray {
  color: $gray;
}
.text-dark-green {
  color: $dark-green;
}
.text-gray-darken-2 {
  color: $gray-darken-2;
}
.text-light-green {
  color: $light-green;
}
.text-red {
  @extend %field-error-text;
}
.bg-gray-darken-3 {
  background-color: $gray-darken-3 !important;
}
.bg-gray-lighten-3 {
  background-color: #fbfbfb !important;
}
.bg-gray-lighten-4 {
  background-color: $gray-lighten-4 !important;
}
.bg-gray-lighten-4-50 {
  background-color: rgba($gray-lighten-4, 0.5) !important;
}
.bg-default {
  background-color: $default !important;
}
.bg-default-02 {
  background-color: rgba($default, 0.2);
}
.bg-secondary {
  background-color: $secondary !important;
}
.bg-black {
  background-color: $black !important;
}
.bg-light-blue {
  background-color: $light-blue !important;
}
.bg-primary {
  background-color: $primary !important;
}
.bg-gray {
  background-color: $gray !important;
}
.bg-dark-green {
  background-color: $dark-green !important;
}
.bg-gray-darken-2 {
  background-color: $gray-darken-2 !important;
}
.bg-light-green {
  background-color: $light-green !important;
}
.bg-red {
  background-color: $red !important;
}

.text-white {
  color: #fff !important;
  &:before {
    color: #fff !important;
  }
  &:after {
    color: #fff !important;
  }
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}
.cursor-grabbing {
  cursor: grabbing;
  user-select: none;
}

.text-unselectable {
  user-select: none;
}

.border-solid {
  border-style: solid;
}

.border-width-0 {
  border-width: 0 !important;
}
.border-width-1 {
  border-width: 1px !important;
}
.border-width-2 {
  border-width: 2px !important;
}
.border-width-3 {
  border-width: 0.3rem !important;
}

.border-default {
  border-color: $default !important;
}

.border-black {
  border-color: black !important;
}

.border-transparent {
  border-color: transparent !important;
}
.border-gray {
  border-color: $gray;
}
.border-gray-lighten-4 {
  border-color: $gray-lighten-4;
}

.border-transparent-1 {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.border-1 {
  border-width: 1px;
  border-style: solid;
  border-color: $default;
}
.border-2 {
  border-width: 2px;
  border-style: solid;
  border-color: $default;
}
.border-3 {
  border-width: 3px;
  border-style: solid;
  border-color: $default;
}

.border-default-1 {
  border: 1px solid $default;
}

.border-primary-1 {
  border: 1px solid $primary;
}

.border-gray-1 {
  border: 1px solid $gray;
}

.border-top-1 {
  border-top: 1px solid $gray;
}

.border-bottom-1 {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: $default;
}
.border-bottom-3 {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: $default;
}
.border-top-3 {
  border-top-width: 3px;
  border-top-style: solid;
  border-color: $default;
}
.border-bottom-5 {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  border-color: $default;
}

.border-bottom-rounded {
  border-radius: 0 0 5px 5px;
}
.border-top-rounded {
  border-radius: 5px 5px 0 0;
}
.border-radius-03 {
  border-radius: 0.3rem;
}
.border-radius-05 {
  border-radius: 0.5rem;
}
.border-radius-3_5 {
  border-radius: 3.5rem;
}
.border-radius-50 {
  border-radius: 50%;
}

.shadow-bottom {
  box-shadow: 0px 3px 6px rgba($black, $alpha: 0.5);
}

.py-100 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.pt-100 {
  padding-top: 10rem;
  @media screen and (max-width: 992px) {
    padding-top: 6rem;
  }
}

.pb-100 {
  padding-bottom: 10rem;
  @media screen and (max-width: 992px) {
    padding-bottom: 6rem;
  }
}

.px-100 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-60-0 {
  padding-left: 6rem;
  padding-right: 6rem;
  @include media-breakpoint-down(md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.p-03 {
  padding: 0.3rem;
}
.p-04 {
  padding: 0.4rem;
}
.p-08 {
  padding: 0.8rem;
}

@include media-breakpoint-down(md) {
  .px-20 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .py-100 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .mb-md-40 {
    margin-bottom: 4rem;
  }
  .mt-md-40 {
    margin-top: 4rem;
  }
}

.mr-20 {
  margin-right: 2rem;
}

.mb-md-40 {
  margin-bottom: 4rem;
}
.mt-md-40 {
  margin-top: 4rem;
}

// Font Helpers
.fs-12 {
  font-size: $fs-12 !important;
}
.fs-13 {
  font-size: $fs-13 !important;
}
.fs-14 {
  font-size: $fs-14 !important;
}
.fs-16 {
  font-size: $fs-16 !important;
}

.fs-18 {
  font-size: $fs-18 !important;
}
.fs-20 {
  font-size: $fs-20 !important;
}
.fs-24 {
  font-size: $fs-24 !important;
}
.fs-40 {
  font-size: $fs-40 !important;
}
$fs-72: 7.2rem;
.fs-72 {
  font-size: $fs-72 !important;
}

.underline {
  text-decoration: underline !important;
}

.ml-n7 {
  margin-left: -7px;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-10 {
  margin-top: 1rem;
}

.mt-12 {
  margin-top: 1.2rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.mb-0-20 {
  margin-bottom: 0;
  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }
}

.mb-12 {
  margin-bottom: 1.2rem;
}
.mb-14 {
  margin-bottom: 1.4rem;
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mb-18 {
  margin-bottom: 1.8rem;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-30 {
  margin-bottom: 3rem;
}
.mb-35 {
  margin-bottom: 3.5rem;
}
.mb-40 {
  margin-bottom: 4rem;
}
.mb-60 {
  margin-bottom: 6rem;
}

.mb-70 {
  margin-bottom: 7rem;
}
.mb-100 {
  margin-bottom: 10rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 6rem;
  }
}

.mt-100 {
  margin-top: 10rem;
  @media screen and (max-width: 992px) {
    margin-top: 6rem;
  }
}

.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-3 {
  gap: 3rem;
}
.gap-4 {
  gap: 4rem;
}
.gap-5 {
  gap: 5rem;
}

.height-0 {
  height: 0;
}
$size-5: 0.5rem;
.height-5 {
  height: $size-5;
}
$size-10: 1rem;
.height-10 {
  height: $size-10;
}
$size-20: 20rem;
.height-20 {
  height: $size-20;
}
.height-40 {
  height: 4rem;
}
.size-10 {
  min-width: $size-10;
  width: $size-10;
  height: $size-10;
}
$size-12: 1.2rem;
.size-12 {
  min-width: $size-12;
  width: $size-12;
  height: $size-12;
}
$size-14: 1.4rem;
.size-14 {
  min-width: $size-14;
  width: $size-14;
  height: $size-14;
}
$size-18: 1.8rem;
.size-18 {
  min-width: $size-18;
  width: $size-18;
  height: $size-18;
}
$size-20: 2rem;
.size-20 {
  min-width: $size-20;
  width: $size-20;
  height: $size-20;
}

.min-width-iv {
  min-width: 4rem;
}
.min-width-xv {
  min-width: 15rem;
}

.content-box {
  box-sizing: content-box;
}

.hidden {
  visibility: hidden;
}

.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-auto {
  overflow-y: auto;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.list-group {
  list-style-type: none;
  .list-group-item {
    display: list-item;
    border: none;
    padding: 0.5rem 0;
    background: none;
    font-size: 1.3rem;
    &::before {
      content: "■";
      padding-right: 1.5rem;
      color: $default;
    }
  }
}

.grow {
  flex-grow: 1;
}
.md-form {
  textarea.md-textarea {
    padding: 1rem 2rem;
    box-sizing: border-box;
    border-color: $default !important;
    border-radius: 5px;
    border: 2px solid $default;
    opacity: 0.5;
    font-size: 1.4rem;
    color: $primary;
    &:focus {
      border: 2px solid $default;
      opacity: 1;
    }
    resize: none;
  }
}

.top-0 {
  top: 0;
}
.top-50 {
  top: 50%;
}
.left-0 {
  left: 0;
}
.left-50 {
  left: 50%;
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}

.z-index-1 {
  z-index: 1;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-6 {
  z-index: 6;
}

.shadow-1 {
  box-shadow: 0px 0px 5px #00000062;
}
.shadow-2 {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.scale-066 {
  transform: scale(0.66);
}

.opacity-0 {
  opacity: 0;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-1 {
  opacity: 1;
}

.invisible {
  opacity: 0;
  visibility: hidden;
  height: 0 !important;
}

.transition-all-100 {
  transition: all 100ms ease-in-out;
}
.transition-all {
  transition: all 500ms ease-in-out;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4em;
  .close {
    position: relative;
  }
}
.text-end {
  text-align: end;
}
.justify-content-end {
  justify-content: flex-end;
}
