@import "./downloads/downloads";
@import "./alsoAvailable/alsoAvailable";
.generic-instock {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 42px;
  background: #ebf6ec;
  color: #00a000;
  leading-trim: both;
  text-edge: cap;
  font-family: $secondaryFontGibson;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 11px */
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 12px;
  width: fit-content;
  &.out-of-stock {
    background-color: rgb(248, 204, 204);
    color: rgb(148, 17, 17);
  }
}
.rightSideGenericPDP {
  button.view-build-summary-btn {
    color: #6c808b;
  }
  .confirm-configuration-btn,
  .view-build-summary-btn {
    img {
      margin-right: 1.2rem;
    }
  }
  .summary-btn-generic {
    margin: 2.4rem 0;
  }
  .also-available-in-leather {
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    padding: 8px 10px;
    display: flex;
    gap: 8px;
    align-items: center;
    width: fit-content;
    margin-top: 1.6rem;
    &:focus {
      background-color: white;
    }
    .also-available-in-leather-text {
      color: #6c808b;
      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 17.6px */
      padding-top: 0.5rem;
    }
  }
  .summary-modal {
    .modal-header {
      background-color: white !important;
      color: black;
    }
    max-width: 128rem;
    max-height: 90dvh;
    // overflow: auto;
    .summary-exit-button {
      margin-left: auto;
      margin-top: 4rem;
      color: #fff;

      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .modal-content,
    .modal-header,
    .modal-body,
    .summary-wrapper {
      border-radius: 20px;
      border: 1px solid white;
    }
    .modal-header {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .summary-wrapper {
      padding: 0;
    }
    .modal-body {
      padding: 4rem !important;
    }
    .modal-header {
      border-bottom: 1px solid #e5e5e5;
    }
    .modal-content {
      border-radius: 20px;
      border: 1px solid white;
      .modal-body {
        overflow: auto;
        max-height: calc(90dvh - 10rem);
      }
      .summary-header {
        justify-content: flex-start;
        padding: 2.8rem 4rem;
        height: unset;
        h1 {
          color: #404040;
          font-family: $secondaryFontGibson;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
        }
        .close-icon {
          left: unset;
          top: 3rem;
          right: 4rem;
        }
      }
    }
  }
  .generic-configure-actions {
    border-top: 1px solid #d9d9d9;
    margin-top: 2.4rem;
  }
  .product-name {
    color: #404040;
    font-family: $secondaryFontGibson;
    font-size: 40px;
    font-style: normal;
    font-weight: 200;
    line-height: 110%;
  }
  .generic-button-font {
    color: #fff;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
  }
  .configure-button {
    border-radius: 5px;
    background: #6c808b;
    box-shadow: none;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    width: 100%;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
  }

  .detail-items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .generic-description {
    color: #797979;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */

    &.generic-description-value {
      font-weight: 500;
    }
  }

  .generic-title-desc {
    padding-bottom: 2.4rem;
    color: #797979;
    font-family: $secondaryFontGibson;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 22px */
  }

  .price-wrap {
    display: flex;
    margin-top: 24px;
  }
  .rightSide-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    margin-top: 2.4rem;
    .actions-button {
      border-radius: 5px;
      background: #6c808b;
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex: 1 0 0;
      color: #fff;
      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
      width: 100%;
      margin: 0;
      box-shadow: unset;
      &.favorite-button {
        background-color: #fff !important;
        color: #6c808b;
        border-radius: 5px;
        border: 1px solid #6c808b;
      }
    }
  }
}
