.homepage-banner {
  padding: 2rem 1.5rem;
  .homepage-hero-banner {
    > .row > div:last-of-type {
      padding: 0;
    }
    .slick-arrow {
      top: 50%;
      width: 5rem;
      height: 5rem;
      z-index: 2;
      &::before {
        background-size: 5rem;
        width: 5rem;
        height: 5rem;
      }
      &.slick-prev {
        left: 4rem;
      }
      &.slick-next {
        right: 0%;
      }
    }
  }
}
