.fabric-finish-container {
  > .container-fluid {
    padding: 0 !important;

    .fabric-toolbar {
      padding: 0 !important;
    }
  }

  .related-container {
    margin: 50px auto !important;
  }
}
