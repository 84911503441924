.also-available {
  margin-top: 2.4rem;
  .also-available-title {
    color: #404040;
    font-family: $secondaryFontGibson;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 20px */
    margin-bottom: 10px;
  }
  .also-available-sku-img {
    width: 120px;
    height: 120px;
    object-fit: contain;
  }

  .also-available-skus {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }

  .also-available-sku {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h4,
    p {
      color: #797979;
    }
    p {
      color: #797979;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }
  }
}
