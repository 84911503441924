.plp-counter-pagination-wrapper {
  .counter-select {
    max-width: 9.6rem;
  }
}

@include media-breakpoint-down(lg) {
  .plp-counter-pagination-wrapper {
    .view-and-pag-col {
      &.view {
        width: 30%;
      }
      &.pagination-wrapper {
        width: 70%;
      }
    }
    .select2 {
      .react-select-container {
        .react-select__control {
          .react-select__value-container {
            padding: 0 1rem !important;
          }
        }
      }
    }
  }
}
