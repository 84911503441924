.account-container {
  .text-red {
    bottom: -1.8rem;
  }
}
.account-form-wrapper {
  .alert {
    display: flex;
    .close {
      position: static;
    }
  }
}

@include media-breakpoint-down(lg) {
  .account-form-wrapper {
    .md-form.form-lg {
      width: 100% !important;
    }
    .masked-input.lg {
      width: 100% !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .address-zip-field > div {
    width: 32.8rem;
  }
}
