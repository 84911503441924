.checkbox-sidebar-container {
  .checkbox-container {
    padding: 10px 25px;

    .checkbox-option {
      padding: 10px 15px;
      border-bottom: 1px solid $border-gray;

      h5 {
        font-weight: 400;
        font-size: 1.3rem;
      }
    }
  }

  .sidebar-footer {
    .apply-button {
      margin-top: 20px;
    }
  }
}