$switch-width: 3.4rem;

.switch {
  margin-bottom: 0;
  .slider-body {
    border-radius: $switch-width;
    min-width: $switch-width;
    padding: 0.3rem;
    .slider-indicator {
      transition: 400ms;
      transform: translateX(0rem);
      opacity: 0.75;
    }
  }

  input:checked + .slider-body {
    .slider-indicator {
      transform: translateX(2rem);
      opacity: 1;
    }
  }

  input:focus + .slider-body {
    @extend .focus;
  }
}
