@import "./desktop/headerBottom/headerBottom";
@import "./desktop/headerBottom/menuItem/menuItem";
@import "./desktop/headerBottom/dropdownItems/dropdownItems";
.notification > div > div {
  top: -4.5px !important;
  right: -4.5px !important;
}
.news-alert {
  background-color: #800000 !important;
}
.header-bottom-container {
  .logo-wrapper {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
}
.header-mobile-container {
  .mobile-outside-wrapper {
    &.active {
      height: 3rem;
    }
  }
}
.header-top {
  .top-toggle-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 2rem;
    width: 35%;
  }
  .top-toggle-menu-active {
    background-color: #6b808c;
    .display-5 {
      color: white !important;
    }
  }
  > a {
    // padding-top: 2.5rem;
    // padding-bottom: 2.5rem;
  }
  .logo {
    height: 9rem;
  }
}

.header-container {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
}
