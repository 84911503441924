.virtual-showroom-wrapper {
  margin: 0;
  .iFrame-wrapper {
    overflow: hidden;
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
@include media-breakpoint-down(lg) {
  .virtual-showroom-wrapper {
    .iFrame-wrapper {
      overflow: hidden;
      margin: 0 auto;
      height: 30rem;
      width: 100%;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
}
