html {
  font-size: 10px;
}

body {
  .modal.show {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.color-black {
  color: black !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // overflow-x: hidden;

  > .main {
    flex: 1 1 auto;
  }

  #footer {
    .nav-link {
      padding: 0;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
  }
}

@import "./style/customMdbVariables";
@import "~core/style/core";
@import "./style/app";
@import "./layout/layouts";
@import "./pages/pages";
@import "./style/skeleton";
