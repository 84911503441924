@import "./downloadTearsheet/downloadTearsheet";

.label-4 {
  top: -4rem !important;
}
.fabric-toolbar {
  position: sticky;
  bottom: 0;
  z-index: 1;
  width: 100%;
  .tooltip-container {
    display: flex !important;
  }
  .toolbar-wrapper {
    width: calc(100% + 15px);
    // width: 100%;
  }
}
.toolbar-wrapper {
  border-bottom: 3px solid $default !important;
  .breadcrumbs {
    a {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .price-and-download-wrapper {
    justify-content: space-between;
    .download-btn {
      color: $default;
      font-weight: 400;
      img {
        margin-right: 1rem;
      }
    }
    .price-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      .price-container {
        justify-content: flex-start;
      }
    }
  }
}
.tooltip-container {
  width: auto;
  text-align: left;
  padding: 0;
  display: inline-block !important;
  .tooltip {
    &.tooltip-pdp {
      &[x-placement^="bottom"],
      &[x-placement^="top"] {
        min-width: 12.5rem;
        border-radius: 0;
        background-color: $white;
        border: 3px solid $default;
        left: 4.8rem !important;
        top: 2.7rem !important;
        transform: translate3d(-66px, -70px, 0px) !important;

        .popover_arrow {
          bottom: -12px;
          left: 60% !important;
          border-width: 12px 12px 0 12px;
          border-color: $default transparent transparent transparent;
          &:before {
            left: -12px;
            border-width: 12px 12px 0 12px;
            border-color: $white transparent transparent transparent;
          }
        }
      }
      &[x-placement^="top"] {
        border: 1px solid $default;
      }
    }
  }
  button.tooltip-btn {
    padding: 1rem !important;
    width: auto !important;
    background-color: transparent !important;
    &:hover,
    &:focus {
      box-shadow: unset !important;
      background-color: transparent !important;
    }
    &:active {
      &:focus {
        box-shadow: unset !important;
        background-color: transparent !important;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .tooltip-container {
    .tooltip {
      &.tooltip-pdp {
        &[x-placement^="bottom"],
        &[x-placement^="top"] {
          width: auto;
          border-radius: 0;
          background-color: $white;
          border: 1px solid $default;
          left: unset !important;
          top: 0px !important;
          transform: translate3d(-64px, -70px, 0px) !important;

          .popover_arrow {
            bottom: -12px;
            left: 60% !important;
            border-width: 12px 12px 0 12px;
            border-color: $default transparent transparent transparent;
            &:before {
              left: -12px;
              border-width: 12px 12px 0 12px;
              border-color: $white transparent transparent transparent;
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .tooltip-container {
    .btn.tooltip-btn {
      width: auto;
      height: 100%;
      padding: 1rem 3rem;
    }
  }
}
@include media-breakpoint-up(xl) {
  .tooltip-container {
    .btn.tooltip-btn {
      width: 21rem;
    }
  }
}
