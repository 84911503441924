@import "./components/Express/express";
.login-modal {
  width: 450px;
  max-width: 90%;
  @import "./components/WelcomeBack/welcomeBack";
}
@include media-breakpoint-down(xs) {
  .account-container {
    h3 {
      font-size: 2.4rem;
    }
    .md-form {
      width: 100% !important;
      label {
        font-size: 1.6rem !important;
      }
    }
  }
}

@media screen and (max-height: 500px) {
  .login-modal {
    height: auto !important;
  }
}
@media screen and (max-width: 480px) {
  .login-modal {
    width: 100% !important;
  }
}
