.media-gallery {
  .modal {
    overflow: visible;
    @media screen and (max-width: 768px) {
      .slick-arrow {
        display: none !important;
      }
    }
  }
}
.pdp-slider-modal {
  width: 80rem;
  min-height: calc(100% - 14rem) !important;
  max-width: 80rem;
  .images-wrapper {
    background-color: transparent !important;
    padding: 0 !important;
    bottom: 4rem !important;
  }
  .arrow-hidden {
    .slick-arrow,
    .slick-list {
      display: none !important;
    }
  }
  .slide-description {
    @media screen and (max-width: 991px) {
      max-width: 80%;
      word-break: break-word;
    }
  }
  .vertical-slider-wrapper {
    .images-wrapper {
      display: flex;
      position: absolute;
      flex-wrap: wrap;
      bottom: 1rem;
      z-index: 10;
      flex-wrap: wrap;
      max-height: 10rem;
      overflow-y: auto;
      @media screen and (max-width: 991px) {
        position: relative;
        justify-content: flex-start;
      }
      img {
        width: 9.6rem;
        height: auto;
        margin: 0 0.8rem 0;
        border: 1px solid #707070;
        cursor: pointer;
      }
      // @media screen and (max-width: 991px) {

      // }
    }
  }
  .vertical-slider {
    display: flex;
    img {
      max-height: 10rem !important;
      width: 100% !important;
      object-fit: contain;
      height: 100%;
      margin: 0 0.8rem;
    }
    .slick-slide {
      .vertical-slide-item {
        border: 2px solid transparent;
        transition: all 0.25s ease-in-out;
        &.active {
          transition: all 0.25s ease-in-out;
          border: 2px solid #404040;
        }
      }
      div:focus,
      div:active,
      div:visited {
        outline: none;
      }
    }
  }
}
.main-slider {
  position: relative;
  .slick-arrow {
    &.slick-prev {
      top: 48%;
      left: -26%;
    }
    &.slick-next {
      top: 48%;
      right: -30%;
    }
  }
  .slick-list {
    .slick-track {
      .slick-slide {
        // height: 80rem;
        max-height: 80rem;
        > div {
          height: 100%;
          img {
            height: calc(100vh - 20rem);
            object-fit: contain;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .pdp-slider-modal {
    width: 54rem;
    max-width: 54rem;
  }
  .main-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          height: 54rem;
          max-height: 54rem;
          > div {
            height: 100%;
            img {
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .pdp-slider-modal {
    width: 50rem;
    max-width: 50rem;
  }
  .main-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          height: 50rem;
          max-height: 50rem;
          > div {
            height: 100%;
            img {
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 470px) {
  .pdp-slider-modal {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    .modal-header {
      height: 4rem;
      border: none;
      .button {
        visibility: hidden;
        position: absolute;
        right: 0;

        bottom: 0;
        text-shadow: none;
      }
      .close {
        position: absolute;
        right: 0;
        font-size: 4rem;
        text-shadow: none;
        margin: 0;
        padding: 0;
        top: 0rem;
        opacity: 1;
        color: $default;
        height: 4rem;
        width: 4rem;
      }
      .modal-title {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .main-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          height: 35rem;
          max-height: 35rem;
          > div {
            height: 100% !important;
            img {
              height: 100% !important;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
