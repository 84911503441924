@import "./components/selector/selector";
@import "./components/dropdown/dropdown";
@import "./components/checkboxSidebar/checkboxSidebar";
@import "./components/modalOptionSelection/modalOptionSelection";

.options-container {
  .error-finish-pdp {
    // margin-left: 21rem;
    color: rgb(206, 63, 63);
    font-size: 1.4rem;
    margin-top: -10px;
  }
  > div {
    margin-bottom: 2rem;
  }
  .selector-container {
    padding-left: 2.5rem;
  }
  > .selector-container {
    padding-left: 1rem;
  }
  .select-options-holder {
    transition: all 0.5s ease-in-out;
    .link {
      font-size: 1.6rem;
      color: $dark-green;
      text-decoration: underline;
      padding-left: 2.5rem;
    }
    &.slide-in {
      animation: slideIn 0.5s ease-in-out;
    }
    &.slide-out {
      animation: slideOut 0.5s ease-out;
    }
  }
  .options-selector-container {
    overflow: visible;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  &.tabs-view {
    display: flex;
    gap: 0.8rem;
    padding: 2.4rem 4rem 2.4rem 9rem;
    .options-selector-tab {
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      border: 1px solid #E4E4E4;
      &.active {
        border: 1px solid #6c808b;
      }
      .option-selector-tab-title {
        color: #6c808b;
        font-family: $secondaryFontGibson;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 17.6px */
        margin: auto;
        padding: 0.8rem 1.2rem;
      }
    }
  }
  

  // .option-title-container {
  //   .option-title {
  //     // padding: 10px 15px;
  //     font-weight: 300;
  //     color: $default;
  //     font-size: 2.4rem;
  //     margin: 0;
  //     float: left;
  //     max-width: calc(100% - 30px);
  //     line-height: 30px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     line-break: anywhere;
  //   }

  //   img {
  //     float: right;
  //     padding-right: 10px;

  //     &.plus {
  //       margin-top: calc((50px - 18px) / 2);
  //     }

  //     &.minus {
  //       margin-top: calc((50px - 2px) / 2);
  //     }
  //   }
  // }
  .view-more-title {
    // height: 38px;
    background: $gray-lighten-4;
    cursor: pointer;
    padding: 10px 10px 10px 25px;
    border-radius: 2px;
    width: calc(100% - 15px);
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    h1 {
      color: $default;
      font-weight: 300;
    }
    img {
      float: right;

      &.plus {
        margin-top: calc((18px - 18px) / 2);
      }

      &.minus {
        margin-top: calc((18px - 2px) / 2);
      }
    }
  }

  .view-more-options-container {
    height: 40px;
    background: $secondary;
    padding: 0 15px;
    border-radius: 2px;
    margin-left: 15px;
    cursor: pointer;
    margin-right: -5px;

    .option-title-container {
      .option-title {
        text-decoration: underline;
        font-size: 1.6rem;
        font-weight: 300;
        color: #fff;
        padding: 10px 0;
      }

      img {
        width: 20px;
        height: 20px;
        margin: 10px 0;
        padding-right: 0;
      }
    }
  }

  .help {
    padding: 15px;

    p {
      font-size: 1.6rem;
      line-height: 1.5;
    }

    a {
      font-size: 1.6rem;
      text-decoration: underline !important;
    }
  }
}
