.mobile-cart-items {
  .item-controls {
    margin-top: 2rem;
  }
}

.mobile-item-uploads {
  .md-form label.active {
    color: #6c808b !important;
  }
}
