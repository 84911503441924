.gallery-detail-container {
  max-width: 108.2rem;
  .card-image {
    &.our-installations {
      .expandable-text {
        order: 0;
        &.expandable-text-true {
          height: 16rem;
          &.bigger-height {
            overflow-y: scroll;
          }
        }
      }
    }
  }
  .gallery-detail-img {
    .imageloader {
      width: 100%;
      height: 100%;
      span {
        width: 100%;
        height: 100%;
        .react-loading-skeleton {
          height: 80rem;
          @include media-breakpoint-down(md) {
            height: 50rem;
          }
          @include media-breakpoint-down(xs) {
            height: 30rem;
          }
        }
      }
    }
  }
  .back-link {
    font-size: 1.3rem;
  }
  .bottom-gallery-images {
    padding-left: 5px;
    padding-right: 5px;
  }
  .download-image-small-button {
    background-color: transparent !important;
    width: auto;
    height: auto;
  }
  .download-image-small-button:hover {
    box-shadow: none !important;
  }
}

@media screen and (max-width: 768px) {
  .gallery-detail-container {
    .bottom-gallery-images {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

@media screen and (max-height: 500px) {
  .container.gallery-detail-container {
    max-height: 300px;
    overflow-y: scroll;
  }
}
