@keyframes transformAndRemove {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
    visibility: hidden;
    position: absolute;
  }
}
@keyframes transformAndAdd {
  0% {
    transform: translateY(100%);
  }
  1% {
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}
// Color Variables
$gray-lighten-4: #f7f7f7;

// Font Variables
$fs-12: 1.2rem;
$fs-13: 1.3rem;
$fs-14: 1.4rem;
$fs-16: 1.6rem;
$fs-24: 2.4rem;
$fs-40: 4rem;
$fs-72: 7.2rem;

// Color helpers
.bg-gray-lighten-4 {
  background-color: $gray-lighten-4 !important;
}
// Font Helpers
.fs-12 {
  font-size: $fs-12 !important;
}
.fs-13 {
  font-size: $fs-13 !important;
}
.fs-14 {
  font-size: $fs-14 !important;
}
.fs-16 {
  font-size: $fs-16 !important;
}
.fs-24 {
  font-size: $fs-24 !important;
}
.fs-40 {
  font-size: $fs-40 !important;
}

.fs-72 {
  font-size: $fs-72 !important;
}

.download-bar {
  z-index: $zindex-sticky;
  position: sticky;
  bottom: 0;
}
.body-2 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.6rem;
}

#image-download-bar {
  transition: all 0.2s ease-in-out;
  // display: none;
  // transform: translateY(100%);
  animation: transformAndRemove 200ms;
  animation-fill-mode: forwards;
  &.has-items {
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: transformAndAdd;
    animation-duration: 200ms;
  }
  &.no-items {
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: transformAndRemove;
    animation-duration: 200ms;
  }
  @import "./modal/modal";
}
