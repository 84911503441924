.logo-data-wrapper {
  cursor: pointer;
  &:hover {
    .logo-info-box {
      background-color: #f7f7f7;
      transition: 0.5s;
    }
  }
  .img-holder {
    margin-bottom: 15px;
    max-height: 202px;
    height: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 202px;
    }
  }
  .logo-info-box {
    padding: 11px 20px;
    border-bottom: 3px solid #6c808b;
    button {
      border-color: transparent;
      background-color: transparent !important;
      box-shadow: none;
      color: #6c8b89;
      padding: 2px;
      span {
        text-decoration: underline;
      }
    }
    .btn-default:not([disabled]):not(.disabled):active,
    .btn-default:not([disabled]):not(.disabled).active,
    .show > .btn-default.dropdown-toggle {
      background-color: transparent !important;
    }
    a {
      font-size: 16px;
      text-decoration: underline !important;
      color: #6c808b;
      &:hover {
        color: #6c808b;
      }
    }
  }
}
