.finish-detail-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .finish-detail-image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    .finish-detail-image {
      height: 33rem;
      width: 33rem;
      max-width: 33rem;
      max-height: 33rem;
      img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .finish-detail-info {
    max-width: 80%;
    margin-top: 3rem;
    h3 {
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
