.fabric-detail-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .fabric-detail-image-row {
    display: flex;
    justify-content: center;
    align-items: center;
    .fabric-detail-image {
      height: 33rem;
      width: 33rem;
      max-width: 33rem;
      max-height: 33rem;
      @include media-breakpoint-down(xs) {
        height: 25rem;
        width: 25rem;
        max-width: 25rem;
        max-height: 25rem;
      }
      img {
        border-radius: 5px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .fabric-detail-info {
    max-width: 80%;
    margin-top: 3rem;
    h3 {
      text-align: left;
      margin-bottom: 1rem;
    }
  }
}
