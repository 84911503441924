@import "./downloadTearsheet/downloadTearsheet";
@keyframes loadingIcon {
  from {
    opacity: .3;
  }
  to {
    opacity: 1;
  }
}

.label-4 {
  top: -4rem !important;
}

.toolbar-wrapper {
  border-bottom: 3px solid $default !important;
  .breadcrumbs {
    a {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
.tooltip-container {
  width: auto;
  text-align: left;
  padding: 0;
  display: inline-block !important;
  flex: 0 0 auto;
  .tooltip {
    &.tooltip-pdp {
      &[x-placement^="bottom"],
      &[x-placement^="top"] {
        min-width: 12.5rem;
        border-radius: 0;
        background-color: $white;
        border: 3px solid $default;
        left: 0 !important;
        top: 0px !important;
        transform: translate3d(-66px, -70px, 0px) !important;

        .popover_arrow {
          bottom: -12px;
          left: 60% !important;
          border-width: 12px 12px 0 12px;
          border-color: $default transparent transparent transparent;
          &:before {
            left: -12px;
            border-width: 12px 12px 0 12px;
            border-color: $white transparent transparent transparent;
          }
        }
      }
      &[x-placement^="top"] {
        border: 1px solid $default;
      }
    }

    .loadingIcon {
      animation: loadingIcon 2s ease-in-out infinite;
    }
  }
  button.tooltip-btn {
    padding: 1rem !important;
    width: auto !important;
    background-color: transparent !important;
    &:hover,
    &:focus {
      box-shadow: unset !important;
      background-color: transparent !important;
    }
    &:active {
      &:focus {
        box-shadow: unset !important;
        background-color: transparent !important;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .tooltip-container {
    .tooltip {
      &.tooltip-pdp {
        &[x-placement^="bottom"],
        &[x-placement^="top"] {
          width: auto;
          border-radius: 0;
          background-color: $white;
          border: 1px solid $default;
          left: unset !important;
          top: 0px !important;
          transform: translate3d(-64px, -70px, 0px) !important;

          .popover_arrow {
            bottom: -12px;
            left: 60% !important;
            border-width: 12px 12px 0 12px;
            border-color: $default transparent transparent transparent;
            &:before {
              left: -12px;
              border-width: 12px 12px 0 12px;
              border-color: $white transparent transparent transparent;
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .tooltip-container {
    .btn.tooltip-btn {
      width: auto;
      height: 100%;
      padding: 1rem 3rem;
    }
  }
  .starting-wrapper {
    white-space: nowrap;
  }
}
@include media-breakpoint-up(xl) {
  .tooltip-container {
    .btn.tooltip-btn {
      width: 21rem;
    }
  }
}
@include media-breakpoint-down(xs) {
  .quote-req-btn {
    width: 100%;
  }
}
@include media-breakpoint-between(md, lg) {
  .price-pdp {
    font-size: 1.8rem;
  }
}
