// @import "./breadcrumbs/breadcrumbs";
// @import "./Pagination/Pagination";
// @import "./footer/footer";
// @import "./galleryBlock/galleryBlock";
// @import "./promoBanner/promoBanner";
// @import "./header/header";
// @import "./heroImage/heroImage";
@import "./hotspot/hotspot";
// @import "./modal/modal";
// @import "./sectionHeader/sectionHeader";
// @import "./staticPagesTop/staticPageTop";
// @import "./tabs/tabs";
// @import "./titleDescription/titleDescription";
// @import "./compoundSlider/compoundSlider";
// @import "./selector/selector";
// @import "./promoVideo/promoVideo.scss";
@import "./productWidget/ProductWidget";
// @import "./wishlist/wishlist";
// @import "./fontIcons/icoMoon/style";
@import "./mediaGallery/mediaGallery";
@import "./header/mobile/mobile";
@import "./categoryWidget/categoryWidget";
@import "./registration/Step/Step";
@import "./registration/Steps/Steps";
@import "./Switch/Switch";
@import "./Checkbox/Checkbox";
@import "./RadioButton/RadioButton";
@import "./Label/Label";
@import "./footer/footer";
@import "./header/right/right";
@import "./header/header";
@import "./Collapse/collapse";
@import "./fabricPatternWidget/fabricPatternWidget";
@import "./tooltip/tooltip";
@import "./newVersion/newVersion";
@import "./Player/player";
@import "./Pagination/PerPage/perPage";
@import "./Accordion/accordion";

@media screen and (max-width: 991px) {
  .cms-register-thank-you.quote-thank-you
    .row.p-5.w-100.h-100.d-flex.align-items-center.justify-items-center {
    margin: 0;
  }
}
