.dropdown-item {
  padding: 0;
  margin-top: -1px;
  .dropdown-trigger,
  .dropdown-container,
  .dropdown-trigger,
  .dropdown-container > * {
    padding: 0.5rem 3rem;
  }
  .dropdown-trigger {
    border-radius: 5px;
    margin: 15px 0;
    background-color: #f6f7f7;
    cursor: pointer;
    h5 {
      // font-weight: bold;
      @include fontSize(2.1rem, 4.2rem, 0);
      @include media(1200px) {
        @include fontSize(1.8rem, 3.2rem, 0);
      }
      @include media(1050px) {
        @include fontSize(1.6rem, 2.8rem, 0);
        white-space: break-spaces;
      }
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
      transition: transform 0.25s ease-out;
      margin-right: 2rem;
    }
    .open {
      transform: rotate(180deg);
    }
  }
  .dropdown-container {
    opacity: 1;
    display: initial;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;
    white-space: normal;
    color: #8e92a1;
    @include fontSize(1.6rem, 2.5rem, 0);
    @include media(1200px) {
      @include fontSize(1.4rem, 2.2rem, 0);
    }
    p,
    div {
      white-space: normal;
      @include fontSize(1.6rem, 2.5rem, 0);
      @include media(1200px) {
        @include fontSize(1.4rem, 2.2rem, 0);
      }
    }
  }
  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: black;
  }
}
