@import "./productDetails/productDetails";

.configureProductModal {
  max-width: 100%;
  margin: 0;
  height: 100%;
  .sidebar-item-container .image-container .small-image-nailhead {
    width: 120px;
    height: 120px;
  }
  .tooltip-info {
    left: -10px !important;
  }
  .modal-header {
    background-color: white !important;
    color: black;
  }
  .modal-content {
    height: 100%;
  }
  .configureProductModal-header {
    // d-flex bg-default mx-0 p-4 align-items-center position-relative
    display: flex;
    padding: 2.8rem 9rem;
    align-items: center;
    position: relative;
    h2 {
      font-family: $secondaryFontGibson;
    }
  }
  .sidebar-title-section {
    display: none;
  }
  .sidebar-container {
    width: unset;
    box-shadow: none;
    .sidebar-footer {
    }
  }
  .sidebar-item-container {
    height: unset;
    width: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.6rem;
    p {
      text-align: center;
      color: #404040;

      text-align: center;
      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      display: block !important;
    }
    &.checked {
      .image-container {
        border: 1px solid #000;
      }
    }
    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 164px;
      height: 164px;
      border-radius: 8px;
      img {
        width: 156px;
        height: 156px;
        object-fit: cover;
      }
      .selected {
        display: none;
      }
    }
  }
}
