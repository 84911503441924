.search-build-container {
  text-align: center;
  .container {
    background: rgba(0, 0, 0, 0.3);
  }
  img {
    object-fit: cover;
    height: 52rem;
  }
}
.build-content {
  position: relative;
  .build-content-link {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    a {
      display: flex;
      margin: 0 auto;
    }
  }
}
