@import "./newsletterModal/newsletterModal";
#footer {
  text-align: none;

  .logo {
    transform: scale(0.6);
  }
}

.footer-categories {
  @media (min-width: 992px) {
    display: grid !important;
    gap: 2rem;
  }
  .btn-default {
    margin-bottom: 0;
  }
  li {
    list-style-type: none;
    text-align: left;
    a.body-2 {
      font-size: 1.8rem;
    }

    font-weight: 400;
  }
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 5rem;
  grid-template-columns: repeat(4, 1fr);
  > ul {
    &:nth-of-type(3) {
      li {
        justify-content: space-between;
      }
    }
    margin: 0;
    background-color: none;
    padding: 2rem 0 0 0;
    list-style: none;
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid; /* Theoretically FF 20+ */
    break-inside: avoid-column; /* IE 11 */
    display: block; /* Actually FF 20+ */
    height: 100%;
    min-height: 100%;
    &.list-group {
      .list-group-item {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0;
        background-color: transparent;
        border: none;
        &::before {
          content: none;
        }
        > p {
          margin: 0 auto;
        }
        ul {
          margin-bottom: 5rem;
        }
      }
    }

    a {
      line-height: 1.8;
    }
  }
}
.cms-rep-portal {
  margin-top: auto;
}
.jola-logo,
.jola-logo-lg {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1.5rem;
  height: 9rem;
}
.footer-social-container {
  @media (min-width: 992px) {
    display: block !important;
  }
  min-height: 9rem;
  display: none;
}
// specific for icons and need to be added trough typography?
.cms-social-links {
  a {
    font-size: 1.8rem;
  }
}
.footer-social-icons-wrapper {
  max-width: 255px;
  i {
    color: white;
    font-size: 2rem;
    &:hover {
      font-size: 2.1rem;
    }
  }
}
@include media-breakpoint-down(lg) {
  #footer {
    text-align: center;

    .logo {
      transform: scale(1);
    }
  }

  .cms-footer-bottom-links {
    .body-5 {
      font-size: 1rem;
      white-space: nowrap;
    }
  }
  .jola-logo {
    position: relative;
    text-align: center;
  }
}
@include media-breakpoint-down(xs) {
  #footer {
    text-align: center;

    .logo {
      transform: scale(1);
    }

    .container {
      img {
        margin-top: 1.5rem;
      }
    }
  }

  .cms-updates-signup,
  .cms-social-links {
    h4 {
      font-weight: $fwregular;
    }
    .body-2 {
      font-size: 1.4rem;
      color: $default;
    }
  }
  .cms-footer-bottom-links {
    .body-5 {
      font-size: 1.3rem;
    }
  }
}

@include media-breakpoint-down(md) {
  .cms-rep-portal {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .cms-make-a-payment {
    margin-bottom: 2rem;
  }
  #footer {
    .container {
      img {
        margin-top: 2rem;
      }
    }
  }
}
