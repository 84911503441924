.sidebar-footer-tab {
  .selected-option-tab-view {
    display: flex;
    gap: 2rem;
    .selected-option-values {
      display: flex;
      gap: 1.6rem;
    }
  }
  .option-label {
    color: #797979;

    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    &.additional-info-option {
        font-size: 16px;
    }
  }
  .option-value {
    color: #404040;
  }
  .moreDetails {
    color: #797979;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }
  .moreDetails-link {
    color: #404040;
    margin-left: 8px;
    font-family: $secondaryFontGibson;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    text-decoration-line: underline !important;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }
}
