.explore-industry-container {
  .explore-slider {
    .slick-arrow {
      top: 41%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .explore-industry-container {
    .explore-slider {
      &:hover {
        .slick-arrow {
          &.slick-next,
          &.slick-prev {
            &:before {
              opacity: 1;
              transition: all ease-in-out 0.5s;
            }
          }
        }
      }
      .slick-arrow {
        position: absolute;
        width: 8rem;
        height: 8rem;
        z-index: 3;
        &:before {
          display: flex;
          font-weight: 600;
          font-size: 20px;
          top: 0;
          width: 8rem;
          height: 8rem;
          transition: all ease-in-out 0.5s;
        }
        &.slick-prev {
          left: 2%;
          transform: rotate(-90deg);
          &:before {
            transform: rotate(90deg);
            opacity: 0;
            content: "";
            background-image: url("../../../assets/icon/leftMobileExplore.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        &.slick-next {
          right: 2%;
          transform: rotate(-90deg);
          &:before {
            transform: rotate(-90deg);
            opacity: 0;
            content: "";
            background-image: url("../../../assets/icon/leftMobileExplore.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
    .slick-slide {
      height: 46rem;
      max-height: 100%;
      > div {
        height: 100%;
      }
      .category-widget-container {
        height: 100%;
        .category-widget {
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .explore-industry-container {
    .slick-slider {
      button {
        &.slick-arrow {
          width: 6rem;
          height: 6rem;
          top: 37%;
          &:before {
            width: 6rem;
            height: 6rem;
          }
        }
      }
    }
    .slick-slide {
      height: 30rem;
      max-height: 100%;
      > div {
        height: 100%;
      }
      .category-widget-container {
        height: 100%;
        .category-widget {
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .explore-industry-container {
    .slick-slide {
      height: 20rem;
      max-height: 100%;
      > div {
        height: 100%;
      }
      .category-widget-container {
        height: 100%;
        .category-widget {
          height: 100%;
          img {
            height: 100%;
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }
}
