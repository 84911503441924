.finishes-info-wrapper {
  p {
    line-height: 2.1rem;
  }
  ul {
    list-style: none;
    padding-left: 2rem;
    li {
      line-height: 2rem;
      &.list-group-item {
        &::before {
          font-size: 1rem;
        }
      }
    }
  }
}

.finish-fix-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.finish-fix-row {
  width: 100%;
  display: block;
}
