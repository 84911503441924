.wishlist-wrapper {
  color: #333 !important;
  a,
  h1,
  h2,
  h3 {
    color: #333 !important;
  }
  button.btn {
    &.wishlist-btn,
    &.primary {
      background-color: #eee !important;
      border: 1px solid #333;
      border-radius: 0;
      box-shadow: none;
      color: #000;
      font-size: 1.2rem;
      width: 100%;
      max-width: 100%;
      margin: 1rem 0;
      &:not(.disabled) {
        &:active {
          background-color: #eee !important;
          box-shadow: none;
        }
      }
    }
  }
}
.wishlist-create-wrapper {
  h4 {
    text-align: center;
    background: #eee;
    padding: 1rem;
  }
  .wishlist-create-content {
    padding: 2rem;
  }
}
button.btn {
  &.wishlist-btn,
  &.primary {
    background-color: #eee !important;
    border: 1px solid #333;
    border-radius: 0;
    box-shadow: none;
    color: #000;
    font-size: 1.2rem;
    width: 100%;
    max-width: 100%;
    margin: 1rem 0;
    &:active {
      background-color: #eee !important;
    }
  }
}
.default-input-field-container {
  input {
    &.wishlist-input {
      border: 1px solid #333;
      border-radius: 0 !important;
      box-shadow: none;
    }
  }
  label {
    font-size: 1.2rem !important;
  }
}
.wishlist-listing {
  &:after {
    content: "";
    flex: 1 1 100%;
    max-width: 33%;
  }
  .wishlist-list-item {
    &.list-item {
      border: 1px solid #999;
      margin: 2rem 0;
      width: calc(1 / 3 * 100% - (1 - 1 / 3) * 1%);
      img {
        max-height: 15rem;
        max-width: 100%;
      }
    }
  }
}
.modal {
  &.show {
    .wishlist-wrapper {
      &.modal-dialog {
        .modal-content {
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
            0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
          outline: none !important;
        }
      }
    }
  }
}

.modal-wrapper {
  &.edit-wishlist-modal,
  &.move-wishlist-modal {
    button.btn {
      &.wishlist-btn,
      &.primary {
        background-color: #eee !important;
        border: 1px solid #333;
        border-radius: 0;
        box-shadow: none;
        color: #000;
        font-size: 1.2rem;
        width: 100%;
        max-width: 100%;
        margin: 1rem 0;
        &:active {
          background-color: #eee !important;
        }
      }
    }
    .modal-body {
      max-width: 100% !important;
      width: 100%;
      .edit-wishlist-modal-content {
        .wishlist-btn.edit {
          flex: 0 0 30%;
        }
      }
    }
  }
}
