.downloadsGeneric {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    width: 100%;
  .download-link {
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    display: flex;
    width: 277px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    .nav-link {
      color: #6c808b;
      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      text-decoration: none !important;
      padding: 0;
    }
  }
}
