.home-v2-block {
  max-width: 1154px !important;
  margin: 0 auto !important;

  & > div {
    width: 100%;
  }
}

.heading-32 {
  font-weight: $fwthin;
  font-size: $fs-26;
  font-family: $secondaryFontGibson;
  font-weight: 100;
  letter-spacing: 0;
  color: $gray-darken-3;
  line-height: $lh-30;
  @include media-breakpoint-up(xl) {
    font-size: $fs-32;
    line-height: $lh-46;
  }
}

.heading-24 {
  font-weight: $fwmedium;
  font-size: $fs-16;
  font-family: $secondaryFontGibson;
  line-height: $lh-24;
  @include media-breakpoint-up(xl) {
    font-size: $fs-24;
    line-height: $lh-30;
  }
}

.p-24 {
  font-weight: $fwlight;
  font-size: $fs-16;
  font-family: $secondaryFontGibson;
  line-height: $lh-24;
  @include media-breakpoint-up(xl) {
    font-size: $fs-24;
    line-height: $lh-30;
  }
}

.li-item-fs-20 {
  font-weight: $fwlight;
  font-size: $fs-14;
  font-family: $secondaryFontGibson;
  // line-height: $lh-24;
  @include media-breakpoint-up(xl) {
    font-size: $fs-20;
    // line-height: $lh-30;
  }
}

.heading-32-width {
  max-width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 24ch;
  }
}

.hero-banner {
  .hero-banner-row {
    width: 100%;
    position: relative;

    .hero-banner-image img {
      width: 100%;
      object-fit: cover;
    }

    .pagebuilder-column-group {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translate(-50%, 0);
      background: #fff;
      opacity: 0.8;
      padding: 3rem 8rem;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 2rem 1.4rem;
      }

      .hero-title {
        font-family: $fontUtopia;
        font-size: $fs-48;
        font-weight: $fwregular;
        line-height: $lh-56;
        color: $gray-darken-3;
        text-align: center;

        @include media-breakpoint-down(md) {
          font-size: $fs-36;
          line-height: $lh-42;
        }
      }

      .hero-paragraph {
        font-family: $secondaryFontGibson;
        font-size: $fs-24;
        font-weight: $fwlight;
        line-height: $lh-36;
        color: $gray-darken-3;
        text-align: center;

        @include media-breakpoint-down(md) {
          font-size: $fs-16;
          line-height: $lh-24;
        }
      }
    }
  }
}

.quality-modal {
  max-width: 114rem;
}




.responsive-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  height: 62.9rem;
  @media screen and (max-width: 767px) {
    height: 30rem;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.h-title{
  font-size: 4rem;
  // font-weight: 300;
  color: $black;
  margin-bottom: 1rem;
  @media screen and (max-width: 991px) {
    font-size: 1.5rem;
  }
  font-family: "utopia-std-display", sans-serif;
 
}
.info-holder{
  margin: 0 100px;
  position: relative;
  top: calc(-25vh);
  background-color: rgba(233, 234, 234, 0.6);
}
