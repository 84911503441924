// .custom-container {
//   &-main-image {
//     .product-media-slider.slick-initialized {
//       .slick-list {
//         .slick-track {
//           .slick-slide {
//             img {
//               width: 56rem !important;
//               max-width: unset;
//               height: auto;
//             }
//           }
//         }
//       }
//     }
//   }
// }
.related-container {
  margin-top: 6rem;
  margin-bottom: 18rem;
  .related-wrapper {
    &:hover {
      .related-info {
        h4 {
          background-color: $gray-lighten-4;
        }
      }
    }
    .related-img {
      .img-fluid {
        height: auto;
      }
    }
    .related-info {
      .border-bottom {
        border-bottom: 3px solid $default !important;
      }
    }
  }
}
.custom-container {
  .related-container {
    .related-img {
      .img-fluid {
        max-height: 25rem;
        height: 100%;
        object-fit: cover;
        display: flex;
        justify-content: center;
      }
    }
    h4 {
      height: 6rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  .custom-container {
    .related-container {
      margin-top: 6rem;
      margin-bottom: 18rem;
      .related-wrapper {
        .related-img {
          height: 100%;
          max-height: 25rem;
          .img-fluid {
            h4 {
              min-height: 7rem;
            }
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .custom-container {
    &-main-image {
      .product-media-slider.slick-initialized {
        .slick-list {
          .slick-track {
            .slick-slide {
              img {
                width: 100% !important;
                max-width: unset;
                height: auto;
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(max-width:420px) {
  .custom-container {
    .related-container {
      .slick-slider {
        .slick-dots {
          bottom: -5rem;
        }
      }
    }
  }
}
