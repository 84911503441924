@import "./options/options";

@include media-breakpoint-down(lg) {
  .mt-lg--25 {
    margin-top: 0;
  }
  #cmsblock-installation_gallery {
    .title-explore,
    .text-explore {
      max-width: 100% !important;
    }
  }
}
@include media-breakpoint-between(lg, xl) {
  .mt-lg--25 {
  }
}
// @include media-breakpoint-up(xl) {
//   .mt-lg--25 {
//     margin-top: -27rem;
//   }
// }

@include media-breakpoint-down(xs) {
  .controls-container {
    z-index: 1 !important;
  }
  .more-options-sidebar {
    > .row {
      width: 90% !important;
    }
  }
}
.pdp-main-image {
  img {
    width: 67rem;
  }
}
.need-assistance-wrapper {
  @include media-breakpoint-down(md) {
    padding-left: 0 !important;
  }
  .need-assistance-text {
    max-width: 90%;
    color: $primary;
    &.custom {
      margin-left: 1.5rem;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
    a {
      text-decoration: underline !important;
    }
  }
}
// BORDER FOR TOOLTIP INSIDE SIDEBAR ON PDP
.border-tooltip-img {
  border: 1px solid $gray;
}
