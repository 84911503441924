.customer-type-step-one___required-label {
  color: #951a1a;
}

.upload-reseller-cert___step-one {
  .md-form {
    width: 32.8rem;
  }
}
.registration-container {
  .select2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .react-select-container.error {
      .react-select__control {
        border-color: rgba(149, 26, 26, 0.35) !important;
        &.react-select__control--is-disabled {
          border-color: rgba(149, 26, 26, 0.25) !important;
        }
      }
    }
    .react-select-container.react-select--is-disabled {
      .react-select__control--is-disabled {
        border-style: solid;
        border-color: rgba(108, 128, 139, 0.25) !important;
      }
    }
  }
  .text-red {
    bottom: -0.5rem;
    height: 17px;
    &.masked-input-error {
      bottom: -1.5rem;
    }
  }
  .masked-input.error,
  .md-form input.error {
    border-color: rgba(149, 26, 26, 0.75) !important;
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
  .masked-input:disabled,
  .md-form input:disabled[type="password"]:not(.browser-default),
  .md-form input:disabled[type="email"]:not(.browser-default),
  .md-form input:disabled[type="text"]:not(.browser-default) {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .masked-input,
  .md-form input[type="password"]:not(.browser-default),
  .md-form input[type="email"]:not(.browser-default),
  .md-form input[type="text"]:not(.browser-default) {
    background-color: rgba(255, 255, 255, 1) !important;
  }
  .form-control.error {
    border-color: rgba(149, 26, 26, 0.75) !important;
  }
  .masked-input:disabled,
  .md-form input:disabled[type="password"]:not(.browser-default),
  .md-form input:disabled[type="email"]:not(.browser-default),
  .md-form input:disabled[type="text"]:not(.browser-default) {
    background-color: rgba(255, 255, 255, 0.75) !important;
  }
  .masked-input:not([value=""]),
  .md-form input:not([value=""])[type="password"]:not(.browser-default),
  .md-form input:not([value=""])[type="email"]:not(.browser-default),
  .md-form input:not([value=""])[type="text"]:not(.browser-default),
  .masked-input:focus:not([readonly]),
  .md-form input:focus:not([readonly])[type="password"]:not(.browser-default),
  .md-form input:focus:not([readonly])[type="email"]:not(.browser-default),
  .md-form input:focus:not([readonly])[type="text"]:not(.browser-default) {
    background-color: rgba(255, 255, 255, 0.75) !important;
    // border-color: rgba(149, 26, 26, 0.5) !important;
    transition: none;
  }

  .registration-container .masked-input:not([value=""]),
  .registration-container
    .md-form
    input:not([value=""])[type="password"]:not(.browser-default),
  .registration-container
    .md-form
    input:not([value=""])[type="email"]:not(.browser-default),
  .registration-container
    .md-form
    input:not([value=""])[type="text"]:not(.browser-default),
  .registration-container .masked-input:focus:not([readonly]),
  .registration-container
    .md-form
    input:focus:not([readonly])[type="password"]:not(.browser-default),
  .registration-container
    .md-form
    input:focus:not([readonly])[type="email"]:not(.browser-default),
  .registration-container
    .md-form
    input:focus:not([readonly])[type="text"]:not(.browser-default) {
    border-color: rgba(149, 26, 26, 0.5) !important;
    opacity: 0.75 !important;
  }
  .md-form input:not([type]),
  .md-form input[type="text"]:not(.browser-default),
  .md-form input[type="password"]:not(.browser-default),
  .md-form input[type="email"]:not(.browser-default),
  .md-form input[type="url"]:not(.browser-default),
  .md-form input[type="time"]:not(.browser-default),
  .md-form input[type="date"]:not(.browser-default),
  .md-form input[type="datetime"]:not(.browser-default),
  .md-form input[type="datetime-local"]:not(.browser-default),
  .md-form input[type="tel"]:not(.browser-default),
  .md-form input[type="number"]:not(.browser-default),
  .md-form input[type="search"]:not(.browser-default),
  .md-form input[type="search-md"],
  .md-form textarea.md-textarea {
    transition: none;
  }
}

@include media-breakpoint-down(lg) {
  .steps {
    overflow: auto;
    white-space: nowrap;
  }
}
