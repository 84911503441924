#fileData {
  .file-data-row {
    border-color: $gray;
    &:hover {
      .resources-item-title {
        text-decoration: underline;
      }
    }
    .resources-item-name-img-column {
      .imageloader {
        .react-loading-skeleton {
          width: 9.5rem;
          height: 9.5rem;
          margin-right: 1.5rem;
        }
        &.play-button-over-img {
          position: relative;
          &:before {
            content: "";
            width: 100%;
            height: 100%;
            background: url("../../../../assets/icon/playSmall.svg") no-repeat
              center;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
      /*Media Queries*/
      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
      }
    }
  }
  .resources-item-thumbnail {
    max-width: 9.5rem;
  }
}
.resources-preview-modal {
  &.modal-dialog {
    max-width: 65rem;
    .resources-modal-video {
      width: 100%;
      height: 50rem;
      /*Media Queries*/
      @include media-breakpoint-down(xs) {
        height: 35rem;
      }
    }
    .close-btn {
      top: -4rem;
      right: -5rem;
      @include media-breakpoint-down(sm) {
        right: 0;
      }
      @include media-breakpoint-down(xs) {
      }
    }
    .react-loading-skeleton {
      width: 50rem;
      height: 50rem;
      @include media-breakpoint-down(xs) {
        width: 100%;
        height: 35rem;
      }
    }
  }
}
