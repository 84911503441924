@import "./finishDetail/finishDetail";
@import "./finishItem/finishItem";

#finish-sidebar {
  height: 100vh;
  width: 56rem;
  background-color: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  box-shadow: -3px 0px 6px #00000062;
  .finish-sidebar-skeleton {
    width: 100%;
    .items-skeleton {
      .col {
        padding: 0 1rem;
      }
    }
  }
  .sidebar-header {
    h2 {
      font-size: 40px;
    }
  }
  .sidebar-footer {
    box-shadow: 0px 0px 5px #00000062;
    height: 12.5rem;
    position: fixed;
    bottom: 0;
    width: 56rem;
    .sidebar-footer-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      .selected-item {
        margin-bottom: 3rem;
        width: 100%;
      }
    }
    button {
      margin: 0;
    }
    .view-details {
      max-width: 9.6rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      &.disabled {
        max-width: 9.6rem;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent !important;
        border: 2px solid #6c808b;
        opacity: 0.5;
        font-size: 14px !important;
      }
    }
  }
  .finish-sidebar-main-content {
    overflow-y: scroll;
    max-height: 80vh;
    @include media-breakpoint-up(md) {
      max-height: 70vh;
    }
    @include media-breakpoint-up(xs) {
      max-height: 65vh;
    }
    .finish-sidebar-main-column {
      // padding: 0;
    }
    .finish-sidebar-image-container {
      height: 32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 32rem;
        object-fit: contain;
      }
    }
    .items-container {
      // margin: 0;
      // padding: 0 0.5rem;
      // padding-bottom: 3rem;
      overflow: visible;
    }
    .items-container::-webkit-scrollbar {
      display: none;
    }
    .finish-toolbar {
      background-color: white;
    }
    .finishes-categories-toolbar {
      // margin-right: 0;
      padding: 2.5rem 0;
      justify-content: center;
      // flex-direction: row;
      // max-width: 100%;
      // overflow-x: scroll !important;
      overflow-x: hidden;
      // overflow-y: hidden;
      flex-wrap: nowrap !important;
      scroll-behavior: smooth;
      .finishes-categories-toolbar-item {
        max-width: fit-content;
        cursor: pointer;
        p {
          white-space: nowrap;
          font-size: 16px;
          padding-bottom: 5px;
        }
        &.active-item {
          p {
            border-bottom: 5px solid $default;
            font-weight: 600;
          }
        }
      }
    }
    .finishes-categories-toolbar::-webkit-scrollbar {
      display: none;
    }
    .empty-fabric-array {
      min-height: 35rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .finish-sidebar-main-content::-webkit-scrollbar {
    display: none;
  }
}

@media screen and(max-width:600px) {
  #finish-sidebar {
    width: 100%;
    height: 100%;
    margin: 0;
    .sidebar-header {
      max-height: 7rem;
      h2 {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
    .sidebar-footer {
      width: 100%;
      button {
        max-width: 16rem;
      }
      .sidebar-footer-column {
        .selected-item {
          margin-bottom: 1.3rem;
          .row {
            flex-direction: column;
          }
        }
      }
    }
    .finishes-categories-toolbar {
      justify-content: start;
    }

    .items-container {
      .items-column {
        padding: 0;
        .items-row {
          margin: 0;
        }
      }
    }
  }
}
