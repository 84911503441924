.modal.show .modal-dialog .modal-content {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.checkbox-container {
  &.wishlist-plp {
    input[type="checkbox"] {
      position: relative;
    }
  }
}
.modal {
  .error {
    .md-form {
      input {
        border-color: red !important;
        color: red !important;
      }
    }
  }
  &.show {
    .modal-dialog.wishlist-modal {
      color: #333;
      .modal-content {
        .modal-header {
          h5 {
            width: 100%;
            padding: 2rem;
            background: #ccc;
            color: #333;
          }
        }
        .modal-body {
          span {
            color: #333;
          }
        }
        .md-form {
          margin: 0;
          input {
            border-radius: 0 !important;
            height: 100%;
            margin: 0;
          }
        }
      }
      button {
        &.btn {
          &.btn-default {
            &.create-btn {
              border: none;
              background-color: #fff !important;
              font-size: 1.2rem;
              color: #333;
              box-shadow: none;
              &:active {
                border: none;
                background-color: #fff !important;
                font-size: 1.2rem;
                color: #333;
                box-shadow: none;
              }
            }
          }
        }
      }

      .create-box {
        padding: 0 2rem;
        margin: 1.5rem 0;
        .create-one {
          flex: 0 0 65%;
          margin: 0;
        }
        .create-two {
          flex: 0 0 30%;
          margin: 0;
          button {
            margin: 0;
          }
        }
      }
    }
  }
}
