.mobile-menu-back-wrapper {
  height: 6.6rem;
  background: $gray-lighten-4;
  position: sticky;
  top: 0;
  z-index: 11;
}

@media screen and (max-width: 480px) {
  .mobile-menu-back-wrapper {
    overflow: hidden;
  }
  .toggle-dropdown.p-0.text-uppercase.active {
    .text-right {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}


.toggle-dropdown{
  overscroll-behavior: none;
}