@import "./filterTypes/multiSelectFilterItem/multiSelectFilterItem";
@import "./filterTypes/switchFilterItem/switchFilterItem";

.filters-container {
  overflow: hidden;
  .filters-sidebar-skeleton {
    width: 100%;
  }
  .filters-multi-wrapper {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    // overflow-x: scroll !important;
    overflow-y: hidden;
    flex-wrap: nowrap !important;
    scroll-behavior: smooth;
    margin: 0;
    margin-left: 2rem;
    z-index: 10;
    background-color: white;
    .multi-select-filter-item {
      white-space: nowrap;
      p {
        min-width: 11.3rem;
      }
    }
  }
  .multi-select-options {
    margin: 0;
    max-width: 100%;
    width: 100%;
    transition: all 0.5s ease-in-out;
    z-index: 9;
    margin-top: 1rem;
    &.active {
      animation: 1s fadeIn;
      transform: translateY(0);
      transition: all 0.5s ease-in-out;
    }
  }
  .filters-multi-wrapper::-webkit-scrollbar {
    display: none;
  }
  .searchable-filter {
    .md-form {
      input {
        border: 0.1rem solid $gray !important;
        padding-left: 6rem;
        width: 92% !important;
        margin-left: 1.5rem;
        &:focus {
          border: 0.1rem solid $default !important;
        }
      }
      .search-filter-icon {
        top: 1rem;
        left: 4rem;
      }
    }
  }
}
@media screen and(max-width:600px) {
  .filters-container {
    .filters-multi-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 2rem;
    }
    .filters-sidebar-skeleton {
      .filters-skeleton-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
      }
    }
  }
}
