.fabric-media-gallery-container {
  margin-bottom: 6rem;
  margin-top: 1rem;
  .fabric-info-container {
    padding-left: 4rem;
  }
  .fabric-media-slider {
    .slick-list {
      .slick-slide {
        max-height: 56rem;
        div {
          height: 100%;
          max-width: 56rem;
          img {
            height: 100%;
            width: 100%;
            object-fit: none;
            border: 1px solid #d8d8d8;
          }
        }
      }
    }
  }
}

@media screen and(max-width:992px) {
  .fabric-media-gallery-container {
    margin-bottom: 0;
    .fabric-info-container {
      padding-left: 1.5rem;
    }
    .fabric-media-slider {
      margin-bottom: 3rem;
      .slick-list {
        .slick-slide {
          div {
            max-width: unset;
          }
        }
      }
    }
  }
}

@media screen and(max-width:485px) {
  .fabric-media-gallery-container {
    .fabric-media-slider {
      .slick-list {
        .slick-slide {
          max-height: 40rem;
        }
      }
    }
  }
}
