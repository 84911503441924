.rep-locator {
  h2 {
    &.display-2 {
      font-weight: 300;
    }
  }
  .find-rep-initial {
    > div {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
      @media (min-width: 992px) {
        padding-top: 14rem;
        padding-bottom: 14rem;
      }
    }
  }
  .logo-wrapper {
    a {
      display: flex;
      justify-content: center;
      img {
        @media (min-width: 992px) {
          width: 85%;
        }
      }
    }
  }
  .rep-email {
    word-break: break-all;
  }
  .location-icon {
    width: 2rem;
  }
  .rep-please-contact {
    margin-bottom: 10rem;
  }
}
