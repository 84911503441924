.related-slider {
  margin-top: 6rem;
  .slick-list {
    margin: 0 -9px;
  }
  .slick-slide {
    // padding: 0 9px;
    .product-img-wrapper {
      height: 25rem;
      width: 100%;
      margin: 0 auto;
    }
    .content-product {
      max-height: 11rem;
      .product-name {
        height: 4rem;
      }
    }
    img {
      &.img-fluid {
        width: 100%;
        height: auto;
      }
    }
  }
}
.info-container-wrapper {
  // max-width: 44.4rem;
  .fabric-info {
    h4 {
      font-size: 14px;
    }
    .attribute-name {
      h4 {
        color: #6c808b;
      }
    }
  }
  .available-in-industry {
    img {
      width: 2.5rem;
    }
  }
}
@include media-breakpoint-up(lg) {
  .related-slider {
    .product-name {
      display: none;
    }
  }
}
@include media-breakpoint-down(md) {
  .related-slider {
    .slick-slide {
      .product-img-wrapper {
        height: 25rem !important;
        // width: 100%;
        // margin: 0 auto;
      }
      .content-product {
        max-height: 11rem;
        .product-name {
          height: 5rem;
        }
      }
      img {
        &.img-fluid {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .info-container-wrapper {
    max-width: unset;
  }
}
@include media-breakpoint-down(xs) {
  .product-widget {
    .bg-gray-lighten-4 {
      background-color: transparent !important;
    }
  }
}
@media screen and (max-width: 390px) {
  .related-products {
    .related-slider {
      .slick-slide {
        .content-product {
          .product-name {
            height: 6rem;
          }
        }
      }
    }
  }
}
