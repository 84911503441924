.vdc {
  padding: 4.8rem 0;

  .winner {
    border-bottom: 1px solid $default;
    .banner_image {
      max-height: 40rem;
      width: auto;
    }
  }
  .hover-image {
    opacity: 0.4;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  .title-container {
    margin-left: 0;
    h3 {
      font-size: 4rem;
    }
  }
  .w-60 {
    width: 60%;
  }
  .name-wrapper-top,
  .name-wrapper-bottom {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 100%;
  }
  .name-wrapper-top {
    top: 0;
    h3 {
      color: #404040;
      font-weight: bold;
    }
    .superscript {
      vertical-align: super;
      font-size: 1.6rem;
    }
  }
  .name-wrapper-bottom {
    bottom: 0;
    > h3 {
      color: #404040;
    }
  }
  .hero-img-wrapper {
    position: relative;
    h2 {
      position: absolute;
      color: #404040 !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10rem;
      line-height: 7rem;
      text-align: center;
      width: 100%;
    }
  }
  p {
    font-size: 2.4rem;
    font-weight: normal;
  }
  .gallery-wrapper {
    .h2 {
      font-size: 2.4rem;
    }
    > div:not(.winner) {
      &:last-of-type {
        div {
          img:not(.banner_image) {
            object-position: 52% 25% !important;
          }
        }
      }
      > div {
        height: 39.8rem;
        img:not(.banner_image) {
          width: 100%;
          object-fit: none !important;
          height: 100% !important;
          min-height: 100%;
        }
        &:first-of-type {
          img:not(.banner_image) {
            object-position: 50% 0%;
          }
        }
      }

      .banner_image {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .four-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.6rem;
    // img {
    //   object-fit: cover;
    //   height: 100%;
    // }
  }
  .three-items {
    display: flex;
    justify-content: space-between;
    // grid-column-gap: 5rem;
    // grid-template-columns: repeat(3, 1fr);
  }
}

.h2 .vdc .display-2 {
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .vdc {
    .pagebuilder-mobile-only {
      display: none;
    }
    .gallery-wrapper {
      .name-wrapper-bottom {
        max-width: calc(100% - 3rem);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .vdc {
    h3,
    .name-wrapper-top .superscript {
      font-size: 1.1rem;
    }
    .winner {
      .name-wrapper-bottom {
        max-width: calc(100% - 3rem);
      }
    }
    .gallery-wrapper {
      > div:not(.winner) {
        > div {
          padding: 0 0.8rem;
        }
        &:last-of-type {
          div {
            img:not(.banner_image) {
              object-position: 56% 25% !important;
            }
          }
        }
        > div {
          &:first-of-type {
            img:not(.banner_image) {
              object-position: 50% 0%;
            }
          }
        }
      }
      .name-wrapper-bottom {
        max-width: calc(100% - 1.6rem);
      }
    }
    .four-items {
      grid-template-columns: repeat(2, 1fr);
    }
    .hero-img-wrapper h2 {
      width: 100%;
      font-size: 5rem;
      line-height: 5rem;
    }
    .title-container h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.4rem;
    }
    .gallery-wrapper {
      > div:first-of-type > div {
        img {
          width: 100%;
        }
      }
    }
    .four-items,
    .three-items {
      display: grid;
      grid-gap: 1.5rem;
    }
    .three-items {
      grid-template-areas:
        "title title title"
        "left middle right";
      > div:first-of-type {
        grid-area: title;
        text-align: center;
        display: flex;
        justify-content: center;
        .name-wrapper-bottom,
        .name-wrapper-top {
          max-width: 26rem;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      > div:nth-of-type(2) {
        grid-area: left;
      }
      > div:last-of-type {
        grid-area: right;
      }
    }
    .four-items {
      grid-template-columns: 1fr 1fr;
      display: grid;
    }
  }
}

@media screen and (max-width: 576px) {
  .vdc {
    .four-items {
      > div {
        overflow: hidden;
        img:not(.hover-image) {
          position: relative;
        }
        &:first-of-type {
          img:not(.hover-image) {
            transform: scale(1.5);
            top: 3rem;
          }
        }
        &:nth-of-type(2) {
          img:not(.hover-image) {
            transform: scale(2.2);
            top: 14rem;
            left: 7rem;
          }
        }
        &:nth-of-type(3) {
          img:not(.hover-image) {
            transform: scale(2);
            top: 7rem;
            left: 2rem;
          }
        }
        &:last-of-type {
          img:not(.hover-image) {
            transform: scale(2);
            top: 8rem;
          }
        }
      }
    }
  }
}
@supports (-webkit-touch-callout: none) {
  // .four-items img,
  .gallery-wrapper img:not(.banner_image) {
    height: auto !important;
    object-fit: contain !important;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  // .four-items img,
  .gallery-wrapper img:not(.banner_image) {
    height: auto !important;
    object-fit: contain !important;
  }
}

// @supports (-webkit-touch-callout: none) {
//   .gallery-wrapper > div:not(.winner) > div {
//     height: 25rem;
//     > img {
//       height: 100%;
//     }
//   }
//   .gallery-modal-popup img {
//     width: 100%;
//     max-height: 25rem;
//   }
// }
