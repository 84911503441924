$mdInputWidth: 21.2rem;
$lgInputWidth: 32.8rem;
$tabBeforeSize: 0rem;
$tabBeforeSizeActive: 0.5rem;

@keyframes tabVerticalHover {
  0% {
    width: $tabBeforeSize;
  }
  40% {
    width: $tabBeforeSizeActive;
  }
  60% {
    width: $tabBeforeSizeActive;
  }
  100% {
    width: $tabBeforeSize;
  }
}

@keyframes tabHorisontalHover {
  0% {
    height: $tabBeforeSize;
  }
  40% {
    height: $tabBeforeSizeActive;
  }
  60% {
    height: $tabBeforeSizeActive;
  }
  100% {
    height: $tabBeforeSize;
  }
}

.width-md {
  width: $mdInputWidth;
}

.option-selected {
  position: relative;
  &:after {
    content: "✓";
    background-color: $default;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: white;
  }
}

// Tabs
.tab {
  height: 4rem;
  font-weight: $fwlight;
  font-size: 1.6rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;

  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
  }

  &.vertical-tab {
    color: $white;
    padding-left: 1rem;
    &:before {
      content: "";
      transition: width 0.2s ease-in-out;
      height: 100%;
      width: $tabBeforeSize;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $white;
    }
    &:hover:not(.active) {
      &:before {
        animation: tabVerticalHover 0.8s 1;
      }
    }
    &.active {
      font-weight: $fwregular;
      &:before {
        width: $tabBeforeSizeActive;
      }
    }
  }

  &.horisontal-tab {
    color: $gray-darken-3;
    padding-bottom: 1rem;
    &:before {
      content: "";
      transition: height 0.2s ease-in-out;
      height: $tabBeforeSize;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $default;
    }
    &:hover:not(.active) {
      &:before {
        animation: tabHorisontalHover 0.8s 1;
      }
    }
    &.active {
      font-weight: $fwregular;
      &:before {
        height: $tabBeforeSizeActive;
      }
    }
  }
}

// Inputs

%disabled-label {
  opacity: 0.5;
}

.label__disabled {
  @extend %disabled-label;
}

%input {
  box-sizing: border-box;
  width: 100% !important;
  border: 2px solid $default;
  border-color: $default;
  border-radius: 5px;
  height: 4rem;
  padding: 1rem 2rem;
  @extend %field-text;
  color: $gray-darken-3 !important;
  opacity: 0.5;

  &:not([value=""]),
  &:focus:not([readonly]) {
    opacity: 1;
    border-bottom: 2px solid $default;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.25;
    & + label {
      @extend %disabled-label;
    }
  }

  &:required {
    & + label {
      &::before {
        content: "*";
        color: $red;
        top: 0;
        font-size: 1.2rem;
        position: absolute;
        right: -0.8rem;
      }
    }
  }

  &.border-sm {
    border: 1px solid $gray !important;
  }
}

%error-input {
  border-color: $red !important;
  color: $red !important;
}

.masked-input {
  @extend %input;
  &.error {
    @extend %error-input;
  }
  &.lg {
    width: $lgInputWidth !important;
    @include media-breakpoint-down(xs) {
      width: 100% !important;
    }
  }
  &.sm {
    width: $mdInputWidth !important;
  }
}

.md-form {
  label {
    font-size: 2.4rem !important;
    @include media-breakpoint-down(sm) {
      font-size: 2rem !important;
    }
  }

  input {
    &.error,
    &.validation-error {
      @extend %error-input;
    }
  }

  width: $mdInputWidth;
  height: 4rem;
  &.form-lg {
    width: $lgInputWidth;
    label {
      @extend h2;
      top: -3rem;
      transform: none !important;
      color: $gray-darken-3 !important;
      &.active {
        @extend h2;
        top: -3rem;
        transform: none !important;
        color: $gray-darken-3 !important;
      }
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  input[type="password"]:not(.browser-default),
  input[type="email"]:not(.browser-default),
  input[type="text"]:not(.browser-default) {
    @extend %input;
  }
  label {
    @extend h2;
    top: -3.5rem;
    transform: none !important;
    color: $gray-darken-3 !important;
    &.active {
      @extend h2;
      top: -3.5rem;
      transform: none !important;
      color: $gray-darken-3 !important;
    }
  }
}
textarea {
  &.md-textarea {
    &.validation-error {
      @extend %error-input;
    }
  }
}
.select2 {
  &:focus {
    outline: none;
  }
  .react-select-container {
    &.lg {
      width: $lgInputWidth;
      @include media-breakpoint-down(lg) {
        width: 100% !important;
      }
      @include media-breakpoint-down(xs) {
        width: 100% !important;
      }
    }
    &.md {
      width: $mdInputWidth;
    }
    &.error {
      .react-select__control {
        border-color: $red !important;
        color: $red !important;
      }
    }
    .react-select__control {
      background-color: $white;
      border-color: $gray !important;
      height: 4rem;

      .react-select__value-container {
        min-width: 3rem;
        font-size: 1.4rem;
        .react-select__input,
        .react-select__placeholder {
          font-size: 1.4rem;
          color: $gray-darken-3;
        }
        > div {
          width: 100%;
          .react-select__input {
            width: 100%;
          }
        }
      }
      &.react-select__control--menu-is-open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .react-select__menu {
      z-index: 3;
      margin-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      background-color: $white;
      box-shadow: none;
      border: 1px solid $gray;
      border-top: none;
      .react-select__menu-list {
        padding: 0;
        .react-select__option {
          cursor: pointer;
          &:last-child {
            border-radius: 0.3rem;
            border-top-right-radius: 0;
            border-top-left-radius: 0;
          }
          &.react-select__option--is-focused {
            background-color: rgba($default, 0.3);
          }
          &.react-select__option--is-selected {
            color: #fff;
            background-color: $default;
          }
          font-size: 1.4rem;
          color: $gray-darken-3;
        }
      }
    }
  }

  &.multi {
    .react-select__control {
      height: auto;
    }
  }

  .react-select__clear-indicator {
    display: none;
  }
  .react-select__value-container--is-multi {
    .react-select__multi-value {
      background-color: transparent;
      width: auto !important;
    }
  }
}

ol.withNumber {
  list-style: none;
  counter-reset: connectedOl;
  li {
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    &:before {
      position: absolute;
      border-radius: 100%;
      display: flex;
      background-color: transparent;
      color: $default;
      top: 2rem;
      font-size: 1.6rem;
      left: -4.3rem;
      width: 0.7rem;
      height: 0.7rem;
      align-items: center;
      justify-content: center;
      content: "";
      z-index: 2;
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
  &.withNumber {
    li {
      &:before {
        border: 1px solid $default;
        width: 3rem;
        left: -4rem;
        top: 1rem;
        height: 3rem;
        counter-increment: connectedOl;
        content: counter(connectedOl);
        z-index: 1;
      }
    }
  }
}
// THIS NEED TO BE IMPLEMENTED IN ANOTHER WAY
.select2 {
  .react-select-container {
    .react-select__control {
      border: 2px;
      border-style: solid;
      border-color: adjust-color($default, $alpha: -0.5) !important;
      opacity: 1;
      .react-select__placeholder {
        opacity: 0.5;
      }
      &--is-focused {
        border: 2px solid $default;
        border-color: $default !important;
        opacity: 1;
      }
    }
  }
}

$smButtonWidth: 10rem;
$mdButtonWidth: 21.2rem;
$lgButtonWidth: 32.7rem;

.btn {
  height: 4rem;
  width: $mdButtonWidth;
  border-radius: 5px;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.btn-sm {
    width: $smButtonWidth;
  }
  &.btn-lg {
    width: $lgButtonWidth;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &.icon {
    img,
    svg {
      position: absolute;
      height: 2rem;
      transition: all $transitionDuration;
    }
    &.icon-left {
      img,
      svg {
        left: 5%;
      }
    }
    &.icon-right {
      svg {
        right: 5%;
      }
    }

    &.btn-sm {
      font-size: 0;
      display: inline-flex;
      justify-content: center;
      &.btn-expand {
        transition: all $transitionDuration;
        &:hover {
          width: $mdButtonWidth;
          @extend %button-dark-text;
          &.icon-left {
            img,
            svg {
              margin-left: 0;
              margin-right: 0;
              left: 5%;
            }
          }
          &.icon-right {
            svg {
              margin-left: 0;
              margin-right: 0;
              right: 5%;
            }
          }
        }
      }
      &.icon-left {
        img,
        svg {
          left: calc(50% - 10px);
        }
      }
      &.icon-right {
        svg {
          right: calc(50% - 10px);
        }
      }
    }
  }

  &.btn-yantra {
    @extend %button-white-text;
    font-family: $mainFont !important;
    background-color: $default !important;
    &.disabled {
      opacity: 1;
      @extend %button-body-1-text;
      background-color: $gray !important;
    }
  }

  &.btn-default {
    @extend %button-white-text;
    background-color: $default !important;

    &.disabled {
      opacity: 1;
      @extend %button-body-1-text;
      background-color: $gray !important;
    }
  }

  &.btn-secondary {
    @extend %button-dark-text;
    background-color: transparent !important;
    border: 2px solid $default;
  }

  &.btn-light-blue {
    @extend %button-white-text;
  }

  &.btn-flat {
    @extend %button-dark-text;
    background-color: transparent !important;
  }

  &.btn-light-green {
    @extend %button-white-text;
  }

  &.btn-info {
    @extend %button-white-text;
  }
}
.btn-secondary:not([disabled]):not(.disabled):active {
  background-color: $default !important;
}
