.success-modal {
  .modal-title {
    font-weight: $fwregular;
    h1 {
      color: #fff;
      font-size: 4rem;
      font-weight: $fwregular;
    }
  }
  .modal-body {
    padding: 3rem 5rem;
    button {
      display: block;
      margin: 0 auto;
    }
  }
}
