.summary-modal {
  .tab-view-summary {
    .download-link {
      flex-direction: column;
      align-items: center;
      .download-icon-summary {
        height: 2.4rem;
        width: 2.4rem;
      }
      a {
        text-decoration: none !important;
        color: #797979;
        font-family: $secondaryFontGibson;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .summary-product-description {
      padding: 3.2rem 0;
      border-top: 1px solid #d9d9d9;
      margin-top: 3.2rem;
    }
    .summary-product-description,
    .summary-detail {
      color: #797979;
      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .product-name-summary {
      color: #404040;
      font-family: $secondaryFontGibson;
      font-size: 32px;
      font-style: normal;
      font-weight: 200;
      line-height: 110%;
    }
    .product-name-summary,
    .summary-detail,
    .description-summary {
      margin-bottom: 8px;
    }
    .summary-detail {
      .summary-detail-value {
        font-weight: 500;
      }
    }
    .description-summary {
      color: #797979;

      font-family: $secondaryFontGibson;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }
    .summary-text-below {
      color: #797979;
      font-family: $secondaryFontGibson;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    .your-design {
      color: #404040;
      font-family: $secondaryFontGibson;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }
    .product-image-summary {
      max-width: 45rem;
    }
    .selected-summary-options {
      display: flex;
      flex-wrap: wrap;
      gap: 3.2rem;
      border-top: 1px solid #d9d9d9;
      padding-top: 4rem;
      border-bottom: 1px solid #d9d9d9;
      padding-bottom: 4rem;
      .selected-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.6rem;
        .selected-option-title {
          height: 3.6rem;
        }
        .selected-option-title,
        .selected-option-value {
          color: #797979;
          text-align: center;
          font-family: $secondaryFontGibson;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          max-width: 11.2rem;
        }
        .selected-option-value {
          color: #404040;
        }
        .selected-option-image-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1.6rem;
          .selected-option-image {
            width: 11.2rem;
            height: 11.2rem;
            border: 1px solid white;
            border-radius: 1.2rem;
            object-fit: cover;
          }
        }
      }
    }
  }

  &.modal-dialog {
    max-width: 67.6rem;
  }
  .summary-header {
    background: #6c808b;
    padding: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 7rem;
    display: flex;
    top: 0;
    z-index: 1001;
    .close-icon {
      cursor: pointer;
      position: absolute;
      top: 1.4rem;
      left: 1.5rem;
    }
    h1 {
      color: white;
    }
  }
  .summary-wrapper {
    z-index: 1000;
    padding: 3.8rem 10rem;
    background-color: rgba(255, 255, 255, 0.95);
    flex-direction: column;
    .close-icon {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      cursor: pointer;
    }
    .options {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-row: 2rem;
      grid-gap: 3rem;
      max-height: 68vh;
      overflow-y: auto;
      .contrastDrawers + .contrastDrawers {
        display: none !important;
      }
      > div {
        > div {
          > p {
            &:first-of-type {
              width: 30%;
            }
          }
          > div {
            &:first-of-type {
              width: 60%;
              margin-left: 2rem;
            }
          }
        }
      }
      img {
        width: 2.5rem;
        height: 2.5rem;
      }
      p {
        font-size: 1.6rem;
        font-weight: 400;
        &.value {
          color: #888888;
          margin-left: 0.8rem;
        }
      }
      h2 {
        text-transform: capitalize;
        color: $default;
        font-size: 2.4rem;
      }
    }
    h1 {
      font-size: 4rem;
      color: $default;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 991px) {
      padding: 3.8rem 1.5rem;
      .options {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
