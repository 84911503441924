@import "./components/Item/cartItem";
@import "./components/MobileItem/mobileItem";
@import "./pages/Submit/Submit.scss";

.react-date-picker {
  .react-date-picker__wrapper {
    display: none;
  }
}

.cart-page {
  max-height: 100%;
  min-height: calc(100vh - 580px);

  .content-container {
    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }
  }
  .discount-caret {
    padding: 0;
  }
}
.cart-items-listing-container {
  .item-container {
    .body-1 {
      // padding: 1rem 0;
      img {
        max-height: 21rem;
        max-width: 21rem;
      }
    }
    .qty-wrapper {
      position: relative;
      border: 0.2rem solid $gray;
      border-radius: 0.5rem;
      width: 9.6rem;
      .qty-click {
        position: absolute;
        top: 1rem;
        img {
          width: 1.5rem;
          height: 1.5rem;
        }
        &.qty-click-minus {
          left: 1rem;
        }
        &.qty-click-plus {
          right: 1rem;
        }
      }
      input {
        &.form-control {
          text-align: center;
          font-size: $fs-16;
          border: none;
          width: 4rem;
          padding: 0;
          margin: 0;
          &:focus {
            border: none;
            box-shadow: none;
          }
        }
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
  textarea {
    &.md-textarea {
      border-color: $gray !important;
      &:focus {
        border: 0.2rem solid $default !important;
        box-shadow: none !important;
      }
    }
  }

  .upload-input-wrapper {
    input {
      &.form-control {
        border: 0.2rem solid $gray;
      }
    }
  }
  .upload-field {
    .add-document-icon {
      @include media-breakpoint-down(xs) {
        display: flex;
        align-self: flex-start;
        margin-top: 1.5rem;
      }
    }
  }
  .uploaded-items {
    .upload-input-wrapper {
      input[value] {
        border: 0.2rem solid $default !important;
      }
    }
    .remove-document-icon {
      @include media-breakpoint-down(xs) {
        display: flex;
        align-self: flex-start;
        margin-right: -0.8rem;
        margin-top: 1rem;
      }
    }
  }
}
button {
  &.icon-left {
    &.remove-all-items {
      img {
        width: 3rem;
        height: auto;
      }
    }
  }
}
//Remove item from Quote Request
.modal {
  .modal-dialog {
    &.remove-from-quote-modal {
      @include media-breakpoint-up(xs) {
        max-width: 56rem;
      }
      p {
        &.modal-heading {
          @include media-breakpoint-up(sm) {
            font-size: 3.6rem;
          }
          @media screen and (max-width: 400px) {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .cart-page {
    div:first-child {
      .pdf_button {
        margin-top: 2rem;
      }
      button {
        text-align: center;
        p {
          margin: auto;
        }
      }
    }
    button {
      &.icon-left {
        &.remove-all-items {
          img {
            left: 4%;
          }
        }
      }
    }
    .cart-page-toolbar {
      flex-direction: column;
    }
  }
}

.finish-fix-image > * {
  padding: 20px;
}
// .finish-fix-cart-image {
//   padding: 20px;
// }
@include media-breakpoint-down(sm) {
  .cart-page {
    .continue-btn-wrapper {
      margin-top: 2rem;
    }
  }
}
@include media-breakpoint-down(xs) {
  .cart-page {
    .item-image-container {
      img {
        max-width: 17rem;
      }
    }
    .upload-field,
    .uploaded-items {
      .upload-input-wrapper {
        flex-direction: column;
        width: 100%;
      }
    }
    .upload-field {
      .label {
        margin-bottom: 1rem;
      }
    }
  }
}

// MINICART QUOTE REQUEST IMAGES SIZE
.item-image-quote {
  &.build-own {
    object-fit: cover;
    width: auto;
    height: 100%;
    max-width: 20rem !important;
  }
}
.item-image-cart {
  &.build-own {
    object-fit: cover;
    width: unset;
    height: 100%;
    max-width: 100% !important;
    max-height: 27rem !important;
  }
}
