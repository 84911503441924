.download-image {
  width: 100%;

  @media screen and (max-width: 756px) {
    display: none;
  }
  img {
    width: 100%;
  }
  .download-image-icon {
    position: absolute;
    right: 2rem;
    top: 2rem;
    background-color: rgba(255, 255, 255, 0.7);
    color: #000;
    padding: 0.4rem;
  }
  &.selected {
    .download-image-icon {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
