.store-locator {
  min-height: 76rem;
  .locator-icon {
    position: absolute;
    top: 25%;
    right: 3%;
    height: 50%;
    cursor: pointer;
  }
  .store-locator-wrapper {
    position: relative;
    .map-inputs-wrapper {
      max-height: 65.7rem;
      border-radius: 5px;
      border-color: $default !important;
      width: 37rem;
      position: absolute;
      margin-top: 5%;
      left: 5%;
      background-color: white;
      z-index: 3;
      border-width: 0.2rem !important;
      transition: all 0.5s ease-in-out;
      overflow: visible;
      .search-container {
        overflow-y: scroll;
        max-height: 47rem;
        transition: all 0.5s ease-in-out;
        &::-webkit-scrollbar {
          width: 0;
        }
        &::-webkit-scrollbar-thumb {
          width: 0;
        }
      }
    }
    .get-direction-content {
      .location-icon {
        width: 1.6rem;
      }
    }
    .search-container {
      transition: all 0.5s ease-in-out;
      &.all-results {
        &::-webkit-scrollbar {
          width: 0.6rem;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $gray;
          width: 0.6rem;
        }
        .view-toggle {
          bottom: 0rem;
        }
      }
      ol {
        list-style: none;
        padding-inline-start: 0;
        &.clicked {
          overflow: auto;
          height: 100%;
        }
        &.not-clicked {
          max-height: 40rem;
          overflow: hidden;
          box-sizing: content-box;
          // &::-webkit-scrollbar {
          //   background: transparent;
          //   position: absolute;
          //   right: 0;
          //   top: 0;
          // }
        }
        &.results-hidden {
          opacity: 0;
          height: 0;
        }
        li {
          &.store-content {
            &::before {
              left: 0;
            }
            .store-content-inner {
              padding: 1.5rem 0 1rem 0;
            }
          }
        }
      }
      .view-toggle {
        transition: all 0.5s ease-in-out;
        background-color: $gray-lighten-4;
        &.results-hidden {
          opacity: 0;
          height: 0;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .store-locator {
    .store-locator-wrapper {
      .map-inputs-wrapper {
        width: 100%;
        position: static;
        margin-bottom: 5rem;
        margin-top: 0;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .store-locator {
    margin-top: 5rem;
    .locator-icon {
      cursor: pointer;
    }
  }
}
