.password-form-wrapper {
  @media screen and (max-width: 480px) {
    h2 {
      &.long-email {
        font-size: 2rem;
      }
    }
  }
  .welcome-back-form {
    .password {
      > div {
        width: 100%;
      }
    }
    .form-lg,
    button {
      width: 100%;
    }
  }
}
