.collection-container {
  .collection-promo-text {
    background-color: rgba(0, 0, 0, 0.3);
  }
  @include media-breakpoint-up(lg) {
    position: relative;
    .collection-promo-text {
      background-color: transparent;
      position: absolute;
      bottom: 12.5%;
      width: 100%;
      p {
        line-height: 1.5;
      }
      .background-mask {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.5;
      }
    }
  }
}
