// Colors
$gray-darken-3: #404040;
$gray-lighten-4: #f7f7f7;
$default: #6c808b;
$secondary: #a8aeb5;
$black: #15191f;
$white: #ffffff;
$light-blue: #85afd3;
$primary: #888888;
$gray: #d8d8d8;
$dark-green: #6c8b89;
$gray-darken-2: #707070;
$light-green: #95b176;
$red: #951a1a;
$border-gray: #dee2e6;
$gray-lighten-5: #9da4ac;

//Fonts
$mainFont: "Yantramanav", sans-serif;
$secondaryFontGibson: "canada-type-gibson", sans-serif;
$fontUtopia: "utopia-std-display", sans-serif;

// Font Weights
$fwthin: 100;
$fwlight: 300;
$fwregular: 400;
$fwmedium: 500;
$fwbold: 700;

// Font sizes
$fs-12: 1.2rem;
$fs-13: 1.3rem;
$fs-14: 1.4rem;
$fs-16: 1.6rem;
$fs-18: 1.8rem;
$fs-20: 2rem;
$fs-22: 2.2rem;
$fs-24: 2.4rem;
$fs-26: 2.6rem;
$fs-28: 2.8rem;
$fs-30: 3rem;
$fs-32: 3.2rem;
$fs-34: 3.4rem;
$fs-36: 3.6rem;
$fs-40: 4rem;
$fs-46: 4.6rem;
$fs-48: 4.8rem;

// Line heights
$lh-16: 1.6rem;
$lh-24: 2.4rem;
$lh-28: 2.8rem;
$lh-30: 3rem;
$lh-32: 3.2rem;
$lh-36: 3.6rem;
$lh-38: 3.8rem;
$lh-40: 4rem;
$lh-42: 4.2rem;
$lh-44: 4.4rem;
$lh-46: 4.6rem;
$lh-56: 5.6rem;

// Animations
$transitionDuration: 0.5s;

// MDB Overrides
$project-default-color: $default;
$project-primary-color: $primary;
$project-black-color: $black;
$project-light-blue: $light-blue;
$project-light-green: $light-green;
$project-info-color: $secondary;
