@import "./components/DownloadButton/DownloadButton";
.quote-btn {
  img {
    width: 4.2rem;
    cursor: pointer;
    margin-right: 1rem;
  }
}
.create-newlist-btn {
  @media screen and (max-width: 480px) {
    max-width: 16rem;
  }
}
.d-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 1.5rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}
.empty-wishlist {
  .empty-wishlist-inner {
    .logo-wrapper {
      a {
        display: flex;
        justify-content: center;
        img {
          &.logo {
            @include media-breakpoint-up(lg) {
              max-width: 80%;
            }
          }
        }
      }
    }
    h2 {
      width: 58%;
      @include media-breakpoint-up(lg) {
        line-height: 4rem;
      }
    }
  }
}
.create-list-icon {
  left: 16% !important;
  @media screen and (max-width: 480px) {
    left: 4% !important;
  }
}
.create-list-modal {
  button {
    &.btn {
      &:first-of-type {
        margin: 0 0.5rem 0 0 !important;
      }
      &:last-of-type {
        margin: 0 0 0 0.5rem !important;
      }
    }
  }
}
//Create project block on Your Project page
.create-project-block {
  .create-new-project {
    .create-new-project-name {
      .md-form {
        width: 100%;
      }
      .text-red {
        margin-top: 0.2rem !important;
      }
    }
  }
}

.modal {
  .modal-dialog {
    &.favorites-modal {
      max-width: 500px;
      .button-holders {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        padding: 11px 15px;
        button {
          width: 100%;
          max-width: 100%;
          display: block;
          margin: 0 auto !important;
        }
      }
      .modal-content {
        max-height: initial !important;
        .modal-content-holder {
          max-height: 510px;
          overflow-y: scroll;
        }
        max-height: 80rem;
        overflow-y: hidden;
        overflow-x: hidden;
        .create-new-project {
          border: 1px solid #6c808b;
          span {
            font-size: 1.2rem;
            color: #6c808b;
          }
        }
        @media screen and (max-width: 1440px) {
          max-height: 65rem;
        }
        @media screen and (max-width: 768px) {
          max-height: 55rem;
        }
      }
    }
    &.favorites-item-modal {
      .favorites-modal-bigger-title {
        .modal-heading {
          @media screen and(max-width:390px) {
            font-size: 2rem;
          }
          @media screen and(max-width:345px) {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .favorite-action,
  .favorite-action > div {
    flex-direction: column;
    align-items: center;
    width: 100%;
    button,
    .grow {
      width: 100%;
    }
  }
  .modal-dialog-centered {
    &.modal-dialog {
      &.favorites-modal {
        .button-holders {
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .favorites-toolbar {
    overflow: scroll;
    a {
      white-space: nowrap;
    }
  }
  .favorite-action {
    button {
      width: 100%;
      > div {
        left: 25% !important;
      }
    }
  }
}

@media screen and(max-width:375px) {
  .favorite-action {
    button {
      > div {
        left: 20% !important;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .create-new-project {
    width: 100% !important;
    .create-new-project-form {
      // padding: 0 2rem;
      .create-new-project-name {
        width: 100%;
        .md-form {
          width: 100%;
          input {
            width: 100% !important;
          }
        }
      }
      .create-btn {
        width: 100% !important;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .empty-wishlist {
    .empty-wishlist-inner {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}
