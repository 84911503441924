@import "./arModal/arModal";
.ar-button {
  position: absolute !important;
  bottom: auto;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include media-breakpoint-down(md) {
    bottom: auto;
    top: 8%;
  }
}
.animation-container {
  position: absolute;
  bottom: 1.2rem;
  left: 80px;
  @include media-breakpoint-down(md) {
    bottom: 0;
    left: 0;
    background: transparent;
    width: auto;
    padding: 0;
    box-shadow: 0 0.35rem 0.6rem #0000005d;
    margin-left: 0;
    width: 100%;
    padding: 0 1.5rem;
    justify-content: space-between;
  }

  .animation-button {
    padding: 5px 10px;
    border: 2px $default solid;
    border-radius: 5px;
    font-size: 16px;
    color: $default;
    text-align: center;
    font-weight: 500;
    transition: 0.1s all linear;
    cursor: pointer;
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0 !important;
      max-width: 10rem;
    }

    &.animation-completed {
      background: $default;
      color: #fff;
    }

    img {
      margin-right: 10px;
    }
  }
}
.plus-sign {
  margin-left: 1.5rem;
  img {
    width: 4rem;
    height: 4rem;
  }
}
