@import "./toggle-menu/toggle-menu";

body {
  &.scroll-lock {
    touch-action: none;
    -ms-touch-action: none;
    overflow: hidden !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    #root {
      .hamburger-button__button {
        > span {
          background-color: $white !important;
        }
      }
    }
  }
}
#mobile-header {
  padding: 0;
  width: 3rem;
  height: 3rem;
  box-shadow: none;
  cursor: pointer;
  &.active {
    z-index: 1;
    width: 3rem;
    height: 3rem;
    right: 0;
    position: absolute;
  }

  #mobile-navigation-container {
    z-index: 10;
    padding: 0;

    .mobile-left,
    .mobile-middle,
    .mobile-right {
      flex: 1 0;
    }

    .mobile-left {
      &.hamburger-menu {
        #nav-icon1 {
          background-color: transparent;
          width: 3rem;
          height: 3rem;
          top: 0;
          span {
            background: $black !important;
            height: 0.1rem;
            &:nth-child(1) {
              top: 7px;
            }
            &:nth-child(2) {
              top: 16px;
              max-width: 65%;
              transform: translate(25%, 0);
            }
            &:nth-child(3) {
              top: 25px;
            }
          }
        }
        .hamburger-button__checkbox:checked {
          + #nav-icon1 {
            span {
              &:nth-child(1),
              &:nth-child(3) {
                top: 16px;
              }
            }
          }
        }
      }
    }
    .mobile-right {
      &.hamburger-menu {
        #nav-icon1 {
          background-color: $white;
          width: 100%;
          height: 100%;
          top: 0;
        }
      }
      #mini-cart-icon {
        margin-left: 1rem;
        .jolaico-cart {
          font-size: 16px;
        }
      }
      .jolaico-search {
        font-size: 16px;
      }
    }
  }
  #mobile-navigation {
    flex-direction: column;
    overflow: visible !important;
    position: fixed;
    left: -99.9rem;
    top: 0;
    display: flex;
    transition: all 0.5s ease-in-out;
    z-index: 9;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
    // padding-bottom: 10rem;
    &.is-open {
      left: 0;
      background-color: transparent;
      padding: 0;
    }
    .mobile {
      &-menu-storelocator,
      &-menu-favorites {
        padding: 0;
        display: flex;
      }
    }
    .navbar-collapse {
      position: relative;
      box-shadow: 5px 0 5px 0px #888;
      // background-image: linear-gradient(
      //   139deg,
      //   #f9f9f9 30%,
      //   #ffffff 60%,
      //   #eeeeee 98%
      // );
      background-color: $gray-lighten-4;
      width: 87%;
      height: 100%;
      margin: 0;
      overflow: hidden; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
      .dropdown {
        // padding: 2.4rem 0 2.4rem 3rem;

        border-bottom: 1px solid $default;

        &:hover {
          background-color: #f2f0f0;
        }
      }
      #navigation-menu-toggles {
        background-color: #ccc;
        padding: 4rem 0 1rem 3rem;
        .menu-toggle {
          margin-bottom: 3rem;
          display: flex;
          svg {
            margin-right: 1.8rem;
          }
        }
        .mobile {
          &-storelocator {
            order: 3;
          }
          &-info {
            order: 4;
          }
          &-account {
            order: 1;
          }
          &-signout {
            order: 5;
            padding-left: 4rem;
          }
          &-login {
            order: 1;
            margin-bottom: 3rem;
          }
          &-favorites {
            order: 2;
          }
        }
        .login {
          .distancer {
            padding: 0 0.4rem;
          }
        }
      }
    }

    .menu-header-link {
      padding: 2rem;
      &.active {
        border-bottom: 5px solid $default;
      }
    }
    .menu-header-link:first-of-type {
      border-right: 1px solid $gray;
    }

    .header-menu-container {
      border-bottom: 1px solid $default;
    }
  }
}

//custom css
.header-mobile-container {
  // height: 6rem;
  z-index: 100;
  position: sticky;
  top: 0;
  background-color: white;
  .mobile-menu-container {
    &.active {
      justify-content: flex-end !important;
      height: 6rem;
    }
    .bottom-mobile-block {
      position: absolute;
      bottom: 0;
      height: 100;
    }
  }
}
.mobile-middle-block {
  background: $gray-darken-3;
  max-height: 100%;
  padding-top: 1rem;
  padding-bottom: 3rem;
  overflow: auto;
  .mobile-links-block {
    padding: 2rem;
    overflow: auto;
    height: 400px;
  }
}
.mobile-bottom-block {
  background: $default;
  padding: 1rem 0;
}

#mobile-header {
  .navbar-nav {
    .nav-item {
      border-bottom: 1px solid $default !important;
    }
  }

  .dropdown-toggle.nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    padding-right: 1.5rem;
    .link-mobile {
      line-height: normal;
      font-size: 14px;
      flex: 1 1 100%;
      padding: 2.4rem 0 2.4rem 3rem;
    }
    span {
      flex: 1 1 auto;
      text-align: right;
    }
    &:after {
      content: none;
    }
  }
}

@include media-breakpoint-up(xs) {
  #mobile-header {
    #mobile-navigation {
      .navbar-collapse {
        overflow-y: scroll;
        .bottom-mobile-block {
          position: relative;
        }
        .dropdown {
          .dropdown-toggle.nav-link {
            a {
              font-size: 1.4rem;
              padding: 1.4rem 0 1.4rem 3rem;
            }
          }
        }
      }
      .mobile-middle-block {
        align-items: flex-start;
        height: 100%;
        overflow: auto;
        height: auto;
      }
    }
  }
  .bottom-mobile-block {
    bottom: 0;
    .nav-link {
      padding: 0;
    }
    .body-3 {
      font-size: 1rem;
    }
  }
  .mobile-middle-block {
    .mobile-links-block {
      padding: 1rem 0.5rem;
      a {
        font-size: 1.4rem;
        padding: 0.2rem 1rem;
        .body-3 {
          font-size: 1.7rem;
        }
      }
    }
  }
  #mobile-header {
    .dropdown-toggle.nav-link {
      .link-mobile {
        font-size: 1.4rem;
        padding: 1.4rem 0 1.4rem 3rem;
      }
    }
  }
}
//zoom ios fix
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .header-mobile-container {
    .mobile-menu-container {
      .bottom-mobile-block {
        position: sticky;
      }
    }
  }
  #mobile-header {
    #mobile-navigation {
      .navbar-collapse {
        overflow: scroll;
      }
    }
  }
}
