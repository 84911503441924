.airtable-embed {
  aspect-ratio: 1 / 2.4;
  height: auto;
  background-color: transparent;
}

.to-the-trade-content {
  background-color: #f7f7f7;
}

@media screen and (max-width: 1440px) {
  .airtable-embed {
    aspect-ratio: 1 / 12;
  }
}
@media screen and (max-width: 768px) {
  .airtable-embed {
    aspect-ratio: 1 / 13;
    width: 100%;
  }
}
