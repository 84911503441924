.homepage-vdc-wrapper img {
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .homepage-vdc-wrapper .pagebuilder-mobile-only {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .homepage-vdc-wrapper .view img {
    max-height: 40rem;
    object-fit: contain;
    width: 100%;
  }
  // .pagebuilder-mobile-hidden {
  //   display: none !important;
  // }
}
