@import "./components/step/LiveEdgeModal/liveEdgeModal.scss";
.steps-container-pdp {
  // box-shadow: rgba(0, 0, 0, .2) 0 6px 4px;
  // border: 2px $default solid;
  // border-radius: 0.25rem;
  // margin-bottom: 50px;
  // min-width: unset !important;

  .selector-container {
    margin: 10px 0;
    display: inline-block;
    width: calc(100% - 15px);
    margin-left: 15px;
    cursor: initial;
    .selected-option {
      border: 1px $border-gray solid;
      border-radius: 2px;
      height: 40px;
      padding: 0 15px;
      cursor: pointer;
    }
    .selector-title {
      padding: 0 15px;
      font-weight: 300;
      color: $gray-darken-3;
      line-height: 0;
      padding-left: 2.5rem;

      &:first-line {
        line-height: 30px;
      }
    }
    @media screen and (max-width: 991px) {
      min-width: auto;
      margin-left: 0px;
      width: 100%;
    }
  }
  > div {
    // padding: 10px !important;
    margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .selector-container {
    .selector-title {
      float: left;
      max-width: 32%;
      line-height: 15px;
      height: 45px;
      overflow: hidden;
    }

    .selected-option {
      float: right;
      width: 67%;
    }
  }
}

.base-options-container {
  @media screen and (min-width: 992px) {
    .monogram-col > div {
      height: 25rem;
      display: flex;
      align-items: center;
      img {
        height: auto !important;
      }
    }
  }
  .md-form {
    width: 100%;
  }
}

.base-options-container .search-input ~ img {
  position: absolute;
  top: 1.1rem;
  right: 1.5rem;
}
