.mission-vision-values-container {
  padding: 10rem 0;
  .heading {
    font-size: 18rem;
    line-height: 18rem;
    font-family: $fontUtopia;
    text-align: center;
    letter-spacing: 1rem;
  }
  .title {
    margin-bottom: 10rem;
  }
  .subtitle {
    text-align: center;
    font-size: 3.8rem;
    color: $gray-darken-3;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 100;
    font-size: 2.8rem;
    font-family: $secondaryFontGibson;
    text-align: center;
  }
  .statement {
    margin-bottom: 6rem;
  }
  .values-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .core-value {
      display: flex;
      flex-direction: column;
      padding: 0 3rem;
    }
    .br-1 {
      border-right: 1px solid black;
    }
  }

  .signature-wrapper {
    margin-top: 15rem;
    display: flex;
    div {
      display: flex;
      justify-content: center;
    }
    img {
      max-width: 45rem;
    }
  }
}

.mission-hero-wrapper .hero-banner-row .pagebuilder-column-group {
  bottom: 0;
}

@media screen and (max-width: 1440px) {
  .mission-vision-values-container {
    padding: 5rem 1rem;
    .heading {
      font-size: 5rem;
      line-height: 6rem;
    }
    .title {
      margin-bottom: 6rem;
    }
    .subtitle {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.6rem;
    }
    .signature-wrapper {
      gap: 2rem;
      padding: 0 3rem;
      margin: 10rem 0;
    }
  }
}
