.forgot-password-page-wrapper {
  min-height: 600px;
  .forgot-password {
    .md-form {
      width: 100%;
    }
    &.first {
      .text-red {
        bottom: -0.5rem;
      }
    }
    &.third {
      .md-form {
        margin-bottom: 0.5rem;
      }
    }
  }
  .short-margin {
    margin-left: 30px;
  }
}
