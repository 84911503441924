@import "./fabricMediaGallery/fabricMediaGallery";
@import "./info/info";
@import "./fabricPdpToolbar/toolbar";

.colorways-container {
  max-width: 1200px;
  margin-top: 6rem;
  margin-bottom: 17rem;
  .colorways-slider {
    &.slide-max-width {
      .slick-slide {
        max-width: 25.55rem;
      }
    }
  }
  .slick-arrow {
    top: 32%;
    &:before {
      background-image: url("../../../../../app/assets/icon/leftMobile.svg");
    }
    &.slick-prev {
      left: -55px;
    }
    &.slick-next {
      right: -63px;
    }
    @media screen and (max-width: 1500px) {
      top: 100%;
      &.slick-prev {
        left: 13px;
      }
      &.slick-next {
        right: 5px;
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 15px;
      &.slick-next {
        right: 0px;
      }
    }
  }
}

.fabric-pdp-skeleton-wrapper {
  .fabric-pdp-skeleton-image-and-details-wrapper {
    .fabric-pdp-skeleton-slider {
      padding-top: 7rem;
    }
  }
  .fabric-pdp-skeleton-action-bar {
    width: 105vw;
    position: relative;
    left: calc(-52vw + 50%);
    padding-top: 9.1rem;
  }
  .fabric-pdp-skeleton-colorways {
    .fabric-pdp-skeleton-colorways-title {
      padding-top: 5.85rem;
      padding-bottom: 2.5rem;
    }
    .fabric-pdp-skeleton-colorways-items {
      margin: 0 -3rem;
      padding-left: 0;
      padding-right: 0;
      .fabric-pdp-skeleton-colorways-item {
        padding: 0;
      }
    }
  }
}
