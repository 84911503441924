@import "./components/downloads/downloads";
@import "./components/summary/summary";

.toolbar-wrapper {
  position: sticky;
  background: $gray-lighten-4;
  border-bottom: 3px solid $default;
  width: 100%;
  z-index: 2;
  display: inline-block;

  .toolbar-container {
    padding: 10px 30px;
    display: inline-block;
    width: 100%;
    @media screen and (max-width: 768px) {
      padding: 10px 15px;
    }

    .toolbar-breadcrumbs {
      display: none;
      > * {
        float: left;
      }
      a {
        font-size: 12px !important;
        line-height: 20px !important;
      }
    }

    .toolbar-left {
      display: inline-block;



      .toolbar-second-row {
        > * {
          float: left;
          padding: 0 15px;
        }

        .product-sku {
          // font-size: 1.6rem;
          line-height: 2rem;
          padding-left: 0;
        }
      }
      .custom-name {
        // font-size: 12px;
        margin-bottom: 0;
        div {
          margin-bottom: 0;
        }
      }
      @media screen and (max-width: 991px) {
        width: 100%;
        .toolbar-second-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .available-in-container {
            display: flex;
            align-items: center;
            img {
              margin: 0 0.5rem;
            }
            p {
              margin-bottom: 0;
              margin-right: 0.5rem;
              font-size: 1.4rem;
              color: #404040;
              font-weight: 400;
            }
          }
        }
      }
    }

    .toolbar-right {
      display: flex;
      align-items: center;
      // width: 100%;
      .price-stock {
        @media screen and (max-width: 991px) {
          justify-content: space-between;
          width: 100%;
        }
      }
      .buttons-wrapper {
        display: flex;
        align-items: center;
      }
      .build-summary {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 1.5rem;
        @media (max-width: 768px) {
          margin: 0 15px 0 0;
        }
        p {
          margin-bottom: 0;
          text-decoration: underline;
          margin-left: 0.8rem;
          width: 50%;
        }
      }
      // > * {
      //   float: left;
      // }

      .product-price {
        font-size: 2.4rem;
        color: $default;
        margin-top: 15px;
      }

      .toolbar-button {
        text-transform: capitalize;
        cursor: pointer;
        height: 4rem;
        border-radius: 5px;
        line-height: 4rem;
        text-align: center;
        transition: all 0.15s ease-in-out;
        margin-top: 15px;

        &:hover {
          outline: 0;
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
            0 4px 15px 0 rgba(0, 0, 0, 0.15);
        }

        &.quote-request-button {
          background: $default;
          font-weight: 300;
          font-size: 1.4rem;
          color: #fff;
          width: 100%;
          position: relative;

          img {
            position: absolute;
            top: 10px;
            left: 10px;
          }
        }

        &.favorite-button {
          width: 10rem;
          border: 2px solid $default;
          background: transparent;
          margin-left: 15px;
          margin-top: 0;
          // display: none;

          img {
            margin: 0 auto;
          }
        }
      }

      .downloads {
        width: 100%;
        display: flex;
        justify-content: space-between;
        a {
          line-height: 25px;
        }

        img {
          vertical-align: super;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .toolbar-wrapper {
    .toolbar-container {
      .toolbar-right {
        .product-price {
          line-height: 40px;
        }

        .toolbar-button {
          &.quote-request-button {
            width: 21.2rem;
            margin: 15px 20px 0;
          }
        }

        .downloads {
          width: auto;

          > * {
            margin: 0 10px;
            float: left !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .toolbar-wrapper {
    height: 12rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .toolbar-wrapper {
    height: 12rem;
    .toolbar-container {
      padding: 10px 30px !important;
    }
  }
}

@media screen and (min-width: 992px) {
  .toolbar-wrapper {
    // height: 100px;
    .toolbar-container {
      padding: 10px 90px;

      .toolbar-breadcrumbs {
        display: inline-block;
      }
      .down-part-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .toolbar-left {
        // float: left;
      }

      .toolbar-right {
        // float: right;
        // width: auto;

        .product-price {
          margin-top: calc((34px - 2.4rem) / 2);
        }

        .toolbar-button {
          &.favorite-button {
            display: block;
            margin-top: calc((48px - 4rem) / 2);
            margin-left: 20px;
          }

          &.quote-request-button {
            margin: calc((48px - 4rem) / 2) 0 0 20px;
          }
        }

        .downloads {
          margin-left: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .toolbar-right {
    flex-direction: column;
    align-items: baseline !important;
    .product-price {
      margin-bottom: 2rem;
    }
    .buttons-wrapper {
      width: 100%;
      margin-bottom: 2rem;
      .action-buttons {
        .quote-request-button.toolbar-button {
          margin-top: 0;
        }
      }
      .toolbar-button {
        margin-top: 0;
      }
    }
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      > div {
        &:first-of-type {
          flex: 3;
        }
        &:last-of-type {
          flex: 1;
        }
      }
      .save-button {
        width: 10rem;
        border: 0.2rem solid #6c808b;
        background: transparent;
        cursor: pointer;
        height: 4rem;
        border-radius: 0.5rem;
        margin-top: 1.5rem;
        line-height: 4rem;
        text-align: center;
        transition: all 0.15s ease-in-out;
        margin-left: 1rem;
      }
    }
  }
}
