@import "./Listing//listing";
@import "./Table//table";

.grade-container {
  .tab.horisontal-tab:before {
    width: 30%;
  }
  .vendor-list-btn {
    max-width: 328px;
    height: 40px;
  }
  .selected-vendor {
    padding: 15px 0;
    border-top: 1px solid #a8aeb5;
    border-bottom: 1px solid #a8aeb5;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 73px;
      margin-right: 10px;
    }
    p {
      font-size: 16px;
      color: #888888;
      margin-bottom: 7px;
    }
  }
  .multi-select-filter-options {
    box-shadow: none;
    padding-top: 32px;
    border-top: 1px solid #00000029;
    border-bottom: 1px solid #00000029;
    .slick-slide > div > div > div {
      width: 120px;
    }
    .slick-arrow {
      top: 42%;
      &.slick-next {
        transform: translate(-50%, 0) rotate(-360deg);
        right: 0 !important;
      }
      &::after,
      &::before {
        display: none;
      }
    }
  }
  .filter-pattern {
    padding: 15px 0px 0px;
    background: white;
    border: 1px solid #ccc;
    position: absolute;
    width: auto;
    max-width: 100%;
    min-width: 313px;
    z-index: 1;
    border-radius: 0px 0px 5px 5px;
    p {
      padding: 5px 25px;
      font-size: 14px;
      &.data {
        cursor: pointer;
        &:hover {
          background-color: #6c808b;
          color: white;
        }
      }
    }
  }

  .grade-bottom-toolbar {
    > .row {
      width: 100%;
    }
  }
  .btn-secondary:not([disabled]):not(.disabled):active {
    background-color: white !important;
    color: #6c808b;
  }
  .filters-part {
    padding: 30px 0px;
    .filter-container {
      max-width: 1000px;
      .search-wrapper {
        width: 100%;
        position: relative;
        margin: 0;
        input {
          padding: 11px 67px;
        }
        img {
          position: absolute;
          top: 50%;
          left: 5%;
          bottom: 50%;
          transform: translate(-50%, -50%);
        }
      }
      margin: 0 auto;
      .select2 .react-select-container.lg {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .grade-container {
    .vendor-list-btn {
      max-width: 100%;
    }
    .filters-part {
      .filter-container {
        max-width: 100%;
        .search-wrapper {
          margin: 15px 0;
        }
      }
    }
    .logo-data-wrapper {
      .logo-info-box {
        padding: 11px 0px;
        a {
          margin-bottom: 8px;
        }
      }
    }
    .btn {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .grade-container {
    .logo-data-wrapper {
      .logo-info-box {
        a {
          min-height: 40px;
        }
      }
    }
  }
}
