.careers-page-wrapper {
  a {
    font-size: 1.6rem;
  }
  .careers-image-and-text-wrapper {
    .view {
      p {
        margin-bottom: 0;
      }
      .mask {
        background-color: #15191f;
        opacity: 0.3;
      }
    }
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      br {
        display: none;
      }
      color: #ffffff;
      max-width: 113rem;
      position: absolute;
      font-size: 10rem;
      font-weight: 100;
      line-height: 9.2rem;
    }
  }

  .view-opportunities-button {
    // margin-left: calc((100% - 212px) / 2);
    // margin-top: 20px;
    margin: 3rem 0;
  }
}

@media screen and (max-width: 1440px) {
  .careers-page-wrapper {
    .careers-image-and-text-wrapper {
      h1 {
        max-width: 92rem;
        font-size: 8rem;
        line-height: 7.2rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .careers-page-wrapper {
    .careers-image-and-text-wrapper {
      h1 {
        max-width: 28.4rem;
        font-size: 4rem;
        line-height: 3.2rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .careers-page-wrapper {
    .careers-image-and-text-wrapper {
      img {
        height: 47.5rem;
        object-fit: cover;
        object-position: left;
      }
      h1 {
        br {
          display: inline;
        }
        max-width: 33.5rem;
        font-size: 4rem;
        line-height: 3.2rem;
      }
    }
  }
}
