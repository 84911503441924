.dropdown-items {
  position: absolute;
  left: 100%;
  width: fit-content;
  min-width: 100%;
  border-radius: 8px;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in, visibility 0.3s ease-in,
    margin-top 0.3s ease-in;
}

.dropdown-items.hoveredFirstLevel {
  margin-top: -30px;
}
