.label {
  display: block;
  .label-text {
    &.required:after {
      content: "*";
      font-weight: $fwmedium;
      color: $red;
      font-size: 1.2rem;
      position: relative;
      top: -1.2rem;
      right: -0.4rem;
    }
    & * {
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
  }
}
