@include media-breakpoint-up(xl) {
  .first-column {
    .imageloader {
      height: 100%;
      .img-collection {
        height: 100%;
        min-height: 30rem;
        object-fit: cover;
      }
    }
  }
}
.second-column {
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    max-height: 7rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.collection-items {
  .second-column {
    p {
      color: #83939c !important;
      font-weight: 400;
    }
  }
  .first-column {
    height: fit-content;
    .img-collection {
      height: 100%;
      min-height: 20rem;
      height: 20rem;
      object-fit: cover;
    }
  }
  .collection-image-wrap:hover {
    .grid-btn-wrap {
      background-color: rgba(255, 255, 255, 0.5);
    }
    .grid-view-button {
      display: block;
    }
  }
  .grid-btn-wrap {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease;
  }
  .grid-view-button {
    display: none;
  }
}
.collection-tearsheet-link {
  text-decoration: underline !important;
  text-decoration-color: #e4e8e9 !important;
  font-size: 1.8rem;
  img {
    margin-right: 1rem;
  }
}
