.room-scenes-container {
  margin-top: 5rem;
  .gallery-items-wrapper {
    grid-column-gap: inherit;
    .slick-track {
      display: flex;
      align-items: center;
    }
    #cmsblock-room_scene_pdp {
      height: 100%;
    }
    // .gallery-images {
    //   display: flex;
    //   position: absolute;
    //   flex-wrap: wrap;
    //   bottom: 4rem;
    //   z-index: 10;
    //   flex-wrap: wrap;
    //   max-height: 10rem;
    //   justify-content: center;
    //   width: calc(100% - 3rem);

    //   > div {
    //     width: 100%;
    //     text-align: center;
    //     .slick-slide img {
    //       max-width: 8.6rem;
    //     }
    //   }
    //   img {
    //     width: 8.6rem;
    //     height: 8.6rem;
    //     margin: 0 0.8rem 1.5rem;
    //     border: 1px solid #707070;
    //     cursor: pointer;
    //   }
    // }
    .room-scenes-slider {
      position: relative;
      .slick-arrow {
        top: 35%;
        &.slick-prev {
          left: -35px;
          &:before {
            opacity: 1;
            content: "";
            background-image: url("../../../../../app/assets/icon/leftMobile.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        &.slick-next {
          right: -65px;
          &:before {
            // transform: rotate(-180deg);
            opacity: 1;
            content: "";
            background-image: url("../../../../../app/assets/icon/leftMobile.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        @media screen and (max-width: 1500px) {
          top: 100%;
          &.slick-prev {
            left: 43%;
          }
          &.slick-next {
            right: 40%;
          }
        }
        @media screen and (max-width: 1050px) {
          top: 100%;
          &.slick-prev {
            left: 40%;
          }
          &.slick-next {
            right: 36%;
          }
        }
        @media screen and (max-width: 425px) {
          &.slick-prev {
            left: 15%;
          }
          &.slick-next {
            right: 3%;
          }
        }
      }
      // .slick-arrow {
      //   top: 50%;
      //   &.slick-prev {
      //     left: 8%;
      //   }
      //   &.slick-next {
      //     right: 2%;
      //   }
      // }
    }
    .gallery-item {
      img.our-installation-img {
        height: 100%;
      }
      .imageloader {
        width: calc(100% - 1.5rem);
      }
    }
    @media screen and (max-width: 768px) {
      .fixed-bottom {
        display: none;
      }
      .gallery-images {
        bottom: 1rem;
      }
    }
  }
}

.gallery-modal-popup {
  max-width: 137rem;
}
