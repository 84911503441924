.password-form-wrapper {
  @media screen and (max-width: 480px) {
    h2 {
      &.long-email {
        font-size: 2rem;
      }
    }
  }
  .welcome-back-form {
    .mb-35 {
      margin-bottom: 35px !important;
    }
    .text-red {
      bottom: -20px;
      &.bottom-15 {
        bottom: -15px;
      }
      &.ellipsis {
        white-space: normal;
        @media screen and (max-width: 576px) {
          bottom: 0;
          top: 38px;
          margin: 5px 0 30px;
          overflow: visible;
        }
      }
    }
    .form-lg,
    button {
      width: 100%;
    }
  }
}
