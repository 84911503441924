.menu-top-container {
  @media screen and (min-width: 1300px) {
    max-width: 1300px;
  }
  .menu-top-item {
    .dropdown-menu {
      border-radius: 8px;
    }
    position: relative;
    .dropdown-parent {
      // display: inline-block;

      a {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        &:hover {
          text-decoration: underline !important;
          img {
            filter: brightness(0) saturate(100%) invert(46%) sepia(27%)
              saturate(246%) hue-rotate(157deg) brightness(99%) contrast(91%);
          }
        }
      }

      // .dropdown-items {
      //   top: 0;
      // }

      &:hover {
        > .dropdown-items {
          visibility: visible;
          opacity: 1;
          // top: 0;
        }
      }
    }

    .btn-secondary {
      border: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
      width: fit-content;
    }
    &.dropdown {
      .nav-link {
        padding: 0.8rem 2rem;
        text-transform: uppercase;
      }

      .dropdown-toggle:focus,
      .dropdown-toggle:hover,
      .dropdown-item:hover,
      .dropdown-item:focus,
      .btn-secondary.dropdown-toggle {
        background-color: transparent !important;
      }
      &:hover > .dropdown-menu {
        // display: block;
        opacity: 1;
        visibility: visible;
      }
      .dropdown-menu {
        // left: -25% !important;
        transform: none !important;
        top: 4.5rem !important;
        display: block;
        visibility: hidden;

        opacity: 0;
        transition: all 0.3s ease-in;
        .nav-link {
          color: #404040;
          font-family: Yantramanav;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .dropdown-toggle {
        &:active {
          /*Without this, clicking will make it sticky*/
          pointer-events: none;
        }
        &::after {
          display: none;
        }
      }
    }

    h2 {
      position: relative;
      font-weight: 400;
      /*Media Queries*/
      @media screen and (min-width: 1440px) {
        font-size: $fs-14;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 2rem;
        left: calc(50% - 1rem);
        height: 0.2rem;
        background-color: $default;
        opacity: 0;
        transition: opacity $transitionDuration ease-in-out;
      }
    }
    &.active,
    x &:hover {
      h2 {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .custom-options-menu {
    width: 25rem;
    top: 100%;
    box-shadow: 0 0.8rem 0.6rem #00000064;
    right: 13%;
    border-radius: 0 0 1rem 1rem;
    transition: all 0.65s ease-in-out;
    z-index: -1;
    /*Media Queires*/
    @media (max-width: 1580px) {
      right: 10%;
    }
    @media (max-width: 1310px) {
      right: 8%;
    }
    &.hide {
      transform: translateY(-80rem);
      opacity: 0;
      .custom-options-content {
        opacity: 0;
      }
    }
    .custom-options-content {
      padding-top: 3.5rem;
      padding-bottom: 3rem;
      a {
        h2 {
          /*Media Queries*/
          @media screen and (min-width: 1440px) {
            font-size: $fs-14;
          }
        }
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
