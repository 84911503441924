@import "./searchInput/searchInput";
body {
  &.search-open {
    //overflow: hidden;
  }
}
.page-footer {
  padding-bottom: env(safe-area-inset-bottom);
  position: sticky;
  bottom: 0;
}
