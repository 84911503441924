@import "./contactForm/contactForm";
.contact-us {
  .text-with-icon {
    &:before {
      display: block;
      width: 2rem;
      height: 2rem;
      margin-top: 0.5rem;
    }
    &.address {
      &:before {
        content: "";
        background: url("../../../assets/icon/address.svg") no-repeat;
      }
    }
    &.phone {
      &:before {
        content: "";
        background: url("../../../assets/icon/phone.svg") no-repeat;
      }
    }
    &.fax {
      &:before {
        content: "";
        background: url("../../../assets/icon/fax.svg") no-repeat;
      }
    }
    &.email {
      &:before {
        content: "";
        background: url("../../../assets/icon/email.svg") no-repeat;
      }
    }
  }
}
