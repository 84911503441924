.category-widget-container {
  .category-widget {
    position: relative;
    .category-widget-content {
      position: absolute;
      transition: all $transitionDuration ease-in-out;
      bottom: -15%;
      right: 0;
      left: 0;
      margin: 0 auto;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.3);
      padding: 1rem;

      .separator {
        transition: all $transitionDuration ease-in-out;
        width: 4rem;
      }

      .category-widget-button-container {
        transition: all $transitionDuration ease-in-out;
        opacity: 0;
      }
    }

    &:hover {
      .category-widget-content {
        bottom: 0%;
        .separator {
          width: 100%;
        }
        .category-widget-button-container {
          opacity: 1;
        }
      }
    }
  }
}
