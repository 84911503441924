@import "./components/Express/express";
.login-modal {
  width: 450px;
  max-width: 90%;
  z-index: 10;
  @import "./components/WelcomeBack/welcomeBack";
}
//Style for modal when you try to add some product in your wishlist as a guest
@media screen and (min-width: 768px) {
  .modal-dialog.favorites {
    width: 500px;
    margin: 8rem auto;
  }
}
.modal-dialog {
  &.favorites {
    .password-form-wrapper {
      .welcome-back-form {
        .text-red {
          bottom: 0;
        }
      }
    }
  }
}
.password-form-wrapper {
  .wlecome-holder {
    width: 360px;
    margin: 0 auto;
    button.btn-default {
      max-width: 100%;
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .modal-dialog.favorites {
    width: auto;
    .bg-gray-lighten-4.pb-5 {
      width: 100% !important;
    }
  }
  .password-form-wrapper {
    .wlecome-holder {
      width: 100%;
    }
  }
}

.pwa-modal {
  .mt-4.mb-5.mx-4.px-5 {
    padding: 0 !important;
  }
  .mt-5.flex-grow-1.d-flex.flex-column {
    .mx-5.px-2 {
      margin: 0 !important;
    }
  }
}

.favorites-subtitle {
  h3 {
    font-size: 16px;
    color: black;
    span {
      color: #6c808b;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.favorites {
  h4 {
    &.favorites-bottom-message {
      font-size: 16px;
      font-weight: normal;
    }
    span {
      color: #6c808b;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
@include media-breakpoint-down(xs) {
  .account-container {
    h3 {
      font-size: 2.4rem;
    }
    .md-form {
      width: 100% !important;
      label {
        font-size: 1.6rem !important;
      }
    }
  }
}
