@import "./collectionsListing/collectionListingItem/collectionListingItem";
@import "./collectionCustom/collectionCustom";
@import "./collectionNotCustom/collectionNotCustom";
.collections-container {
  .plp-collection-filters {
    padding: 0 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
    input {
      margin-top: 0 !important;
    }
    .select2 {
      width: 25rem;
    }
  }
  .title {
    font-size: 4rem;
  }
  .second-column {
    .outer-wrapper {
      background-color: $gray-lighten-4;
      span {
        &.description {
          line-height: 2.4rem;
        }
      }
    }
  }
  .collection-items {
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, 1fr);
    }
    column-gap: 3rem;
    .second-column {
      .outer-wrapper {
        background-color: unset;
      }
    }
  }
}
.img-full {
  img {
    width: 100%;
  }
  p {
    line-height: 2.4rem;
    @include media-breakpoint-down(xs) {
      line-height: 1.7rem;
      font-size: $fs-13;
    }
  }
}
