.related-slider {
  .slick-list {
    margin: 0 -9px;
  }
  .slick-slide {
    // padding: 0 9px;
    .product-img-wrapper {
      height: 25rem;
      width: 100%;
      margin: 0 auto;
    }
    .content-product {
      max-height: 11rem;
      .product-name {
        height: 4rem;
      }
    }
    img {
      &.img-fluid {
        width: 100%;
        height: auto;
      }
    }
  }
}
.fabric-info {
  .vendor-logo {
    max-height: 7rem;
  }
  .custom-collapse {
    p {
      line-height: 2.1rem;
    }
    .col-lg-11,
    .col-10 {
      flex: 0 0 83.33333% !important;
      max-width: 83.33333% !important;
    }
    .col-lg-1,
    .col-2 {
      flex: 0 0 16.66667% !important;
      max-width: 16.66667% !important;
    }
  }

  h4 {
    font-size: 14px;
  }
  .attribute-name {
    h4 {
      color: #6c808b;
    }
  }
  .colorways-clickable-section {
    .colorways-img-block {
      .img-fluid {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .colorways-text-block {
      @include media-breakpoint-down(sm) {
        height: 9rem;
        > div {
          span {
            width: 80%;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .related-slider {
    .slick-slide {
      .product-img-wrapper {
        height: 100%;
      }
      .content-product {
        max-height: 11rem;
      }
      img {
        &.img-fluid {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
