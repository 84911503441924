.sub-options-section-wrapper {
  .sub-options-title {
    background-color: #f7f7f7;
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
    .sub-options-title-icon {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      padding-right: 0.5rem;
    }
  }
  &.addition-info {
    .link-text {
      font-size: 1.6rem;
      text-decoration: underline !important;
    }
  }
}

.btn-continue {
  position: relative;
  top: -130px;
}
.options-selections {
  .tooltip-container {
    &.option-title-tooltip {
      .show {
        &.tooltip {
          min-width: 22rem;
          transform: translate3d(-0.4rem, -11.6rem, 0) !important;
          padding: 0 !important;
          border: none;
          box-shadow: -0.1rem 0.1rem 0.3rem #00000055;
          opacity: 1;
          .popover_arrow {
            bottom: -1.1rem;
            left: 1rem !important;
            border-width: 1.4rem 0.9rem 0 0.9rem;
            border-color: $primary transparent transparent transparent;
            &:before {
              left: -0.9rem;
              border-width: 1.4rem 0.9rem 0 0.9rem;
              border-color: $primary transparent transparent transparent;
            }
          }
        }
      }
    }
  }
}
.more-options-wrapper {
  .click-to-view {
    img {
      width: 2rem;
      height: 2rem;
    }
  }
}
//All of these breakpoints are wrong and are here because of the structure in the jsx file - This needs to be refactored along with the jsx structure
@include media-breakpoint-down(lg) {
  // .more-options-wrapper {
  //   padding: 0 !important;
  // }
  .sub-options-section-wrapper {
    padding: 0 !important;
    .sub-options-title {
      // margin: 0 !important;
    }
    .sub-options {
      padding: 0 !important;
    }
  }
  // .option-selection-dropdown
  // .nailhead-options
  // {
  //   margin: 0;
  // }
  // .upholstery-selections,
  // .options-selections {
  //   margin: 0 !important;
  //   &.finish-options {
  //     margin: 1rem 0 !important;
  //   }
  // }
}
@include media-breakpoint-down(md) {
  .option-selection-dropdown,
  .nailhead-options {
    margin: 0 0 0 -1.5rem;
  }
  .sub-options-section-wrapper {
    .sub-options {
      margin: 0 1.5rem !important;
    }
  }
}
@media (min-width: 1025px) and (max-width: 1199.98px) {
  .custom-option-container {
    .upholstery-selections {
      height: 5rem !important;
    }
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .custom-option-container {
    .upholstery-selections {
      height: 6rem !important;
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .sub-options-section-wrapper {
    .sub-options-title {
      .sub-options-title-icon {
        padding-right: 2rem;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sub-options-section-wrapper {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }
}
@include media-breakpoint-down(xs) {
  .sub-options-title {
    h4 {
      white-space: nowrap;
    }
  }
}
