.static-page {
  .md-form {
    width: 100%;
    height: 100%;
  }
  .message-form-field {
    height: fit-content;
    .md-form {
      margin-bottom: 0.5rem;
      .md-textarea {
        margin-bottom: 0;
      }
    }
  }
  .first-column-inputs {
    .md-form {
      height: 4rem;
    }
  }
  .select2 {
    &:focus {
      outline: none;
    }
    .react-select-container {
      .react-select__control {
        background-color: $white;
        border-color: $default !important;
        height: 4rem;
        border: 2px solid $default;
      }
    }
  }
}
.contact-us-form {
  .text-red {
    bottom: -0.5rem;
  }
  .subject-input-wrapper {
    height: 4rem;
  }
  textarea {
    &.md-textarea {
      margin-bottom: 1.5rem;
      overflow-y: auto;
    }
  }
}
.custom-label {
  top: -2rem;
  transform: none !important;
  color: #404040 !important;
  position: absolute;
}
@include media-breakpoint-down(sm) {
  .contact-us-form {
    .custom-label {
      h2 {
        &.label-text {
          font-size: 2rem;
        }
      }
    }
  }
}
