.craftsmanship {
  margin-bottom: 0 !important;
  .banner-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    .overlay {
      height: 100%;
      width: 100%;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      background: #15191f 0% 0% no-repeat padding-box;
      opacity: 0.3;
    }
    p {
      margin: 0;
    }
    h1 {
      font-size: 100px;
      line-height: 92px;
      letter-spacing: 0;
      color: white;
      text-align: center;
      position: absolute;
      font-weight: 100;
      max-width: 55%;
      z-index: 3;
    }
  }
  .company-story-block {
    .nav-link {
      button {
        background-color: #f7f7f7 !important;
        border: none !important;
        color: #6c808b;
      }
    }
  }
  .card-image {
    text-align: center;
  }
  .slick-slide {
    img {
      margin: 0 auto;
    }
  }
  .resized-description {
    max-width: 80rem;
    margin: 0 auto;
  }
  .frame-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .frame-image-container {
      .imageloader {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-height: 35rem;
          max-width: 35rem;
        }
      }
    }
  }
  .explore-block {
    margin-bottom: 0 !important;
    .company-story-block {
      .imageloader {
        height: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    button {
      background-color: white !important;
      border: none;
    }
  }
}
.card-image {
  p.expandable-text {
    font-size: 1.6rem;
    // height: 5rem;
    margin-bottom: 2rem;
    overflow: hidden;
    transition: height 0.4s linear;
    &.expandable-text-true {
      height: auto;
    }
  }

  p.click-to-show {
    font-size: 1.3rem;
    text-decoration: underline;
    color: $default;
    cursor: pointer;
  }
  &.third-card {
    h3 {
      margin: 4rem 0;
    }
  }
}

@media screen and(max-width:1440px) {
  .craftsmanship {
    .banner-image-container {
      h1 {
        font-size: 70px;
      }
    }
    .explore-block {
      .company-story-block {
        height: unset;
      }
    }
  }
}

@media screen and(max-width:992px) {
  .craftsmanship {
    .banner-image-container {
      height: 47.5rem;
      h1 {
        font-size: 40px;
        line-height: 42px;
        max-width: 85%;
      }
      p {
        height: 100%;
        .imageloader {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
    }
    .explore-block {
      .company-story-block {
        height: unset;
      }
    }
  }
}
