.plp-filter-wrapper {
  height: 100%;
}

.top-filter-wrapper {
  margin-top: 2.5rem;
  padding: 0 3rem;
}

.top-filter-sticky {
  position: sticky;
  top: 146px;
  z-index: 90;
  background: white;
}

.plp-tags-wrapper {
  padding: 3rem 0 2.5rem 0;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}
.sidebar-filter {
  width: 100%;
}
.search-wrapper {
  margin-bottom: 2rem;
}
.default-input-field-container {
  width: 100%;
}
.search-field-container-plp {
  width: 100%;
}
.search-container button {
  width: 12rem;
  margin-left: 1.5rem;
}
.select2 .react-select-container.md {
  .price-slider-placeholder {
    font-size: 1.4rem;
    color: #404040;
    opacity: 0.5;
  }
  .react-select__value-container--has-value {
    padding-left: 1.5rem !important;
  }
}

.sort-products {
  max-height: 5rem;
}

#filter-modal {
  overflow-y: auto;
  overflow-x: hidden;
  .modal-content {
    height: 100%;
    box-shadow: none;
    .content-wrapper {
      min-height: 40rem;
      margin: 0;
      padding: 4rem 10%;
      max-width: 100%;
    }
  }
}

.multi-range-slider {
  border: none;
  box-shadow: none;
  padding: 0.5rem 1rem 2rem;
  .bar {
    height: 0.6rem;
    background: #6c808b;
    border-radius: 0.6rem;
    .bar-left,
    .bar-right {
      background-color: white;
      border: 1px solid #d8d8d8;
      height: 0.6rem;
      padding: 0;
      box-shadow: none;
    }
    .bar-inner {
      box-shadow: none;
      background: inherit;
      border: none;
    }
  }
  .thumb {
    background: #6c808b;
    box-shadow: none;
    .caption {
      display: none !important;
    }
    &::before {
      box-shadow: none;
      border: 1px solid #6c808b;
      width: 1.6rem;
      height: 1.6rem;
      margin: -6px -12px;
    }
  }
  .labels {
    justify-content: center;
    > div {
      font-size: 1.4rem;
      position: relative;
      &:first-of-type {
        left: -1.5rem;
        // margin-right: 0.4rem;
      }
      &:last-of-type {
        right: -1.5rem;
      }
    }
  }
}

.stock-amount-wrapper {
  width: 100%;
  .react-select__menu {
    border: none !important;
  }
}

#multiselectContainerReact {
  width: 100%;
  max-width: 100%;
}

@media screen and (min-width: 992px) {
  .filter-modal {
    .content-wrapper {
      max-width: 80%;
      margin: 0 auto;
      padding: 4rem 0;
      > div:not(:last-of-type) {
        margin-bottom: 3rem;
      }
    }
    .select2 {
      width: 100%;
      > div {
        width: 100% !important;
      }
    }
  }
  .plp-tags-wrapper {
    flex-wrap: wrap;
  }
}

.filter-select {
  .react-select__option {
    padding: 8px;
  }
}
.search-container {
  .btn {
    // width: unset;
    // margin-left: 1.5rem;
    font-weight: 500;
  }
  .checkbox {
    margin-bottom: 0;
    h5 {
      color: #6c808b;
      @media screen and (max-width: 991px) {
        color: #404040;
      }
      white-space: nowrap;
    }
  }
}
.search-field-container-plp {
  position: relative;
  .clear-search-plp {
    img {
      width: 3rem;
    }
    position: absolute;
    right: 5px;
    bottom: 29%;
  }
}
.clear-filters-plp {
  white-space: nowrap;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  border: 1px solid rgba(211, 217, 220, 1);
  border-radius: 5rem;
  img {
    height: 1.5rem;
    margin-left: 0.5rem;
  }
}
.stock-wrapper {
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
    h5 {
      width: 100%;
      color: #404040;
    }
  }
}
.plp-new-items-wrapper {
  .checkbox {
    margin-bottom: 0;
    h5 {
      color: #404040;
      white-space: nowrap;
    }
  }
  // padding-left: 7rem;
  // @media screen and(max-width: 1200px) {
  //   padding-left: 0rem;
  // }
}

@media screen and (max-width: 1366px) {
  .search-container {
    flex-direction: column;
  }
  .search-container button {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 992px) {
  #filter-modal .checkbox {
    padding: 1.5rem;
    margin: 0;
  }

  .top-filter-sticky {
    top: 52px;
  }
}

@media screen and (max-width: 991px) {
  .top-filter-wrapper {
    padding: 0 2rem;
  }
}

.dimensions-wrapper {
  width: 98%;
  margin: 1rem auto;
  .static-values {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 1.4rem;
    }
  }
  .dynamic-values {
    display: flex;
    justify-content: left;
    font-weight: 400;
    p {
      &:first-of-type {
        margin-right: 0.5rem;
        &::after {
          content: "-";
          display: inline-block;
          margin-left: 0.5rem;
        }
      }
      font-size: 1.4rem;
    }
  }
}

@include media-breakpoint-down(lg) {
  .dimensions-wrapper {
    width: 75%;
  }
  .drawer-wrapper {
    // height: unset !important;
    // margin-bottom: 3rem;
  }
  .select2 .react-select-container.md {
    max-width: 15rem;
  }
}
@include media-breakpoint-down(md) {
  .sort-products {
    padding: 0;
    min-width: 100%;
    justify-content: space-between !important;
    &.no-filters {
      flex: 0 0 100%;
      max-width: 100%;
      button {
        // width: 100%;
        margin: 0 !important;
      }
    }
  }
  .sortButton {
    width: 10rem;
    margin: 0;
  }
}

code {
  padding: 10px;
  background: #f1f1f1;
}

.multiSelectContainer {
  min-width: 250px;
  max-width: 250px;
  &.chip-added {
    .searchWrapper {
      input#search_input {
        position: relative;
        top: -6px;
        opacity: 0;
      }
    }
  }
  // z-index: 1000;
  .closeIcon {
    display: none;
  }
  /* Overriding element hover */
  .searchWrapper {
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    max-height: 70px;
    border: 2px solid #6c808b;
    border-color: rgba(108, 128, 139, 0.5) !important;
    span {
      &.placeholder {
        font-size: 1.4rem;
        color: #404040;
        opacity: 0;
        position: relative;
        top: -25px;
        // display: none;
      }
    }
    &.height-extend {
      height: 70px;
    }
    .icon_down_dir {
      width: 0.75em;
      background-repeat: no-repeat;
      &.rotate {
        transform: rotateX(-178deg);
        top: 40%;
      }
    }
    padding-left: 2.5rem;
    input#search_input {
      // opacity: 0.5;
      opacity: 1;
      position: relative;
      top: -2px;
    }
  }
  &.focus {
    .searchWrapper {
      border: 2px solid #6c808b;
      border-color: #6c808b !important;
      opacity: 1;
    }
  }
  .optionListContainer {
    z-index: 3;
  }
  .optionContainer {
    li {
      font-size: 1.4rem;
      color: #404040;
      &:hover {
        background: white;
        color: #6c808b;
      }
      &.highlight {
        background: white;
        color: black;
      }
    }
  }
  span.chip {
    // display: none;
    background: #6c808b;
    padding: 1px 6px;
    border-radius: 0.25rem;
    position: relative;
    left: -15px;
    margin-bottom: 2px;
    margin-top: 3px;
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  input[type="checkbox"]:before {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    border: 1px solid #808080;
    content: "";
    background: #fff;
  }

  input[type="checkbox"]:after {
    position: relative;
    display: block;
    // left: 2px;
    top: -12px;
    width: 18px;
    height: 18px;
    border-width: 1px;
    border-style: solid;
    border-color: #6c808b;
    content: "";
    background-image: linear-gradient(135deg, white 0%, white 100%);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0.25rem;
  }

  input[type="checkbox"]:checked:after {
    background-image: url("../../../../assets/icon/checked.svg");
  }

  input[type="checkbox"]:not(:disabled):checked:hover:after {
    background-image: url("../../../../assets/icon/checked.svg");
  }

  input[type="checkbox"]:not(:disabled):hover:after {
    // background-image: linear-gradient(135deg, #8bb0c2 0%, #fff 100%);
    border-color: #6c808b;
  }

  input[type="checkbox"]:not(:disabled):hover:before {
    // border-color: #6c808b;
  }

  /* Placehoolder Color Change */
  .searchBox#css_custom_input::placeholder {
    color: red;
  }
}
