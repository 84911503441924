.configureProductModal-details {
    max-width: 51rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        max-width: 100%;
    }
    padding: 4rem 6rem;
    border-left: 1px solid #E5E5E5;
}