%with-appear-transition {
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}
%appear {
  opacity: 1;
  visibility: visible;
}
.product-title {
  font-family: $secondaryFontGibson;
}

.product-description {
  color: #666666;
  font-size: $fs-14;
  font-family: $secondaryFontGibson;
}
.product-sku {
  color: #666666;
  font-size: $fs-12;
  font-family: $secondaryFontGibson;
}
.product-collection-name {
  font-family: $secondaryFontGibson;
  letter-spacing: 2px;
  color: #666666;
}

.commercial-grade {
  color: #666666;
  font-family: $secondaryFontGibson;
  font-weight: 500;
  font-size: $fs-14;
}
.product-price {
  color: $gray-darken-3;
}
.product-widget {
  overflow-x: hidden;

  .cfgmodel {
    border: 1px solid rgba(211, 217, 220, 1);
    width: 40px;
    height: 40px;
    border-radius: 50px;

    img {
      position: absolute;
      margin: 0.8rem;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
    }
  }
  .product-widget-image {
    max-height: 40.55rem;
    width: auto;
    &.build-your-own-img {
      transform: scale(1.5);
    }
    &.border-grey {
      border: 1px solid #d8d8d8;
    }
  }
  .imageloader {
    > div {
      span {
        height: 40.55rem;
        width: 100%;
      }
    }
  }
  .favorite {
    @extend %with-appear-transition;
  }
  .badges {
    @extend %with-appear-transition;
  }
}
.product-widget:hover {
  .favorite {
    @extend %appear;
  }
  .badges {
    @extend %appear;
  }
}

@media screen and (max-width: 800px) {
  .product-widget {
    .cfgmodel {
      width: 30px;
      height: 30px;

      img {
        width: 20px;
        height: 20px;
        margin: 0.5rem;
      }
    }
  }
}
// @include media-breakpoint-down(lg) {
//   .product-widget {
//     .favorite,
//     .badges {
//       opacity: 1 !important;
//       visibility: visible !important;
//     }
//   }
// }
