.cart-submit {
  .back-section-wrapper {
    a {
      > div {
        &.border-gray {
          border-color: $gray;
          p {
            font-weight: $fwmedium;
            color: $default;
          }
        }
      }
    }
  }
  h2 {
    &.body-2 {
      font-size: $fs-24;
      color: $default;
    }
  }
  .md-form {
    margin-bottom: 0.5rem;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    label {
      top: -3.5rem !important;
    }
  }
  .calendar-icon {
    @include media-breakpoint-down(lg) {
      right: 1rem !important;
    }
  }
  .masked-input {
    @include media-breakpoint-down(lg) {
      width: 100% !important;
    }
  }
}
.quote-thank-you {
  .display-2 {
    font-weight: 300;
  }
  button.btn {
    font-size: 1.4rem;
    text-transform: capitalize;
    font-weight: 400;
  }
}
