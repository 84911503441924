.items-container {
  .tooltip-elements-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fabric-item-tooltip {
    max-width: 5.6rem;
    max-height: 5.6rem;
    height: 5.6rem;
    width: 5.6rem;
    padding: 0;
    margin: 1rem;
    .tooltip-content {
      z-index: 9999999;
      width: 26rem;
      height: 36rem;
      top: -38rem;
      padding: 2rem;
      box-shadow: -1px 1px 3px #00000055;
      .image-container {
        height: 19rem;
        width: 19rem;
        border-radius: 5px;
        margin-bottom: 1rem;
        img {
          border-radius: 5px;
          height: 100%;
          width: 100%;
        }
      }
      .info-container {
        p {
          font-size: 14px;
          line-height: 1.6rem;
          letter-spacing: 0;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .color-element {
            display: flex;
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 2px;
            border: 1px solid $primary;
            margin: 0 1rem;
          }
        }
      }
    }
    .tooltip-trigger-element {
      padding: 0;
    }
    &.align-left {
      .tooltip-elements-container {
        justify-content: flex-start;
      }
      .tooltip-content {
        .tooltip-tick-arrow {
          left: 1rem;
        }
      }
    }
    &.align-right {
      .tooltip-elements-container {
        justify-content: flex-end;
      }
      .tooltip-content {
        .tooltip-tick-arrow {
          right: 1rem;
        }
      }
    }
  }

  .fabric-item {
    cursor: pointer;
    border-radius: 5px;
    height: 5.6rem;
    width: 5.6rem;
    max-width: 5.6rem;
    padding: 0;
    margin: 0;
    img {
      border-radius: 5px;
    }
    div {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      .fabric-inner {
        &.fabric-has-badge-true {
          position: relative;
          &:after {
            content: "";
            width: 3.1rem;
            height: 3.1rem;
            background: url("../../../../../assets/icon/badge.svg") no-repeat;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
.modal-backdrop {
  &.notification-modal-backdrop {
    opacity: 0.3;
  }
}
.notification-modal {
  .modal-content {
    border-radius: 0.5rem;
    border: 0.2rem solid $default;
    box-shadow: 0px 0.2rem 0.4rem #00000033;
    padding: 0.75rem;
  }
}
@media screen and(max-width:600px) {
  .fabric-item {
    // margin: 1rem !important;
    .mobile-fabric-item {
      padding: 0;
    }
  }
}
