.multi-select-filter-checkbox-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  .color-filter {
    height: 2rem;
    width: 2rem;
    border-radius: 5px;
    margin-right: 1rem;
    border: 1px solid $gray;
  }
}
.multi-select-filter-options {
  box-shadow: inset 0 0 5px #00000029;
  padding: 1.5rem 5rem;
  padding-right: 2rem;
  padding-bottom: 5rem;
  background-color: $gray-lighten-4;
  z-index: 9;
  max-width: 100%;
  width: 100%;

  .slick-slider {
    .slick-slide {
      .checkbox {
        margin-bottom: 1rem;
        align-items: center !important;
        input:checked ~ .checkbox-body {
          border-width: 1px !important;
        }
        p {
          margin-right: 1rem;
        }
      }
    }
    .slick-dots {
      //display: flex !important;
      //justify-content: center;
      //align-items: center;
      //bottom: -2rem;
      //li {
      //  height: 1rem;
      //  width: 1rem;
      //  button::before {
      //    height: 6px;
      //    width: 6px;
      //  }
      //  &.slick-active {
      //    button {
      //      height: 1rem;
      //      width: 1rem;
      //    }
      //    button::before {
      //      height: 1rem;
      //      width: 1rem;
      //    }
      //  }
      //}
    }
    .slick-arrow {
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      transition: all 0.25s ease-in-out;

      &.slick-prev {
        border-right: 7px solid $default;
        left: -2.5rem;
      }
      &.slick-next {
        right: 25px;
        border-left: 7px solid $default;
      }
      &.slick-disabled {
        opacity: 0;
      }
      &::before {
        content: "";
      }
    }
    div:focus,
    div:active,
    div:visited {
      outline: none;
    }
  }
  .dots-nav-slider {
    margin-top: 2rem;
    .slick-list {
      max-width: 50%;
      margin: 0 auto;
      height: 26px !important;
    }
    .slick-track {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .slick-slide {
      button {
        height: 0.7rem;
        width: 0.7rem !important;
        margin: 0.5rem;
        border-radius: 50%;
        background-color: white;
        border: 1px solid gray;
        padding: 0;
        &.active {
          background-color: $primary;
          transition: all 0.25s ease-in-out;
        }
      }
      button:focus,
      button:visited,
      button:active {
        outline: none;
      }
    }
  }
}
.filter-options-mobile {
  .md-form {
    position: relative;
    width: 100%;
    padding-right: 1.5rem;
    img {
      position: absolute;
      top: 1rem;
      right: 2rem;
    }
  }
}
@media screen and(max-width:600px) {
  .filter-options-mobile {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    max-height: 90vh;
    overflow-y: scroll;
    .filter-title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 2rem;
    }
    .filter-option-mobile {
      margin-bottom: 2rem;
    }
  }
}
